import React from 'react'
import { Tooltip } from 'antd'
import './index.scss'

const MiniProgress = ({
  targets = [],
  color = 'rgb(19, 194, 194)',
  strokeWidth,
  percent,
  remaining,
  showRemaining,
  showTarget = false,
  showPercent = false,
}) => (
  <div className="mini-progress">
    {showTarget &&
      targets.map((target) => (
        <Tooltip title={target.popup} key={target.id}>
          <div
            className={'target'}
            style={{
              left: target.value ? `${target.value}%` : undefined,
            }}
          >
            {target.position === 'bottom' ? (
              <span
                style={{
                  fontSize: 8,
                  position: 'absolute',
                  top: strokeWidth + 12,
                  left: target.left ?? -15,
                  width: 100,
                }}
              >
                {target.title}
              </span>
            ) : (
              <span
                style={{ fontSize: 8, position: 'absolute', top: -15, left: target.left ?? -15, width: 100 }}
              >
                {target.title}
              </span>
            )}
            <span
              style={{
                backgroundColor: color || undefined,
              }}
            />
            <span
              style={{
                backgroundColor: color || undefined,
              }}
            />
          </div>
        </Tooltip>
      ))}
    <div className={'progress-wrap'}>
      <div
        className={'progress'}
        style={{
          backgroundColor: color || undefined,
          width: percent ? `${percent}%` : undefined,
          height: strokeWidth || undefined,
        }}
      >
        {showPercent ? `${percent}%` : ''}
      </div>
      {showRemaining ? (
        <div
          className={'unprogress'}
          style={{
            width: `${100 - percent}%`,
            height: strokeWidth || undefined,
          }}
        >
          {percent ? remaining : '0%'}
        </div>
      ) : null}
    </div>
  </div>
)

export default MiniProgress
