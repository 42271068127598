import { TOGGLE_LOADING, TOGGLE_MENU, SET_MASTER_DATA } from 'store/actions/common.action'

const initialState = {
  isLoading: false,
  isMenuOpen: false,
  types: [],
  statuses: [],
  departments: [],
  users: [],
  roles: [],
  project_users: [],
}

export default function common(state = initialState, { type, payload }) {
  switch (type) {
    case TOGGLE_LOADING:
      return { ...state, isLoading: payload }

    case TOGGLE_MENU:
      return { ...state, isMenuOpen: payload }

    case SET_MASTER_DATA:
      return { ...state, [payload.name]: payload.data }

    default:
      return state
  }
}
