import * as actionTypes from 'store/types/project.action.types'

const initialState = {
  projects: [],
  selectedProject: {},
  projectOverview: {},
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PROJECT:
      return { ...state, projects: action.payload }

    case actionTypes.SET_SELECTED_PROJECT:
      return { ...state, selectedProject: action.payload }

    case actionTypes.SET_PROJECT_OVERVIEW:
      return { ...state, projectOverview: action.payload }

    default:
      return state
  }
}

export default reducer
