import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { isMobile } from 'react-device-detect'

//
//  ANT - Breakpoints
//––––––––––––––––––––––––––––––––––––––––––––––––––
/**
 * A map of breakpoints based on ant
 * xs:  320px
 * sm:  576px
 * md:  768px
 * lg:  992px
 * xl:  1200px
 * xxl: 1600px
 */
const useLayoutHelper = () => {
  const screens = useBreakpoint()
  const isXXL = screens.xxl === undefined ? false : screens.xxl
  const isXL = screens.xl === undefined ? false : screens.xl
  const isMD = screens.md === undefined ? false : screens.md
  const isLG = screens.lg === undefined ? false : screens.lg
  const isSM = screens.sm === undefined ? false : screens.sm
  const isXS = screens.xs === undefined ? false : screens.xs

  const isToggleMenu = isMobile || (!isXL && (isLG || isMD || isSM || isXS)) || false

  return { isToggleMenu, isMobile }
}

export default useLayoutHelper
