import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Layout, Menu } from 'antd'
import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { useHistory, withRouter, matchPath, useLocation } from 'react-router-dom'

import { routes, routePage } from 'routes'
import { useWindowSize } from 'hooks'
import Logo from 'assets/images/tp-logo.png'
import LogoVertical from 'assets/images/tp-logo-vertical-trim.png'

import './Sidebar.scss'

const { Sider } = Layout
const { SubMenu } = Menu

function SideBar({ isMobile, toggleMenuAction }) {
  const { accessedRoutes } = useSelector((state) => state.authState)
  const [collapsed, setCollapsed] = useState(false)
  const [selectedMenu, setSelectedMenu] = useState()

  const location = useLocation()
  const history = useHistory()
  const { windowWidth } = useWindowSize()

  /* use matchPath instead of useParams because wrapper components can't get params of children routes */
  const match = matchPath(location.pathname, {
    path: '/projects/:id',
    strict: false,
  })

  const projectRoute = `${routes.projects}/${match?.params?.id}`

  useEffect(() => {
    getSelectedMenu(accessedRoutes)
  }, [accessedRoutes, location])

  const getSelectedMenu = (menus, parent = null) => {
    menus.forEach((menu) => {
      if (menu.routePage === routePage.project) {
        if (menu.submenu) {
          getSelectedMenu(menu.submenu, menu)
        } else {
          const routeMatch = matchPath(location.pathname, {
            path: `${projectRoute}${menu.nestedRoute}`,
            exact: true,
            strict: false,
          })
          if (routeMatch) {
            setSelectedMenu({ selectedKey: `${projectRoute}${menu.nestedRoute}`, openKey: parent?.path })
          }
        }
      }
    })
  }
  const handleClick = (e) => {
    toggleMenuAction(false)
    history.push(e.key)
  }

  return (
    <Sider
      width={220}
      className="site-layout-background"
      collapsible
      collapsed={isMobile ? !isMobile : collapsed}
      onCollapse={(value) => setCollapsed(value)}
      breakpoint="lg"
      {...(windowWidth <= 992 && { collapsedWidth: '0', trigger: 'null' })}
    >
      <div
        className="logo-container"
        onClick={() => {
          toggleMenuAction && toggleMenuAction(false)
          history.replace(routes.projects)
        }}
      >
        <img alt="logo" src={collapsed ? LogoVertical : Logo} className="logo-header" />
      </div>
      {selectedMenu && (
        <Menu
          mode="inline"
          defaultSelectedKeys={[selectedMenu.selectedKey]}
          defaultOpenKeys={[selectedMenu.openKey]}
          onClick={handleClick}
          style={{ height: '100%', borderRight: 0 }}
        >
          {accessedRoutes.map((menu) => {
            if (menu.routePage === routePage.project) {
              if (menu.submenu) {
                return (
                  <SubMenu
                    key={menu.path}
                    icon={menu.meta.icon}
                    title={
                      <span className="uppercase">
                        <FormattedMessage id={menu.translate} />
                      </span>
                    }
                  >
                    {menu.submenu.map((sub) => (
                      <Menu.Item key={`${projectRoute}${sub.nestedRoute}`}>
                        <FormattedMessage id={sub.translate} />
                      </Menu.Item>
                    ))}
                  </SubMenu>
                )
              }
              return (
                <Menu.Item
                  key={`${projectRoute}${menu.nestedRoute}`}
                  className="uppercase"
                  icon={menu.meta.icon}
                >
                  <FormattedMessage id={menu.translate} />
                </Menu.Item>
              )
            }
          })}
        </Menu>
      )}
    </Sider>
  )
}

export default withRouter(SideBar)

SideBar.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  project: PropTypes.object,
  logoClassName: PropTypes.string,
  setSelectedFolderData: PropTypes.func,
  isMobile: PropTypes.bool,
}
