import React, { useEffect } from 'react'
import { Form, Select } from 'antd'
import { filterString } from 'utils/utility'

import { Modal } from 'components/Common'

const { Option } = Select

const formItemLayout = {
  labelCol: {
    xs: { span: 22 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 22 },
    sm: { span: 16 },
  },
}

const ProjectUserForm = (props) => {
  const [form] = Form.useForm(null)
  const { data, onSubmit, users, roles } = props

  useEffect(() => {
    if (props.visible) {
      form.resetFields()
      if (data) {
        form.setFieldsValue({
          user_id: data.user_id,
          role_id: data.role_id,
        })
      }
    }
  }, [props.visible])

  const onFinish = (formData) => {
    onSubmit({
      ...data,
      ...formData,
    })
  }

  return (
    <Modal
      title={'Cập nhật thành viên'}
      formName="project-user-form"
      loading={props.loading}
      visible={props.visible}
      onCancel={props.onCancel}
    >
      <Form
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        id="project-user-form"
        form={form}
        name="dynamic_rule"
        onFinish={onFinish}
      >
        <Form.Item
          {...formItemLayout}
          name="user_id"
          label="Thành viên"
          rules={[
            {
              required: true,
              message: 'Vui lòng chọn thành viên',
            },
          ]}
        >
          <Select
            showSearch
            style={{ width: '100%' }}
            placeholder="Chọn thành viên"
            optionFilterProp="children"
            onChange={(value) => {
              const selected = users.find((item) => item.id === value)
              console.log(selected)
              if (selected) {
                form.setFieldsValue({
                  role_id: selected.role_id,
                })
              }
            }}
            filterOption={(input, option) => filterString(input, option.children)}
          >
            {users.map((user) => (
              <Option key={user.id} value={user.id}>
                {user.full_name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="role_id"
          label="Vai trò"
          rules={[
            {
              required: true,
              message: 'Vui lòng chọn vai trò',
            },
          ]}
        >
          <Select
            showSearch
            style={{ width: '100%' }}
            placeholder="Chọn vai trò"
            optionFilterProp="children"
            filterOption={(input, option) => filterString(input, option.children)}
          >
            {roles.map((role) => (
              <Option key={role.id} value={role.id}>
                {role.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ProjectUserForm
