export const routes = {
  login: '/login',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  profile: '/profile',
  home: '/home',
  projects: '/projects',
  projectsDetails: '/projects/:id/gantt',
  overview: '/projects/:id/overview',
  projectSettings: '/projects/:id/settings',
  projectMembers: '/projects/:id/members',
  projectFileStorage: '/projects/:id/file-storage',
  fileStorage: '/file-storage',
  fileLog: '/projects/:id/file-storage/logs',
  users: '/admin/users',
  adminProjects: '/admin/projects',
  departments: '/admin/departments',
  rolesPermissions: '/admin/roles',
  calendars: '/calendars',
}
