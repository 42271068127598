export default {
  login_signIn: 'Sign In',
  or: 'OR',
  login_signWithGoogle: 'Sign in with Google',
  sidebar_overview: 'Overview',
  sidebar_workpackage: 'Work Package',
  sidebar_projectPlan: 'Project Plan',
  sidebar_task: 'Task',
  sidebar_assignToMe: 'My tasks',
  sidebar_fileStorage: 'File storage',
  sidebar_fileLog: 'File Logs',
  sidebar_members: 'Members',
  siderbar_projectCalendar: 'Calendars',
  siderbar_projectSetting: 'Project Setting',
  sidebar_users_permissions: 'User & Permissions',
  sidebar_users: 'Users',
  sidebar_department: 'Departments',
  sidebar_roles_permissions: 'Roles & permissions',
  sidebar_system_calendar: 'System calendar',
  siderbar_system_setting: 'System settings',
  status: {
    new: 'New',
    approved: 'Approved',
    rejected: 'Rejected',
    'in-progress': 'In-progress',
    done: 'Done',
    closed: 'Closed',
  },
}
