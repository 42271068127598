import { notification } from 'antd'

import { Api } from 'services'
import endPoints from 'configs/endPoints'

export const TOGGLE_LOADING = 'common/TOGGLE_LOADING'
export const TOGGLE_MENU = 'common/TOGGLE_MENU'
export const SET_MASTER_DATA = 'common/SET_MASTER_DATA'

export function setMasterData(payload) {
  return {
    type: SET_MASTER_DATA,
    payload,
  }
}

function handlePopupAlert(type = 'success', text = '') {
  notification[type]({
    message: text || type,
    // description: text,
    duration: 2,
  })
}

function toggleLoading(bool) {
  return {
    type: TOGGLE_LOADING,
    payload: bool,
  }
}

function toggleMenu(bool) {
  return {
    type: TOGGLE_MENU,
    payload: bool,
  }
}

function getMasterData(tableUrl, filters, successCB = () => {}) {
  return async (dispatch, getState) => {
    try {
      const tableName = tableUrl.replace('/', '')
      // const { common } = getState()
      // if (common[tableName] && common[tableName].length) {
      //   return successCB(common[tableName])
      // }
      const params = {
        ...filters,
        limit: 'unlimited',
      }
      const { data } = await Api.get(`${endPoints.remoteUrl}${tableUrl}`, params)
      dispatch(setMasterData({ name: tableName, data }))
      successCB(data)
    } catch (error) {
      // return Promise.reject(error)
    }
  }
}
export { toggleLoading, toggleMenu, handlePopupAlert, getMasterData }
