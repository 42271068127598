import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { Modal, Form, Input, DatePicker, Select, Upload, message } from 'antd'
import { InboxOutlined } from 'assets/icon'
import { dateFormat } from 'utils'
import * as fileActions from 'store/actions/filestorage.action'
import { FileBreadcrumb } from 'components'
import { FolderFilled } from 'assets/icon'

const { TextArea } = Input

const formLayout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
}

const initialState = {
  selectedFolder: {},
}

function CreateFolderModal({
  isSelectedProject,
  isSelectedDepartment,
  visible,
  onCancel,
  breadscrumb,
  onSubmit,
  onSuccess,
  rowData,
  selectedProject,
  isMobile,
}) {
  const [form] = Form.useForm()
  const [state, setState] = useState({
    ...initialState,
  })

  useEffect(() => {
    if (!visible) {
      form.resetFields()
    }
  }, [visible])

  useEffect(() => {
    if (visible && rowData) {
      form.setFieldsValue({
        name: rowData.name,
      })
    }
  }, [visible, rowData])

  const handleSubmit = async () => {
    try {
      let values = await form.validateFields()

      const formData = {
        name: values.name || 'New Folder',
        is_folder: 1,
        ...(rowData
          ? { id: rowData.id }
          : {
              project_id: selectedProject?.project_id || null,
              department_id: breadscrumb.selectedDepartment?.department_id || null,
              ...(breadscrumb.selectedFolders?.length > 0
                ? {
                    parent_id: breadscrumb.selectedFolders.slice(-1)[0]?.id,
                  }
                : { parent_id: null }),
            }),
      }

      await onSubmit(formData)
      form.resetFields()
      onSuccess()
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Modal
      title={`${rowData ? 'Sửa' : 'Tạo'} thư mục`}
      visible={visible}
      onOk={handleSubmit}
      onCancel={onCancel}
      forceRender
      maskClosable={false}
    >
      <Form form={form} {...formLayout}>
        <Form.Item
          label="Tên thư mục"
          name="name"
          rules={[
            {
              required: true,
              message: 'Bắt buộc',
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default CreateFolderModal
