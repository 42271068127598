import React, { useEffect, useState } from 'react'
import { Modal, Form, Input, Select, Upload, Progress } from 'antd'
import { InboxOutlined } from 'assets/icon'
import { FileBreadcrumb } from 'components'
import { fileBlackList } from 'utils'

const { TextArea } = Input
const { Option } = Select

const formLayout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
}

function FileUploadModal({
  isSelectedProject,
  isSelectedDepartment,
  visible,
  onCancel,
  breadscrumb,
  onSubmit,
  onSuccess,
  directory,
  isSaving,
  isMobile,
  progress,
  resetUploadProgress,
}) {
  const [form] = Form.useForm()

  useEffect(() => {
    if (!visible) {
      form.resetFields()
    } else {
    }
  }, [visible])

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  const uploadProps = {
    multiple: true,
    beforeUpload: function (file, fileList) {
      return false
    },
  }

  async function handleSubmit() {
    try {
      let values = await form.validateFields()
      values.directory = directory ? 1 : 0
      //values.files = values.files?.filter((file) => !file.id).map((file) => file.originFileObj)
      if (values.files) {
        if (values.directory) {
          const files = []
          const filePaths = []
          values.files.forEach(function (file) {
            const fileTmp = file.originFileObj
            let fileExtension = file.name.split('.')
            fileExtension = fileExtension[fileExtension.length - 1]
            if (!fileBlackList.includes(fileExtension)) {
              if (!file.id) {
                files.push(fileTmp)
                filePaths.push(fileTmp.webkitRelativePath)
              }
            }
          })
          values.files = files
          values.filePaths = filePaths
        } else {
          values.files = values.files.filter((file) => !file.id).map((file) => file.originFileObj)
        }

        if (isSelectedProject) {
          values.project_id = breadscrumb.selectedProject?.project_id
        }

        if (isSelectedDepartment) {
          values.department_id = breadscrumb.selectedDepartment?.department_id
        }

        if (breadscrumb.selectedFolders?.length > 0) {
          values.parent_id = breadscrumb.selectedFolders.slice(-1)[0]?.id
        }
        await onSubmit(values)

        form.resetFields()
        onSuccess()
        resetUploadProgress()
      } else {
        Modal.error({
          title: 'Lỗi',
          content: 'Tập tin không hợp lệ, xin kiểm tra lại',
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Modal
      title={`Upload ${directory ? 'thư mục' : 'tập tin'}`}
      visible={visible}
      okButtonProps={{ loading: isSaving }}
      onOk={handleSubmit}
      onCancel={onCancel}
      forceRender
      maskClosable={false}
    >
      <Form form={form} {...formLayout}>
        <Form.Item label="Description" name="description">
          <TextArea rows={5} maxLength={250} />
        </Form.Item>

        <Form.Item className="form-item-breadcrumb">
          <FileBreadcrumb
            isSelectedProject={isSelectedProject}
            isSelectedDepartment={isSelectedDepartment}
            setSelectedFolderData={() => {}}
            breadscrumb={breadscrumb}
          />
        </Form.Item>

        <Form.Item
          name="files"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          className="form-item-upload"
        >
          <Upload.Dragger name="file" {...uploadProps} directory={directory}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Bấm để chọn {`${directory ? 'thư mục' : 'tập tin'}`}</p>
          </Upload.Dragger>
        </Form.Item>
      </Form>
      <Progress strokeLinecap="square" percent={progress} />
    </Modal>
  )
}

export default FileUploadModal
