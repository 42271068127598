import React, { useEffect } from 'react'
import { DatePicker, Button, Breadcrumb, Row } from 'antd'
import { FolderFilled, FolderOpenFilled } from 'assets/icon'

function FileBreadcrumb({ isSelectedProject, isSelectedDepartment, setSelectedFolderData, breadscrumb }) {
  function isLast(index) {
    return index === breadscrumb.selectedFolders.length - 1
  }

  return (
    <Breadcrumb>
      <Breadcrumb.Item
        onClick={() => {
          setSelectedFolderData(0, null)
        }}
        className={`ant-breadcrumb-link ${
          breadscrumb.selectedFolders?.length < 1 ? 'pointer-events--none' : 'cusor--pointer'
        }`}
      >
        <FolderFilled />
        <span>Tất cả</span>
      </Breadcrumb.Item>

      {/* {Object.keys(breadscrumb.selectedProject).length !== 0 && (
        <Breadcrumb.Item
          onClick={() => {
            setSelectedFolderData(1, breadscrumb.selectedProject)
          }}
          className={`ant-breadcrumb-link ${!isBrowsing ? 'pointer-events--none' : 'cusor--pointer'}`}
        >
          <FolderOpenFilled />
          <span>{breadscrumb.selectedProject?.name}</span>
        </Breadcrumb.Item>
      )}

      {Object.keys(breadscrumb.selectedDepartment).length !== 0 && (
        // <pre>{JSON.stringify(selectedFolder, null, 2)}</pre>
        <Breadcrumb.Item
          onClick={() => {
            setSelectedFolderData(2, breadscrumb.selectedDepartment)
          }}
          className={`ant-breadcrumb-link ${!isSelectedProject ? 'pointer-events--none' : 'cusor--pointer'}`}
        >
          <FolderOpenFilled />
          <span>{breadscrumb.selectedDepartment.name}</span>
        </Breadcrumb.Item>
      )} */}

      {/* <pre>{JSON.stringify(breadscrumb.selectedFolders, null, 2)}</pre> */}
      {breadscrumb.selectedFolders?.map((item, index) => (
        <Breadcrumb.Item
          id={item.id}
          item={`breadcrumb-item${index}`}
          key={`${index}`}
          onClick={() => {
            setSelectedFolderData(1, { ...item }, index)
          }}
          className={`ant-breadcrumb-link ${isLast(index) ? 'pointer-events--none' : 'cusor--pointer'}`}
        >
          <FolderOpenFilled />
          <span> {item.name}</span>
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  )
}

export default FileBreadcrumb
