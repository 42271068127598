import React, { useState, useMemo, useCallback, forwardRef, useEffect } from 'react'
import moment from 'moment'
import { Modal, Form, Input, Table, Select, Button, Tag, Row, Col } from 'antd'

import { chain } from 'lodash'
import { InboxOutlined } from 'assets/icon'
import { dateFormat } from 'utils'
import * as fileActions from 'store/actions/filestorage.action'
import { FileBreadcrumb } from 'components'
import { DeleteOutlined } from 'assets/icon'
import { filterString } from 'utils/utility'

const { Option, OptGroup } = Select

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
}

function ShareFolderModal({
  isSelectedProject,
  isSelectedDepartment,
  visible,
  onCancel,
  breadscrumb,
  onSubmit,
  onDelete,
  onSuccess,
  rowData,
  selectedProject,
  users,
  selectedUsers,
  isMobile,
}) {
  let userList = useMemo(() => users, [users])
  const [form] = Form.useForm()
  const [optionGroups, setOptionGroups] = useState({})
  const [selectedUsersList, setSelectedUsersList] = useState([])

  const permissions = {
    1: 'view',
    7: 'edit',
    15: 'delete',
  }

  useEffect(() => {
    if (selectedUsers?.length > 0 && userList?.length > 0) {
      selectedUsers = selectedUsers.map((item) => {
        let find = userList.filter((obj) => obj.id === item.user_id)
        return { ...item, full_name: find[0]?.full_name, email: find[0]?.email }
      })

      setSelectedUsersList(selectedUsers)
    }
  }, [selectedUsers, rowData])

  useEffect(() => {
    const groupedData = chain(userList)
      .groupBy((x) => x.department?.name)
      .value()
    setOptionGroups(groupedData)
  }, [userList])

  useEffect(() => {
    if (!visible) {
      form.resetFields()
      setSelectedUsersList([])
    } else {
      form.setFieldsValue({
        permission: '1',
      })
    }
  }, [visible])

  // useEffect(() => {
  //   if (visible && rowData) {
  //     form.setFieldsValue({
  //       name: rowData.name,
  //     })
  //   }
  // }, [visible, rowData])

  const handleSubmit = async () => {
    try {
      let values = await form.validateFields()

      const formData = values.selectedUser.map(function (id) {
        return { user_id: id, permission: values.permission }
      })

      await onSubmit(0, formData, rowData.id)
      form.resetFields()
      onSuccess()
    } catch (err) {
      console.log(err)
    }
  }

  const onChange = (selected) => {
    // console.log('selected', selected)
  }

  const columns = [
    {
      title: '',
      dataIndex: 'full_name',
      key: 'full_name',
      render: (full_name, record) => {
        return (
          <>
            <span className="name"> {full_name || ''}</span>
            <span className="email"> {record.email || ''}</span>
          </>
        )
      },
    },
    {
      title: '',
      dataIndex: '',
      width: 40,
      render: (permission, row) => {
        return permissions[row.permission] || ''
      },
      align: 'left',
    },
    {
      title: '',
      width: 50,
      align: 'right',
      render: (row) => {
        return (
          <Button onClick={() => onDelete(1, row.id)} type="link" danger icon={<DeleteOutlined />}></Button>
        )
      },
    },
  ]

  return (
    <Modal
      width={800}
      title="Share with people"
      visible={visible}
      onOk={handleSubmit}
      onCancel={onCancel}
      maskClosable={false}
      forceRender
    >
      <Form form={form} {...formLayout} initialValues={{ permission: 1 }}>
        {/* <Form.Item className="form-item-breadcrumb">
          <FileBreadcrumb
            isSelectedProject={isSelectedProject}
            isSelectedDepartment={isSelectedDepartment}
            selectedProject={selectedProject}
            breadscrumb={breadscrumb}
          />
        </Form.Item> */}

        <div style={{ display: 'flex' }} className="share-folder__field">
          <Form.Item
            name="permission"
            rules={[
              {
                required: true,
                message: 'Bắt buộc',
              },
            ]}
          >
            <Select>
              {Object.entries(permissions).map(([key, data]) => (
                <Option key={key} value={key}>
                  {data}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="selectedUser"
            rules={[
              {
                required: true,
                message: 'Bắt buộc',
              },
            ]}
          >
            <Select
              mode="multiple"
              placeholder="Chọn nhân viên"
              allowClear
              showSearch
              name="selectedUser"
              optionFilterProp="full_name"
              filterOption={(input, option) =>
                filterString(input, option.children) || filterString(input, option.label)
              }
              onChange={onChange}
            >
              {Object.keys(optionGroups).map((groupName) => (
                <OptGroup key={groupName} label={groupName}>
                  {optionGroups[groupName].map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.full_name}
                    </Option>
                  ))}
                </OptGroup>
              ))}
            </Select>
          </Form.Item>
        </div>

        <Table
          className="share-folder__user-list"
          pagination={false}
          rowKey="id"
          columns={columns}
          dataSource={selectedUsersList}
          scroll={{ x: 'max-content', y: 200 }}
          tableLayout={'auto'}
          showHeader={false}
          // className={`table-file ${os}`}
        />
      </Form>
    </Modal>
  )
}

export default ShareFolderModal
