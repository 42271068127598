export const departmentBackgroundColors = {
  0: '#eeb76b',
  1: '#e2703a',
  2: '#9ede73',
  3: '#e48900',
  4: '#9e9d89',
  5: '#e4d3cf',
  6: '#b67162',
  7: '#2b2e4a',
  9: '#e84545',
  10: '#903749',
  11: '#53354a',
  12: '#fed049',
  13: '#007580',
  14: '#347638',
  15: '#c8c6a7',
  16: '#440a67',
  17: '#b4aee8',
  18: '#92967d',
  19: '#6e7c7c',
  20: '#435560',
  21: '#810034',
  22: '#28527a',
  23: '#f0a500',
  24: '#4a47a3',
  25: '#00af91',
  26: '#1a508b',
}

export const departmentIds = {
  LEGAL: 14,
}
