export default {
  login_signIn: 'Đăng nhập',
  or: 'Hoặc',
  login_signWithGoogle: 'Đăng nhập với Google',
  sidebar_overview: 'Tổng quan dự án',
  sidebar_workpackage: 'Gói công việc',
  sidebar_projectPlan: 'Kế hoạch',
  sidebar_task: 'Công việc',
  sidebar_assignToMe: 'Công việc của tôi',
  sidebar_fileStorage: 'Lưu trữ',
  sidebar_fileLog: 'Lịch sử lưu trữ',
  sidebar_members: 'Thành viên dự án',
  siderbar_projectCalendar: 'Lịch',
  siderbar_projectSetting: 'Thiết lập dự án',
  sidebar_users_permissions: 'Tài khoản & phân quyền',
  sidebar_users: 'Tài khoản',
  sidebar_department: 'Phòng ban',
  sidebar_roles_permissions: 'Vai trò & phân quyền',
  sidebar_system_calendar: 'Lịch hệ thống',
  siderbar_system_setting: 'Cài đặt hệ thống',
  status: {
    new: 'Mới',
    approved: 'Đã duyệt',
    rejected: 'Từ chối',
    'in-progress': 'Đang thực hiện',
    done: 'Hoàn thành',
    closed: 'Đóng',
  },
}
