import React from 'react'
import { Row, Col, Card, Typography } from 'antd'
import EndPoints from 'configs/endPoints'

const { Text } = Typography

const ProjectItem = (props) => {
  const { name, image, active } = props.data

  // const scheduleMapping = {
  //   active: { color: 'success', title: 'On Track' },
  //   LATE: { color: 'error', title: 'Late' },
  // }

  return (
    <Col xs={24} lg={12} xl={{ span: 8 }} xxl={{ span: 6 }}>
      <Card
        hoverable
        title={name}
        // className="card-wrapper"
        // style={{ maxWidth: '270px', maxHeight: '350px' }}
        onClick={() => props.onClick(props.data)}
        // cover={
        //   <div
        //     alt="example"
        //     style={{ backgroundImage: `url(${EndPoints.fileUrl}/${image})` }}
        //     className="static-img"
        //   />
        // }
      >
        <Card.Meta
          title={name}
          description={
            <Row style={{ display: 'flex', flexDirection: 'column' }}>
              <Text>
                Status:
                <Text className={`${active && 'text-green'} bold`}>{active ? ' ON TRACK' : ' LATE'}</Text>
              </Text>
              {/* <Badge status={scheduleMapping.ON_TRACK.color} /> */}
            </Row>
          }
        />
      </Card>
    </Col>
  )
}

export default ProjectItem
