import React, { useState } from 'react'
import documentation from '../../../assets/documentation/MYPROJECT_HDSD.pdf'
import moment from 'moment'
import PropTypes from 'prop-types'
import {
  Layout,
  Row,
  Typography,
  Col,
  Button,
  Dropdown,
  Menu,
  Badge,
  Tooltip,
  Empty,
  Tag,
  List,
  Popover,
  Modal,
} from 'antd'
import { BellFilled } from '@ant-design/icons'
import { connect } from 'react-redux'
import { logout } from 'store/actions/auth.action'
import { toggleMenu } from 'store/actions/common.action'
import * as notificationActions from 'store/actions/notification.action'
import { useLayoutHelper } from 'hooks'

import {
  ArrowLeftOutlined,
  UserOutlined,
  LogoutOutlined,
  MenuOutlined,
  SettingOutlined,
  CheckCircleFilled,
  WarningFilled,
  CloseCircleFilled,
  EditFilled,
  FolderFilled,
  FileTextOutlined,
  CalendarOutlined,
} from 'assets/icon'
import Logo from 'assets/images/tp-logo.png'
import { useHistory, useLocation, matchPath } from 'react-router-dom'
import { routes } from 'routes'
import isEmpty from 'lodash/isEmpty'
import { isAdmin } from 'utils/permission'
import { useSelector, useDispatch } from 'react-redux'
import endPoints from 'configs/endPoints'

import './NavBar.scss'

const { Text } = Typography
const dateFormat = 'YYYY-MM-DD HH:mm'

const notificationColor = {
  success: {
    color: '#87d068',
    icon: <CheckCircleFilled />,
  },
  warning: {
    color: '#87d068',
    icon: <WarningFilled />,
  },
  urgent: {
    color: '#87d068',
    icon: <EditFilled />,
  },
  late: {
    color: '#87d068',
    icon: <CloseCircleFilled />,
  },
  read: {
    color: '#f6f5f5',
  },
  unread: {
    color: '#f6f5f5',
  },
}

function NavBar(props) {
  const {
    className,
    style,
    logoClassName,
    selectedProject,
    toggleMenuAction,
    projectOverview,
    noBackButton,
    logoutAction,
    showLogo,
    isDisplayProjectName,
  } = props
  const location = useLocation()
  const dispatch = useDispatch()
  const { profile } = useSelector((state) => state.authState)
  const { notifications, unreadCount } = useSelector((state) => state.notificationState)
  const fetchNotifications = () => dispatch(notificationActions.fetchNotifications())
  const getUnreadCounts = () => dispatch(notificationActions.getUnreadCounts())
  const project = !isEmpty(selectedProject) ? selectedProject : projectOverview
  const history = useHistory()
  const { isToggleMenu: isMobile } = useLayoutHelper()
  const [isNotificationVisible, setIsNotificationVisible] = useState(false)

  const isHome = matchPath(location.pathname, {
    path: '/projects',
    exact: true,
  })

  const isBasePage =
    matchPath(location.pathname, {
      path: '/file-storage',
      exact: true,
    }) ||
    matchPath(location.pathname, {
      path: '/profile',
      exact: true,
    })

  const onLogout = () => {
    logoutAction(() => history.replace(routes.login))
  }

  const onToggleMobileMenu = () => {
    toggleMenuAction(true)
  }

  const renderMenu = () => (
    <Menu>
      <Menu.Item key="1" icon={<UserOutlined />} onClick={() => history.push(routes.profile)}>
        Hồ sơ
      </Menu.Item>
      {(isAdmin() || profile?.can_update_system_calendar) && (
        <Menu.Item key="2" icon={<CalendarOutlined />} onClick={() => history.push(routes.calendars)}>
          Lịch hệ thống
        </Menu.Item>
      )}
      {isAdmin() && (
        <Menu.Item key="3" icon={<SettingOutlined />} onClick={() => history.push(routes.users)}>
          Quản trị
        </Menu.Item>
      )}
      <Menu.Item key="4" icon={<FolderFilled />} onClick={() => history.push(routes.fileStorage)}>
        Lưu trữ
      </Menu.Item>
      <Menu.Item key="5" icon={<FileTextOutlined />}>
        <a target="_blank" href={documentation}>
          Tài liệu HDSD
        </a>
      </Menu.Item>
      <Menu.Item key="6" icon={<LogoutOutlined />} onClick={onLogout}>
        Đăng Xuất
      </Menu.Item>
    </Menu>
  )

  const handleMarkAsRead = async (item) => {
    try {
      if (item) {
        if (item.is_read === 0) {
          await notificationActions.markAsRead(item.id)
        }
        history.push(`/projects/${item.project_id}/gantt?task_id=${item.work_package_id}`)
      } else {
        await notificationActions.markAsRead()
      }
      setIsNotificationVisible(false)
      fetchData()

      return Promise.resolve()
    } catch (error) {
      Modal.error({
        title: 'Lỗi',
        content: error.message,
      })

      return Promise.reject(error)
    }
  }

  const fetchData = () => {
    fetchNotifications()
    getUnreadCounts()
  }

  const openNotificationPopover = () => {
    if (!isNotificationVisible) {
      fetchData()
    }
    setIsNotificationVisible(!isNotificationVisible)
  }

  const notificationContent = (
    <div className="message-popover">
      <div className="msg-header item-block"></div>
      <List
        className="notification"
        footer={
          <Button type="link" className="item-block ls" onClick={() => handleMarkAsRead()}>
            Đánh dấu tất cả đã đọc
          </Button>
        }
      >
        {notifications?.length > 0 ? (
          <>
            {notifications.map((item) => (
              <List.Item key={item.id} onClick={() => handleMarkAsRead(item)} className="notification-item">
                <Tag
                  color={item.is_read !== 0 ? notificationColor['read']?.color : 'white'}
                  className={item.is_read !== 0 ? 'hasRead' : ''}
                >
                  <div className="title">{item.title}</div>
                  <div
                    className="content"
                    dangerouslySetInnerHTML={{
                      __html: item.content,
                    }}
                  ></div>
                  <div className="date">
                    {item.updated_user?.full_name}{' '}
                    {moment(item.created_at, dateFormat).format(dateFormat) || ''}
                  </div>
                </Tag>
              </List.Item>
            ))}
          </>
        ) : (
          <Empty style={{ padding: '20px 0' }} />
        )}
      </List>
    </div>
  )

  return (
    <Layout.Header className={`nav-bar ${className || ''}`} style={style}>
      <Row align="middle" justify="center" style={{ height: '100%', flexWrap: 'initial' }}>
        {isMobile && (
          <Col md={{ span: 7 }} xs={{ span: 9 }}>
            {!isHome && !isBasePage && (
              <Button type="link" onClick={onToggleMobileMenu} className="menu-drawer-trigger">
                <MenuOutlined />
              </Button>
            )}
          </Col>
        )}
        {(!isHome && !isMobile) || isBasePage ? (
          <Button
            type={'text'}
            // align="middle"
            onClick={() => history.replace(routes.projects)}
            // onClick={() => console.log(history)}
            // className="pointer"
            // style={{ flexWrap: 'initial' }}
            style={{ position: 'absolute', left: 0, paddingLeft: 20, zIndex: 9999 }}
            icon={<ArrowLeftOutlined style={{ paddingRight: 10 }} />}
          >
            {isDisplayProjectName && (
              <Text style={{ marginBottom: 0, fontSize: 16, color: '#000' }}>{project.name}</Text>
            )}
          </Button>
        ) : (
          ''
        )}

        {showLogo && (
          <Col md={{ offset: !isMobile ? 7 : 0, span: 10 }} xs={{ span: 6 }}>
            <Row justify={'center'} align="middle">
              <img alt="logo" src={Logo} className={`${logoClassName} logo-header`} />
            </Row>
          </Col>
        )}

        <Col flex="auto">
          <Row justify="end" align="middle">
            <ul className="right navbar-action-list">
              <Popover
                content={notificationContent}
                trigger="click"
                visible={isNotificationVisible}
                onVisibleChange={openNotificationPopover}
              >
                <li>
                  <Badge count={unreadCount} size="small">
                    <BellFilled style={{ fontSize: 22 }} />
                  </Badge>
                </li>
              </Popover>
              <Dropdown overlay={renderMenu} placement="bottomCenter">
                <li>
                  <div className="pointer">
                    {profile.image ? (
                      <img src={`${endPoints.fileUrl}/${profile.image}`} className="avatar" alt="avatar" />
                    ) : (
                      <Button shape="circle">
                        <UserOutlined />
                      </Button>
                    )}
                  </div>
                  {!isMobile && (
                    <Text className="navbar-username ml-xs">
                      <Tooltip placement="bottom" title={profile.full_name}>
                        {profile.first_name}
                      </Tooltip>
                    </Text>
                  )}
                </li>
              </Dropdown>
            </ul>
          </Row>
        </Col>
      </Row>
    </Layout.Header>
  )
}

export default connect(
  ({ projectState, common }) => ({
    selectedProject: projectState.selectedProject,
    projectOverview: projectState.projectOverview,
    isMenuOpen: common.isMenuOpen,
  }),
  {
    logoutAction: logout,
    toggleMenuAction: toggleMenu,
  },
)(NavBar)

NavBar.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  project: PropTypes.object,
  logoClassName: PropTypes.string,
  setSelectedFolderData: PropTypes.func,
  noBackButton: PropTypes.bool,
}
