import React, { useEffect, useState, useRef } from 'react'
import { Row, Col, Button, Select, Form, Modal, Checkbox, Space } from 'antd'

import { filterString } from 'utils/utility'
import { MultiDatePicker } from 'components'
import { Api } from 'services'
import endPoints from 'configs/endPoints'
import { isAdmin } from 'utils/permission'

const CheckboxGroup = Checkbox.Group

const initialState = {
  holidayList: [],
  weekdays: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
  addedDepartment: [],
}

function CalendarAction({
  isVisible,
  onCancel,
  onSuccess,
  projectId,
  onSubmit,
  departments,
  rowData,
  calendarList,
  nonResetForm,
  profile,
}) {
  const [state, setState] = useState(initialState)
  const [form] = Form.useForm()
  const disabled = !isVisible
  const defaultWeekdays = {
    monday: 1,
    tuesday: 1,
    wednesday: 1,
    thursday: 1,
    friday: 1,
    saturday: 0,
    sunday: 0,
  }

  useEffect(() => {
    form.resetFields()
    if (isVisible && !rowData) {
      let days = Object.keys(defaultWeekdays).filter((key) => defaultWeekdays[key] == 1)

      form.setFieldsValue({
        department_id: null,
        weekdays: days,
      })
    }
  }, [isVisible, profile?.department_id])

  useEffect(() => {
    if (isVisible && rowData) {
      setState({ ...state, holidayList: rowData.department_holidays })

      form.setFieldsValue({
        department_id: rowData.department_id,
        weekdays: rowData.department_weekdays,
      })
    }
  }, [isVisible, rowData])

  useEffect(() => {
    if (!isVisible) {
      form.resetFields()
    }
  }, [isVisible])

  const getSystemCalendar = async () => {
    const { data } = await Api.get(
      `${endPoints.system_calendar}/department_calendar/${form.getFieldValue('department_id')}`,
    )
    if (!data) {
      return Modal.info({ title: 'Thông báo', content: 'Phòng ban chưa được tạo lịch trong hệ thống' })
    }
    const currentRow = JSON.parse(data.value)
    let weeks = { ...currentRow }
    delete weeks.holidays
    weeks = Object.keys(weeks).filter((key) => weeks[key] == 1)
    currentRow.holidayList = currentRow.holidays
    currentRow.weekdays = weeks
    setState({ ...state, ...currentRow })
    form.setFieldsValue({
      weekdays: currentRow.weekdays,
    })
  }

  const submitHandler = async (data) => {
    let days = {
      monday: 0,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
      sunday: 0,
    }

    state.weekdays.forEach((day) => {
      if (days[day] !== undefined) {
        days[day] = 1
      }
    })

    const formData = {
      project_id: projectId,
      department_id: data.department_id,
      holidays: state.holidayList,
      ...days,
      ...(rowData ? { id: rowData.id } : {}),
    }

    await onSubmit({ ...formData })
    setState({ ...initialState })
    if (!nonResetForm) {
      form.resetFields()
    }
    onSuccess()
  }
  const onChangeSelectWeekday = (checked) => {
    setState({ ...state, weekdays: checked })
  }

  return isVisible ? (
    <Row gutter={[20, 20]} style={{ margin: 0 }}>
      <Col flex="1 1 auto" xs={24} style={{ padding: 0 }}>
        {/* <pre>{JSON.stringify(state, null, 2)}</pre> */}
        <Form id="projectcalendar-form" form={form} name="dynamic_rule" onFinish={submitHandler}>
          <Row gutter={[20, 20]}>
            <Col sm={7} xs={24}>
              <Form.Item
                name="department_id"
                style={{ marginBottom: 0, width: '100%' }}
                rules={[{ required: true, message: 'Vui lòng chọn phòng ban' }]}
              >
                <Select
                  disabled={(isVisible && rowData?.id) || false}
                  placeholder="Chọn phòng ban"
                  style={{ marginBottom: 0, width: '100%' }}
                  showSearch
                  optionFilterProp="name"
                  filterOption={(input, option) =>
                    filterString(input, option.children) || filterString(input, option.label)
                  }
                >
                  {departments.map((item) => {
                    let isAdded = calendarList.find((key) => key.department_id === item.id)
                    return (
                      <Select.Option disabled={isAdded ? true : false} key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
            {projectId && (
              <Col sm={15} xs={24}>
                <Button
                  type="primary"
                  onClick={() => {
                    if (!form.getFieldValue('department_id')) {
                      return Modal.error({
                        title: 'Lỗi',
                        content: 'Vui lòng chòn phòng ban cần sao chép',
                      })
                    }
                    getSystemCalendar()
                  }}
                >
                  Sao chép lịch hệ thống
                </Button>
              </Col>
            )}
          </Row>
          <Row gutter={[20, 20]}>
            <Col sm={12} xs={12}>
              <h4>Ngày nghỉ</h4>
              <MultiDatePicker
                value={state.holidayList}
                calendarFormat="YYYY-MM-DD"
                onChange={(value) => {
                  setState({ ...state, holidayList: value })
                }}
              />
            </Col>
          </Row>
          <Form.Item name="weekdays">
            <CheckboxGroup key={Math.random()} value={state.weekdays} onChange={onChangeSelectWeekday}>
              <Checkbox value={'monday'}>Monday</Checkbox>
              <Checkbox value={'tuesday'}>Tuesday</Checkbox>
              <Checkbox value={'wednesday'}>Wednesday</Checkbox>
              <Checkbox value={'thursday'}>Thursday</Checkbox>
              <Checkbox value={'friday'}>Friday</Checkbox>
              <Checkbox value={'saturday'}>Saturday</Checkbox>
              <Checkbox value={'sunday'}>Sunday</Checkbox>
            </CheckboxGroup>
          </Form.Item>
          <Row gutter={[20, 20]}>
            <Col sm={24} xs={12}>
              <Space size="small">
                <Button htmlType="submit" type="primary">
                  Lưu
                </Button>
                {!nonResetForm && (
                  <Button
                    onClick={() => {
                      if (projectId) {
                        onCancel()
                      } else {
                        setState(initialState)
                        form.resetFields()
                        onCancel()
                      }
                    }}
                  >
                    {projectId ? 'Đóng' : 'Hủy'}
                  </Button>
                )}
              </Space>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  ) : null
}

export default CalendarAction
