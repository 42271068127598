import { routeList, routePage } from 'routes'
import { permission } from 'utils/permission'
import { Api } from 'services'
import endPoints from 'configs/endPoints'

export const SET_ROUTES = 'auth/SET_ROUTES'
export const SET_PROJECT = 'auth/SET_PROJECT'
export const AUTH_LOGIN = 'auth/AUTH_LOGIN'
export const AUTH_LOGOUT = 'auth/AUTH_LOGOUT'
export const AUTH_PROFILE = 'auth/AUTH_PROFILE'
export const UPDATE_PROFILE = 'auth/UPDATE_PROFILE'
export const UPDATE_AVARTAR = 'auth/UPDATE_AVARTAR'
export const AUTH_PROJECT = 'auth/AUTH_PROJECT'

function getCookie(cname) {
  const name = `${cname}=`
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

function setCookie(name, value) {
  document.cookie = `${name}=${value};path=/`
}

/* get authenticated user */
function getProfile() {
  return async (dispatch) => {
    try {
      const { data } = await Api.get(endPoints.profile)
      dispatch(generateRoutes(data.role_permissions, data.role))
      dispatch({
        type: AUTH_PROFILE,
        payload: data,
      })
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }
}

/* update user profile */
function updateProfile(formData) {
  return async (dispatch) => {
    try {
      const { data } = await Api.put('/users/update_profile', formData)
      dispatch({
        type: UPDATE_PROFILE,
        payload: data,
      })
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }
}

/* update user image */
function updateAvatar(avatar) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_AVARTAR,
      payload: avatar,
    })
  }
}

function updatePassword(formData) {
  return async () => {
    try {
      const { data } = await Api.put('/users/update_password', formData)
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }
}

/* get role of user for a specific project */
function getProjectRole(projectId) {
  return async (dispatch, getState) => {
    try {
      const { authState } = getState()
      const projectRole = authState.project_users.find((item) => item.project_id === +projectId)
      /* if the user is not Admin and doesn't have role of selected project, return error  */
      if (!projectRole && authState.role.slug !== process.env.REACT_APP_IS_ADMIN) {
        return Promise.reject({ message: "Don't have permission" })
      }
      const allowedRoutes = authState.accessedRoutes.filter((route) => route.routePage !== routePage.project)
      const role = authState.role.slug === process.env.REACT_APP_IS_ADMIN ? authState.role : projectRole?.role
      dispatch(generateRoutes(projectRole?.role_permissions, role, true, allowedRoutes))
      return Promise.resolve()
    } catch (err) {
      console.log(err)
      return Promise.reject(err)
    }
  }
}

/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    let allowed = roles.some((role) => route.meta.roles.includes(role.slug))
    if (allowed) return allowed
    for (const route_role of route.meta.roles) {
      if (route_role.includes(':') && permission(route_role, { role_permissions: roles })) {
        return true
      }
    }
  } else {
    return true
  }
}

function filterAsyncRoutes(routes, roles, isProjectRole = false) {
  const res = []

  routes.forEach((route) => {
    const tmp = { ...route }
    if (
      ((isProjectRole && route.routePage === routePage.project) ||
        (!isProjectRole && route.routePage !== routePage.project)) &&
      hasPermission(roles, tmp)
    ) {
      if (tmp.submenu) {
        tmp.submenu = filterAsyncRoutes(tmp.submenu, roles, isProjectRole)
      }
      res.push(tmp)
    }
  })

  return res
}

function generateRoutes(permissions = [], role, isProjectRole = false, allowedRoutes = []) {
  let accessedRoutes
  if (role.slug === process.env.REACT_APP_IS_ADMIN) {
    accessedRoutes = routeList.filter(
      (route) =>
        (isProjectRole && route.routePage === routePage.project) ||
        (!isProjectRole && route.routePage !== routePage.project),
    )
  } else {
    accessedRoutes = filterAsyncRoutes(routeList, permissions, isProjectRole)
  }
  if (isProjectRole) {
    accessedRoutes = [...allowedRoutes, ...accessedRoutes]
  }
  return { type: SET_ROUTES, payload: accessedRoutes }
}

function setRouteProjectId(projectId = 0) {
  return { type: SET_PROJECT, payload: projectId }
}

function login(params, successCB = () => {}, errorCB = () => {}) {
  return (dispatch) => {
    Api.post(endPoints.login, params)
      .then(({ data }) => {
        setCookie('token', data?.access_token)
        Api.setToken(data?.access_token)
        successCB()
        // handlePopupAlert('success', data?.message || 'Login success')
        dispatch({
          type: AUTH_LOGIN,
          payload: data.access_token,
        })
      })
      .catch((error) => {
        errorCB(error)
        // console.log('error: ', error)
        // handlePopupAlert('error')
      })
  }
}

function logout(successCB = () => {}) {
  return async (dispatch) => {
    Api.get(endPoints.logout)
      .then(() => {
        setCookie('token', '')
        dispatch({
          type: AUTH_LOGOUT,
        })
        Api.unsetToken()
      })
      .catch(() => {
        setCookie('token', '')
        dispatch({
          type: AUTH_LOGOUT,
        })
        Api.unsetToken()
      })
    successCB()
  }
}

function forgotPassword(data) {
  return Api.post('/password/create', data)
}

function findUserPassword(token) {
  return Api.get(`/password/find/${token}`)
}

function resetPassword(data) {
  return Api.post('/password/reset', data)
}

export {
  setCookie,
  getCookie,
  login,
  logout,
  getProfile,
  generateRoutes,
  getProjectRole,
  setRouteProjectId,
  updateProfile,
  updatePassword,
  updateAvatar,
  forgotPassword,
  findUserPassword,
  resetPassword,
}
