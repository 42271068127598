import { combineReducers } from 'redux'

import common from 'store/reducers/common.reducer'
import projectReducer from 'store/reducers/project.reducer'
import userReducer from 'store/reducers/user.reducer'
import departmentReducer from 'store/reducers/department.reducer'
import filestorageReducer from 'store/reducers/filestorage.reducer'
import roleReducer from 'store/reducers/role.reducer'
import authReducer from 'store/reducers/auth.reducer'
import projectUserReducer from 'store/reducers/project_user.reducer'
import notificationReducer from 'store/reducers/notification.reducer'
import projectCalendarReducer from 'store/reducers/project_calendar.reducer'
import systemCalendarReducer from 'store/reducers/system_calendar.reducer'
import { AUTH_LOGOUT } from 'store/actions/auth.action'

const appReducer = combineReducers({
  common,
  projectState: projectReducer,
  userState: userReducer,
  departmentState: departmentReducer,
  filestorageState: filestorageReducer,
  roleState: roleReducer,
  authState: authReducer,
  projectUserState: projectUserReducer,
  notificationState: notificationReducer,
  projectCalendarState: projectCalendarReducer,
  systemCalendarState: systemCalendarReducer,
})

const rootReducer = (state, action) => {
  if (action.type === AUTH_LOGOUT) {
    state = {
      common: state.common,
    }
  }
  return appReducer(state, action)
}

export default rootReducer
