import React from 'react'
import { Modal } from 'antd'

const modal = (props) => {
  return (
    <Modal
      title={props.title}
      okButtonProps={{
        form: props.formName,
        key: 'submit',
        htmlType: 'submit',
        loading: props.loading,
      }}
      visible={props.visible}
      onCancel={props.onCancel}
      width={props.width ?? 600}
      okText="Lưu"
      cancelText="Hủy bỏ"
      maskClosable={false}
    >
      {props.children}
    </Modal>
  )
}

export default modal
