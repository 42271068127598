import moment from 'moment'
export const dateFormat = 'YYYY-MM-DD HH:mm'
export const ONE_DAY_TIMESTAMP = 60 * 60 * 24 * 1000 - 1
/**
 * Get first date of the current month
 *
 * @param {string} format
 */
export function getFirstDayOfCurMonth(dateFormat = 'timestamp') {
  const curDate = new Date()
  curDate.setDate(1)
  curDate.setHours(0, 0, 0)
  return dateFormat === 'timestamp' ? curDate.getTime() : moment(curDate).format(dateFormat)
}

/**
 * Get last date of the current month
 *
 * @param {string} format
 */
export function getLastDayOfCurMonth(dateFormat = 'timestamp') {
  const curDate = new Date()
  curDate.setDate(moment().daysInMonth())
  curDate.setHours(23, 59, 59)
  return dateFormat === 'timestamp' ? curDate.getTime() : moment(curDate).format(dateFormat)
}
