import React, { useEffect } from 'react'
import { Tooltip, Space } from 'antd'
import {
  EmailShareButton,
  EmailIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  ViberShareButton,
  ViberIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share'

function FileSocialShare(props) {
  const { url, name } = props

  useEffect(() => {
    const script = document.createElement('script')

    script.src = 'https://sp.zalo.me/plugins/sdk.js'
    script.async = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [url])

  return (
    <Space align="center" size="middle">
      <FacebookMessengerShareButton url={url} title={name} appId={`${process.env.REACT_APP_FACEBOOK_APP_ID}`}>
        <FacebookMessengerIcon size={40} round />
      </FacebookMessengerShareButton>

      <EmailShareButton
        url={url}
        subject={`Tienphat - FileStorage | ${name}`}
        body={`This file is shared via Myproject - FileStorage \n \n ${name} \n`}
        className="file__share-button --email"
      >
        <EmailIcon size={40} round />
      </EmailShareButton>

      <ViberShareButton url={url} title={name} className={'file__share-button --viber'}>
        <ViberIcon size={40} round />
      </ViberShareButton>

      <div
        dangerouslySetInnerHTML={{
          __html: `<div class="zalo-share-button" data-href="${url}" data-oaid="579745863508352884" data-layout="4" data-color="blue" data-customize=false></div>`,
        }}
      ></div>
    </Space>
  )
}

export default FileSocialShare
