import React, { useEffect, useState } from 'react'
import TableFile from './TableFile'
import moment from 'moment'
import { Tooltip } from 'antd'
import { LineOutlined } from 'assets/icon'
import { dateFormat, formatFileSize } from 'utils'
import { FileList } from 'components'

function DirectoryList({
  isSelectedProject,
  selectedProject,
  data,
  fileList,
  folderList,
  isBrowsing,
  isLoading,
  onDelete,
  onAdd,
  onCreateFolder,
  setSelectedFolderData,
  breadscrumb,
  handleFileButton,
  handleFolderButton,
  isSelectedDepartment,
}) {
  const [mergedData, setMergedData] = useState([])

  useEffect(() => {
    if (isSelectedProject && !isBrowsing) {
      setMergedData([...selectedProject.departments, ...fileList, ...folderList])
    } else {
      setMergedData([...data, ...fileList])
    }
  }, [fileList, folderList, data, breadscrumb])

  // useEffect(() => {
  //   if (isSelectedProject && folderList?.length) {
  //     setMergedData([...mergedData, ...folderList])
  //   }
  // }, [folderList])

  const tableColumnsDirectory = [
    {
      title: 'Tên',
      dataIndex: ['name', 'file_name'],
      key: ['name', 'file_name'],
      // width: '65%',
      render: (text, record) => {
        return <div className="--truncated">{record.name || record.file_name || <LineOutlined />}</div>
      },
    },
    {
      title: 'Owner',
      dataIndex: 'user_id ',
      key: 'user_id ',
      width: 120,
      sorter: true,
      align: 'center',
      fixed: 'center',
      render: (user_id) => {
        return (user_id && 'admin') || <LineOutlined />
      },
    },
    {
      title: 'Dung lượng',
      dataIndex: 'file_size',
      key: 'file_size',
      width: 120,
      align: 'center',
      fixed: 'center',
      render: (file_size, row) => {
        if (row.file_extension !== 'folder' && row.file_extension) {
          return formatFileSize(file_size)
        }
        return <LineOutlined />
      },
    },
    {
      title: 'Cập nhật',
      dataIndex: 'updated_at',
      key: 'updated_at',
      width: 120,
      sorter: true,
      render: (updated_at) => {
        let date = isSelectedProject ? selectedProject.updated_at : updated_at
        return moment(date, dateFormat).format(dateFormat) || <LineOutlined />
      },
    },
  ]

  return (
    <>
      <TableFile
        isSelectedProject={isSelectedProject}
        isSelectedDepartment={isSelectedDepartment}
        selectedProject={selectedProject}
        columns={tableColumnsDirectory}
        //dataSource={isSelectedProject ? selectedProject.departments : data}
        dataSource={mergedData}
        isLoading={isLoading}
        rowKey="id"
        isBrowsing={isBrowsing}
        onDelete={onDelete}
        onAdd={onAdd}
        onCreateFolder={onCreateFolder}
        setSelectedFolderData={setSelectedFolderData}
        setSelectedFolderData={setSelectedFolderData}
        breadscrumb={breadscrumb}
        handleFileButton={handleFileButton}
        handleFolderButton={handleFolderButton}
      />
    </>
  )
}

export default DirectoryList
