import React from 'react'
import { Card, List, Row, Col, Divider, Button } from 'antd'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import moment from 'moment'

import { MiniProgress } from 'components'
import ProjectDurationChart from './ProjectDurationChart'
import ProjectBudgetChart from './ProjectBudgetChart'
import { STATUS_DONE, STATUS_CLOSE } from 'configs/constants'
import permission from 'utils/permission'

const gridConfig = {
  gutter: 16,
  xs: 1,
  sm: 2,
  md: 3,
  lg: 3,
  xl: 4,
  xxl: 4,
}

const ProjectList = (props) => {
  const { loading, projects, onPress, onDelete, onEdit } = props

  return (
    <List
      rowKey="id"
      loading={loading && !projects.length}
      grid={gridConfig}
      dataSource={projects}
      renderItem={(project) => {
        const progress = Math.floor(project.root_task?.progress * 100)
        const duration = moment(project.end_date).diff(moment(project.start_date), 'days') + 1
        const remain = moment(project.end_date).diff(moment(), 'days') + 1

        /*
         * current date is before start_date, duration percent = 0
         * current date is after end_date, duration percent = 100
         * otherwise, duration percent = duration / total duration
         */
        const durationPercent = Math.floor(
          moment().isAfter(moment(project.end_date))
            ? 100
            : moment().isBefore(moment(project.start_date))
            ? 0
            : ((moment().diff(moment(project.start_date), 'days') + 1) * 100) / duration,
        )
        let statusColor = '#357638'
        /*
         * if current date is after end_date and the root task is not completed/closed => in trouble
         * if the root task is not completed/closed but actual_end_date is after target_end_date => in trouble
         */
        if (
          (moment().isAfter(moment(project.end_date)) &&
            project.root_task?.status_id !== STATUS_DONE &&
            project.root_task?.status_id !== STATUS_CLOSE) ||
          (moment(project.root_task?.target_end_date).isBefore(moment(project.root_task?.end_date)) &&
            project.root_task?.status_id === STATUS_DONE &&
            project.root_task?.status_id === STATUS_CLOSE)
        ) {
          statusColor = '#eb2f08'
        } else if (
          project.root_task?.status_id !== STATUS_DONE &&
          project.root_task?.status_id !== STATUS_CLOSE &&
          progress < durationPercent
        ) {
          /*
           * if project is not complented/closed and the progress is less than durationPercent => at risk
           */
          statusColor = '#f6b93b'
        }

        /**
         * Project progress duration
         */
        const projectDurationProgress = project.departmentWorkload?.reduce(
          (a, v) => (a = a + Math.floor((v.duration * 100) / project.projectWorkload.total)),
          0,
        )

        return (
          <List.Item key={project.id}>
            <Card
              hoverable
              className="project-card"
              headStyle={{ background: statusColor }}
              title={
                <div className="project-title" onClick={() => onPress(project)}>
                  {project.name}
                </div>
              }
              actions={[
                ...(permission('project:update')
                  ? [
                      <Button type={'link'} onClick={() => onEdit(project)}>
                        <EditOutlined key="edit" />
                      </Button>,
                    ]
                  : []),
                ...(permission('project:delete')
                  ? [
                      <Button type={'link'} danger onClick={() => onDelete(project)}>
                        <DeleteOutlined key="delete" />
                      </Button>,
                    ]
                  : []),
              ]}
            >
              <div className="project-card-body" onClick={() => onPress(project)}>
                <p>LỊCH TRÌNH</p>
                <Row>
                  <Col span={12}>
                    <b>BẮT ĐẦU</b>
                  </Col>
                  <Col span={12}>{moment(project.start_date).format('DD-MM-YYYY')}</Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <b>KẾT THÚC</b>
                  </Col>
                  <Col span={12}>{moment(project.end_date).format('DD-MM-YYYY')}</Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <b>THỜI HẠN</b>
                  </Col>
                  <Col span={12}>{duration} ngày</Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <b>NGÀY CÒN LẠI</b>
                  </Col>
                  <Col span={12}>{remain > 0 ? remain : 0} ngày</Col>
                </Row>
                <div style={{ paddingTop: 20, paddingBottom: 10 }}>
                  <ProjectDurationChart data={project} />
                </div>
                <Divider />
                <span>% HOÀN THÀNH</span>
                <MiniProgress
                  percent={projectDurationProgress}
                  showRemaining={false}
                  strokeWidth={25}
                  color={statusColor}
                  showPercent
                />
                <Divider />
                <p>NGÂN SÁCH</p>
                <Row>
                  <Col span={24}>
                    <ProjectBudgetChart project={project} />
                  </Col>
                </Row>
                {project.created_user ? (
                  <div className="text-right mt-sm">
                    Người tạo: <b>{project.created_user?.full_name}</b>
                  </div>
                ) : null}
              </div>
            </Card>
          </List.Item>
        )
      }}
    />
  )
}
export default ProjectList
