import React, { useState } from 'react'
import { Modal, notification } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'

import { paginationData } from 'utils/utility'
import useMergeState from './useMergeState'

const { confirm } = Modal

const useBasePage = ({ saveFn, deleteFn, fetchFn }) => {
  const [filterData, setFilterData] = useState({ ...paginationData })
  const [otherData, setOtherData] = useMergeState({
    isShowModal: false,
    isSaving: false,
    selectedItem: null,
  })

  const onFilter = (customFilter) => {
    fetchFn({...filterData, ...customFilter})
  }

  const onFilterChange = (value, key) => {
    setFilterData((prevData) => ({
      ...prevData,
      [key]: value,
    }))
  }

  const onFilterSet = (filters) => {
    setFilterData((prevData) => ({
      ...prevData,
      ...filters,
    }))
  }

  const onPageChange = (page, pageSize) => {
    setFilterData((prevData) => ({
      ...prevData,
      limit: pageSize,
      page,
    }))
  }

  const onModalHandler = () => {
    setOtherData({ isShowModal: !otherData.isShowModal })
  }

  const onOpenForm = (item = null) => {
    setOtherData({ selectedItem: item })
    onModalHandler()
  }

  const onSubmit = async (data) => {
    setOtherData({ isSaving: true })
    try {
      await saveFn(data)
      setOtherData({ isSaving: false, isShowModal: false })
      notification.success({
        message: 'Save successfully',
      })
      onFilter()
      return Promise.resolve()
    } catch (error) {
      setOtherData({ isSaving: false })
      Modal.error({
        title: 'Lỗi',
        content: error.message,
      })
      return Promise.reject(error)
    }
  }

  const onDelete = (id, options) => {
    confirm({
      title: 'Are you sure delete this item?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      async onOk() {
        try {
          await deleteFn(id, options)
          notification.success({
            message: 'Delete successfully',
          })
          onFilter()
        } catch (error) {
          Modal.error({
            title: 'Lỗi',
            content: error.message,
          })
        }
      },
    })
  }

  return {
    filterData,
    isShowModal: otherData.isShowModal,
    selectedItem: otherData.selectedItem,
    isSaving: otherData.isSaving,
    onFilter,
    onSubmit,
    onDelete,
    onOpenForm,
    onPageChange,
    onFilterChange,
    onFilterSet,
    onModalHandler,
  }
}
export default useBasePage
