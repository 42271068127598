import React from 'react'
import { Table } from 'antd'
import { CheckCircleTwoTone } from '@ant-design/icons'

import { TableAction } from 'components/Common'
import { ADMIN_ROLE_ID } from 'configs/constants'
import permission from 'utils/permission'

const UserList = (props) => {
  const columns = [
    {
      title: 'Tài khoản',
      dataIndex: 'username',
    },
    {
      title: 'Tên',
      dataIndex: 'full_name',
    },
    {
      title: 'Email',
      dataIndex: 'email',   
    },
    {
      title: 'Phòng ban',
      dataIndex: ['department', 'name'],
    },
    {
      title: 'Admin',
      dataIndex: ['role'],
      align: 'center',
      render: (item) => {
        if (item.id === ADMIN_ROLE_ID) {
          return <CheckCircleTwoTone style={{ fontSize: 18 }} twoToneColor="#52c41a" />
        }
      },
    },
    ...(permission(`${props.roleName}:edit,${props.roleName}:delete`)
      ? [
          {
            title: '',
            width: '20%',
            align: 'center',
            render: (item) => (
              <TableAction
                roleName={props.roleName}
                item={item}
                onEdit={props.onEdit}
                onDelete={props.onDelete}
              />
            ),
          },
        ]
      : []),
  ]

  const { data } = props

  return (
    <Table
      pagination={false}
      columns={columns}
      dataSource={data}
      rowKey="id"
      bordered
      // scroll={{ x: '767px', y: 414 }}
    />
  )
}

export default UserList
