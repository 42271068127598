import { Api } from 'services'
import endPoints from 'configs/endPoints'
import { toggleLoading } from 'store/actions/common.action'
import * as actionTypes from 'store/types/notification.action.types'

export const fetchNotifications = () => {
  return async (dispatch) => {
    dispatch(toggleLoading(true))

    const { data } = await Api.get(endPoints.notification)
    dispatch({
      type: actionTypes.FETCH_NOTIFICATION,
      payload: data,
    })
    dispatch(toggleLoading(false))
  }
}

export const getUnreadCounts = () => {
  return async (dispatch) => {
    const { data } = await Api.get(`${endPoints.notification}/count_unread`)
    dispatch({
      type: actionTypes.FETCH_UNREADCOUNT,
      payload: data,
    })
  }
}

export const markAsRead = async (id) => {
  try {
    if (id) {
      const { data } = await Api.put(`${endPoints.notification}/${id}`)
      console.log(data)
      return Promise.resolve(data)
    } else {
      const { data } = await Api.put(`${endPoints.notification}/mark_all_as_read`)
      console.log(data)
      return Promise.resolve(data)
    }
  } catch (error) {
    return Promise.reject(error)
  }
}

// export const deleteNotification = async (notificationId) => {
//   try {
//     const { data } = await Api._delete(`${endPoints.notifications}/${notificationId}`)
//     return Promise.resolve(data)
//   } catch (error) {
//     return Promise.reject(error)
//   }
// }
