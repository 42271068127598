import { Api } from 'services'
import endPoints from 'configs/endPoints'
import { toggleLoading } from 'store/actions/common.action'
import { likeFilter, whereFilter, orderBy, convertToFormData, fileDownload } from 'utils/utility'
import * as actionTypes from 'store/types/filestorage.action.types'
import moment from 'moment'
import { batch } from 'react-redux'

export const fetchFileStorage = (filterData) => {
  return async (dispatch) => {
    dispatch(toggleLoading(true))
    const params = {
      page: filterData.page,
      limit: filterData.limit,
      ...filterData,
      ...likeFilter('name', filterData.name || '*'),
      ...whereFilter('department_id', filterData.department_id),
      ...likeFilter(
        'created_at',
        filterData.created_at && moment(filterData.created_at).isValid() ? filterData.created_at : '*',
      ),
      ...orderBy({ name: 'is_folder', value: 'desc' }),
    }
    const { data, total } = await Api.get(endPoints.filestorages, params)
    let userPermissions = null
    if (!isNaN(params.parent_id)) {
      userPermissions = await fetchUserPermissions(params.parent_id)
    }

    batch(() => {
      dispatch({
        type: actionTypes.FETCH_FILESTORAGE,
        payload: { data, total },
      })
      dispatch({
        type: actionTypes.FETCH_USERPERMISSION,
        payload: userPermissions,
      })
    })

    dispatch(toggleLoading(false))
  }
}

export const downloadFileStorage = (id, folderName) => {
  return async (dispatch) => {
    dispatch(toggleLoading(true))
    const response = await Api.get(`${endPoints.filestorages}/${id}/download`, {}, { responseType: 'blob' })
    fileDownload(response, `${folderName}.zip`)
    dispatch(toggleLoading(false))
  }
}

// export const fetchFileFolder = (filterData) => {
//   return async (dispatch) => {
//     dispatch(toggleLoading(true))
//     const params = {
//       page: filterData.page,
//       limit: filterData.limit,
//       ...filterData,
//     }
//     const { data, total } = await Api.get(endPoints.filefolders, params)

//     dispatch({
//       type: actionTypes.FETCH_FILESTORAGEFOLDER,
//       payload: { data, total },
//     })
//     dispatch(toggleLoading(false))
//   }
// }

// export const fetchFileStorageProject = () => {
//   return async (dispatch) => {
//     dispatch(toggleLoading(true))
//     // const params = {
//     //   page: filterData.page,
//     //   limit: filterData.limit,
//     //   // ...likeFilter('name', filterData.name),
//     //   ...whereFilter('department_id', filterData.department_id),
//     // }
//     const { data, total } = await Api.get(`${endPoints.projects}/project_departments`)
//     dispatch({
//       type: actionTypes.FETCH_FILESTORAGEPROJECT,
//       payload: { data, total },
//     })
//     dispatch(toggleLoading(false))
//   }
// }

export const uploadFile = (formData) => {
  return async (dispatch) => {
    try {
      let config = {
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          percentCompleted < 70 || (percentCompleted = 70)
          dispatch({
            type: actionTypes.FETCH_UPLOADPROGRESS,
            payload: percentCompleted,
          })
        },
      }
      const postData = convertToFormData(formData)
      const { data } = await Api.postFormData(endPoints.filestorages, postData, config)
      dispatch({
        type: actionTypes.FETCH_UPLOADPROGRESS,
        payload: 100,
      })
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject()
    }
  }
}

export const createFolder = async (formData) => {
  try {
    if (formData.id) {
      const { data } = await Api.put(`${endPoints.filefolders}/${formData.id}`, formData)
      return Promise.resolve(data)
    } else {
      const { data } = await Api.post(endPoints.filefolders, formData)
      return Promise.resolve(data)
    }
  } catch (error) {
    return Promise.reject(error)
  }
}

export const updateFile = async (id, formData) => {
  try {
    if (formData.id) {
      const { data } = await Api.put(`${endPoints.filestorages}/${formData.id}`, formData)
      return Promise.resolve(data)
    } else {
      const { data } = await Api.post(endPoints.filestorages, formData)
      return Promise.resolve(data)
    }
  } catch (error) {
    return Promise.reject(error)
  }
}

export const deleteFile = async (fileId) => {
  try {
    const { data } = await Api._delete(`${endPoints.filestorages}/${fileId}`)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const deleteFolder = async (folderId) => {
  try {
    const { data } = await Api._delete(`${endPoints.filefolders}/${folderId}`)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const saveFilePermission = async (formData, fileID) => {
  try {
    const { data } = await Api.post(`${endPoints.filestorages}/${fileID}/file_permissions`, formData)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const deleteFilePermission = async (fileID, permissionId) => {
  try {
    const { data } = await Api._delete(`${endPoints.filestorages}/${fileID}/file_permissions/${permissionId}`)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchUserPermissions = async (fileID) => {
  return await Api.get(`${endPoints.filestorages}/${fileID}/file_permissions/user`)
}
