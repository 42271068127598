import React, { useState, useMemo, useCallback, forwardRef } from 'react'
import { Tag, Input, DatePicker } from 'antd'
import moment from 'moment'
import { dateFormat } from 'utils'

function validateArray(value) {
  const arr = Array.isArray(value) ? value : [value]
  return arr.filter((e) => e != null && e != undefined)
}

const MultiDatePicker = forwardRef((props, ref) => {
  const calendarFormat = props.calendarFormat ?? dateFormat
  const { onChange, value, format } = props
  const arrValues = useMemo(() => validateArray(value), [value])
  const [typingText, setTypingText] = useState('')
  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null })

  const Holiday = useCallback(
    ({ value, onClose }) => {
      return (
        <Tag onClose={() => onClose(value)} className="project-calendar__holiday-list" closable>
          <span className="name"> {value.name || ''}</span>
          <span className="date"> {`${value.start_date} -  ${value.end_date}`}</span>
        </Tag>
      )
    },
    [format],
  )

  const renderCalendaDate = useCallback(
    (current) => {
      if (
        arrValues.some((e) => current.isSame(e.start_date)) ||
        arrValues.some((e) => current.isSame(e.end_date))
      ) {
        return <div className={'selectedDate'}>{current.format('DD')}</div>
      }
      return <div>{current.format('DD')}</div>
    },
    [arrValues],
  )

  const onSelect = (selected) => {
    const index = arrValues.findIndex((e) => selected[0].isSame(e.start_date))
    const tempArr = [...arrValues]

    if (index !== -1) {
      tempArr.splice(index, 1)
    } else {
      tempArr.push({
        name: typingText,
        start_date: moment(selected[0]).format(calendarFormat),
        end_date: moment(selected[1]).format(calendarFormat),
      })
    }

    onChange(tempArr)
    clearForm()
  }

  const onRemove = useCallback(
    (oldSelect) => {
      const filteredArr = arrValues.filter((e) => !moment(oldSelect.start_date).isSame(e.start_date))
      onChange(filteredArr)
    },
    [arrValues, onChange],
  )

  const onType = (e) => {
    setTypingText(e.target.value)
  }

  const clearForm = () => {
    setTypingText('')
    setDateRange([])
  }

  return (
    <>
      <div style={{ marginBottom: 5 }}>
        {arrValues.map((holiday, index) => (
          <Holiday value={holiday} onClose={onRemove} key={index} />
        ))}
      </div>
      <Input.Group compact style={{ display: 'flex' }}>
        <Input style={{ width: '30%' }} defaultValue="Chọn ngày nghỉ" value={typingText} onChange={onType} />
        <DatePicker.RangePicker
          format={calendarFormat}
          onChange={onSelect}
          dateRender={renderCalendaDate}
          value={dateRange !== '' ? dateRange : ''}
          style={{ width: '70%' }}
        />
      </Input.Group>
    </>
  )
})

export default MultiDatePicker
