import React, { useState, useEffect } from 'react'
import { Drawer, Typography, Col, Row, Button, Tooltip, Space, notification } from 'antd'
import QRCode from 'qrcode.react'
import { formatFileSize } from 'utils'
import { Link } from 'react-router-dom'

import {
  FolderFilled,
  FileFilled,
  EditOutlined,
  DownloadOutlined,
  FileSearchOutlined,
  MoreOutlined,
} from 'assets/icon'

import FileSocialShare from './FileSocialShare'

function FileDescription({ visible, onCancel, selectedFileData }) {
  const {
    extension,
    size,
    description,
    name,
    updated_at,
    created_at,
    full_url,
    url,
    owner,
    work_package,
  } = selectedFileData

  const DescriptionItem = ({ title, content }) => (
    <Row className="file-description__item" align="middle">
      <Col span={8}>
        <span>{title}</span>
      </Col>
      <Col span={16}>
        <span>{content}</span>
      </Col>
    </Row>
  )

  const FileAction = () => {
    return (
      <Button
        onClick={() => {
          window.open(full_url)
        }}
        size="large"
        icon={<DownloadOutlined />}
      >
        Tải xuống
      </Button>
    )
  }

  return (
    <Drawer
      className="file-detail"
      width={450}
      placement="right"
      closable={false}
      onClose={onCancel}
      visible={visible}
    >
      <Row align="middle" justify="center" className="file-qrcode">
        <Col style={{ textAlign: 'center' }} span={24}>
          <QRCode value={full_url} />
        </Col>
        <Col style={{ textAlign: 'center' }} span={24}>
          <Typography.Title level={4}>{name}</Typography.Title>
        </Col>
      </Row>

      <Row className="file-description">
        <Col span={24}>
          <DescriptionItem title="Dung lượng" content={formatFileSize(size)} />
          <DescriptionItem title="Loại" content={extension} />
          <DescriptionItem title="Owner" content={owner?.full_name} />
          <DescriptionItem title="Miêu tả" content={description} />
          <DescriptionItem title="Upload" content={created_at} />
          <DescriptionItem title="Cập nhật" content={updated_at} />
          {work_package?.id && (
            <DescriptionItem
              title="Công việc"
              content={
                <Link
                  to={`projects/${work_package?.project_id}/gantt?task_id=${work_package?.id}`}
                  target="_blank"
                >
                  {work_package.subject}
                </Link>
              }
            />
          )}
        </Col>
      </Row>

      <Row className="file-preview" align="middle" justify="center">
        <Col span={18}>
          <div className="file-preview__link">{url}</div>
        </Col>
        <Col span={6}>
          <Button
            type="text"
            onClick={() => {
              navigator.clipboard.writeText(full_url)
              notification.success({
                message: 'Đã sao chép',
              })
            }}
          >
            Sao chép
          </Button>
        </Col>
      </Row>
      <Row className="file-social" align="middle" justify="center">
        <Col span={24}>
          <FileSocialShare url={full_url} name={name} />
        </Col>
      </Row>
      <Row className="file-action" align="middle" justify="left" align="middle">
        <Col span={24}>
          <FileAction />
        </Col>
      </Row>
    </Drawer>
  )
}

export default FileDescription
