import axios from 'axios'
// import _ from 'lodash'
import { getCookie } from 'store/actions/auth.action'

let source
const BASE_URL = process.env.REACT_APP_BASE_API
const headersDefault = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

const token = getCookie('token')

const api = axios.create({
  baseURL: BASE_URL,
  headers: token
    ? { ...headersDefault, Authorization: `Bearer ${getCookie('token')}` }
    : { ...headersDefault },
  timeout: 120000,
})

function getToken() {
  return api.defaults.headers.get.Authorization
}

function setToken(value) {
  api.defaults.headers.common.Authorization = `Bearer ${value}`
}

function unsetToken() {
  delete api.defaults.headers.Authorization
}

function cancel(message = 'Request canceled') {
  if (source) source.cancel(message)
}

function handleError(errResponse, reject, isFormData) {
  // const status = _.get(errResponse, 'status')
  // const errorMessage = _.get(errResponse, 'data.message')
  // const errorList = _.get(errResponse, 'data.errors') || {}
  if (errResponse === undefined || errResponse === 'undefined') {
    // AlertCantClose('Server error', 'Please try later', navigateToLoginPage)
    reject()
    // } else if (status === 401 || status === 403) {
    //   // Alert(errorMessage, 'Email or password is incorrect', navigateToLoginPage)
    //   reject()
  } else {
    // const arrErr = Object.keys(errorList)
    // let listDesErr = ''
    // _.map(arrErr, (err) => {
    //   const errorDes = errorList[err]
    //   listDesErr += Array.isArray(errorDes)
    //     ? errorDes.length > 1
    //       ? errorDes.join(',')
    //       : errorDes[0]
    //     : errorList[err]
    // })
    // Alert(errorMessage, `${listDesErr} ${isFormData ? 'You can upload later' : ''}`)
    reject()
  }
}

function customFetch(url, method, body = {}, customOptions = {}) {
  source = axios.CancelToken.source()
  return new Promise((resolve, reject) => {
    let optionDetect = {}
    if (method === 'GET' || method === 'PATCH') {
      optionDetect = {
        params: {
          ...body,
        },
      }
    }
    if (method === 'POST' || method === 'PUT' || method === 'DELETE') {
      optionDetect = {
        data: {
          ...body,
        },
      }
    }
    const options = {
      method,
      url,
      ...optionDetect,
      cancelToken: source.token,
      ...customOptions,
    }

    api
      .request(options)
      .then((response) => {
        const result = response.data
        const { status } = response
        if (status === 200 || status === 201) {
          resolve(result)
        } else {
          reject(new Error(result))
        }
      })
      .catch((error) => {
        let { data } = error?.response || {}
        if (!data) {
          data = {}
        }
        data.message = error.message
        if (error.response?.request?.response) {
          const messageError = JSON.parse(error.response.request.response)?.message
          if (messageError) {
            const msg = typeof messageError === 'object' ? Object.values(messageError) : [messageError]
            if (msg && msg.length) {
              data.message = msg.join('\n')
            }
          }
        }
        reject(data)
        // handleError(error.response, reject)
      })
  })
}

function postFormData(url, body, config = {}) {
  return new Promise((resolve, reject) => {
    const options = {
      method: 'POST',
      url: BASE_URL + url,
      data: body,
      headers: {
        Accept: '*/*',
      },
      timeout: 1800000,
      ...config,
    }

    api
      .request(options)
      .then((result) => {
        resolve(result.data)
      })
      .catch((error) => {
        console.log('error', error)
        handleError(error.response, reject, true)
      })
  })
}

function post(url, body, options) {
  return customFetch(url, 'POST', body, options)
}

function get(url, body, options) {
  return customFetch(url, 'GET', body, options)
}

function put(url, body, options) {
  return customFetch(url, 'PUT', body, options)
}

function patch(url, body, options) {
  return customFetch(url, 'PATCH', body, options)
}

function _delete(url, body, options) {
  return customFetch(url, 'DELETE', body, options)
}

export default {
  getToken,
  setToken,
  unsetToken,
  post,
  get,
  put,
  cancel,
  _delete,
  patch,
  postFormData,
}
