import * as actionTypes from 'store/types/department.action.types'

const initialState = {
  departments: [],
  total: 0,
}

const reducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case actionTypes.FETCH_DEPARTMENT:
      return { ...state, departments: payload.data, total: payload.total }

    default:
      return state
  }
}

export default reducer
