import React from 'react'
import { Table } from 'antd'

import { TableAction } from 'components/Common'
import permission from 'utils/permission'

import './Role.scss'

const RoleList = (props) => {
  const columns = [
    {
      title: 'Vai trò',
      dataIndex: 'name',
    },
    ...(permission(`${props.roleName}:edit,${props.roleName}:delete`)
      ? [
          {
            title: '',
            align: 'center',
            width: '20%',
            render: (item) => (
              <TableAction
                roleName={props.roleName}
                item={item}
                onEdit={props.onEdit}
                onDelete={props.onDelete}
              />
            ),
          },
        ]
      : []),
  ]

  const { data } = props

  return (
    <>
      <Table
        pagination={false}
        columns={columns}
        dataSource={data}
        rowKey="id"
        bordered
        // scroll={{ x: '300px', y: 414 }}
      />
    </>
  )
}

export default RoleList
