import React from 'react'
import moment from 'moment'
import { Table, Tooltip, Typography } from 'antd'
import { LineOutlined } from 'assets/icon'
import { useLayoutHelper } from 'hooks'
import { Link } from 'react-router-dom'

const { Text } = Typography

function OverviewList(props) {
  const { data, isLoading, status, height = 180 } = props
  const { isToggleMenu: isMobile } = useLayoutHelper()

  const RenderTooltip = ({ taskParent, taskMain = null, taskCurrent }) => {
    return taskMain ? (
      <>
        <p className="task-main">
          <b>- Công việc</b> : {taskMain.subject}
        </p>
        <p className="task-parent">
          <b>- Công việc</b> : {taskParent.subject}
        </p>
        <p className="task-name">
          <b>- Công việc</b> : {taskCurrent}
        </p>
      </>
    ) : (
      <>
        <p className="task-parent">
          <b>- Công việc</b> : {taskParent.subject}
        </p>
        <p className="task-name">
          <b>+ Công việc</b> : {taskCurrent}
        </p>
      </>
    )
  }

  const columns = [
    ...(status != 'upcoming'
      ? [
          {
            title: 'Trễ/ Sớm',
            width: 100,
            align: 'center',
            render: (_, record) => {
              if (record.pastDue < 0) {
                return <Text type="danger">{`Trễ ${-record.pastDue} ngày`}</Text>
              } else if (record.pastDue > 0) {
                return <Text type="success">{`Sớm ${record.pastDue} ngày`}</Text>
              }
              return <Text>Đúng tiến độ</Text>
            },
          },
        ]
      : []),
      {
        title: 'Tiến độ',
        width: 60,
        align: 'center',
        render: (_, record) => {
          return <Text>{Math.floor(record.progress * 100)}%</Text>
        },
      },
    {
      title: 'Công việc',
      dataIndex: 'subject',
      // width: 100,
      render: (subject, row) => {
        return row.parent_work_package ? (
          <Tooltip
            placement="topLeft"
            title={
              <RenderTooltip
                taskParent={row.parent_work_package}
                taskMain={row.parent_work_package?.parent_work_package}
                taskCurrent={subject || <LineOutlined />}
              />
            }
          >
            <div className="--truncated" style={{ color: '#1890ff' }}>
              <Link style={{ color: '#409eff' }} to={`gantt?task_id=${row.id}`} target={'_blank'}>
                {subject || <LineOutlined />}
              </Link>
            </div>
          </Tooltip>
        ) : (
          <Link style={{ color: '#409eff' }} to={`gantt?task_id=${row.id}`} target={'_blank'}>
            subject || <LineOutlined />
          </Link>
        )
      },
    },
    ...(status !== 'department'
      ? [
          {
            title: 'Phòng ban',
            dataIndex: 'calendar_duration',
            align: 'center',
            width: 100,
            render: (assigned, row) => {
              return row.assigned ? row.assigned.department.name : <LineOutlined />
            },
          },
        ]
      : []),
    {
      title: 'Bắt đầu dự kiến',
      dataIndex: 'target_start_date',
      align: 'center',
      width: 100,
      render: (text) => moment(new Date(text)).format('DD/MM/YY'),
    },
    {
      title: 'Kết thúc dự kiến',
      dataIndex: 'target_end_date',
      align: 'center',
      width: 100,
      render: (text) => moment(new Date(text)?.valueOf() - 1).format('DD/MM/YY'),
    },
    {
      title: 'Thời gian dự kiến',
      dataIndex: 'calendar_target_duration',
      align: 'center',
      width: 100,
      render: (text) => (text ? `${text} ngày` : ''),
    },
    ...(status == 'current'
      ? [
          {
            title: 'Hoàn thành',
            width: 100,
            dataIndex: 'progress',
            align: 'center',
            // ...(isMobile && { fixed: 'right' }),
            render: (progress) => `${Math.round(progress * 100)}%`,
          },
        ]
      : []),
  ]

  return (
    <>
      {/* <pre>
        <code>{JSON.stringify(columns, null, 4)}</code>{' '}
      </pre> */}

      <Table
        // onRow={(record, rowIndex) => {
        //   return {
        //     onDoubleClick: (event) => {
        //       //history.push(`/projects/${projectID}/gantt?task_id=${record.id}`)
        //       window.open(`/projects/${projectID}/gantt?task_id=${record.id}`, '_blank').focus()
        //     },
        //   }
        // }}
        loading={isLoading}
        tableLayout={isMobile ? 'auto' : 'fixed'}
        pagination={false}
        columns={columns}
        dataSource={data}
        rowKey="id"
        rowClassName={(record) => record.is_critical ? 'danger' : '' }
        locale={{ emptyText: 'Hiện chưa có dữ liệu' }}
        bordered
        // scroll={isMobile ? { x: 'max-content', y: 176 } : { y: 188 }}
        scroll={{ x: 'max-content', y: height }}
      />
    </>
  )
}

export default OverviewList
