export const paginationData = {
  page: 1,
  limit: 10,
}

export function convertToFormData(data, method) {
  const formData = new FormData()
  for (const key in data) {
    if (data[key] === undefined) {
      continue
    }

    if (data[key] === null) {
      data[key] = ''
    }
    if (Array.isArray(data[key])) {
      data[key].forEach((value) => formData.append(`${key}[]`, value))
    } else {
      formData.append(key, data[key])
    }
  }
  if (method) {
    formData.append('_method', method)
  }
  return formData
}

/**
 * Where LIKE
 * /api/users?name=*se
 * Users::where('name', 'like', '%se')
 *
 * @param {string} name column name
 * @param {string} value
 */
export const likeFilter = (name, value) => {
  if (value) {
    return { [name]: `*${value}*` }
  }
}

/**
 * Where
 * /api/users?name=Selahattin
 * Users::where('name', '=', 'Selahattin')
 *
 * @param {string} name column name
 * @param {string/number...} value
 */
export const whereFilter = (name, value) => {
  if (value) {
    return { [name]: value }
  }
}

/**
 * Where NULL
 * /api/users?updated_at=[null]
 * Users::whereNull('updated_at')
 *
 * @param {string} name column name
 */
export const whereNull = (name) => {
  if (name) {
    return { [name]: JSON.stringify([null]) }
  }
}

/**
 * Where NOT NULL
 * /api/users?updated_at!=[null]
 * Users::whereNotNull('updated_at')
 *
 * @param {string} name column name
 */
export const whereNotNull = (name, value) => {
  if (value) {
    return { [`${name}!`]: JSON.stringify([null]) }
  }
}

/**
 * Where greater than a specific value
 * /api/users?ag[gt]=18
 * Users::where('age', '>', 18)
 *
 * @param {string} name
 * @param {string/number...} value
 */
export const gtFilter = (name, value) => {
  if (value) {
    return { [`${name}[gt]`]: value }
  }
}

/**
 * Where greater or equal than a specific value
 * /api/users?age[gte]=18
 * Users::where('age', '>=', 18)
 *
 * @param {string} name
 * @param {string/number...} value
 */
export const gteFilter = (name, value) => {
  if (value) {
    return { [`${name}[gte]`]: value }
  }
}

/**
 * Where less than a specific value
 * /api/users?age[lt]=18
 * Users::where('age', '<', 18)
 *
 * @param {string} name
 * @param {string/number...} value
 */
export const ltFilter = (name, value) => {
  if (value) {
    return { [`${name}[lt]`]: value }
  }
}

/**
 * Where less or equal than a specific value
 * /api/users?age[lte]=18
 * Users::where('age', '<=', 18)
 *
 * @param {string} name
 * @param {string/number...} value
 */
export const lteFilter = (name, value) => {
  if (value) {
    return { [`${name}[lte]`]: value }
  }
}

/**
 * Where Between
 * /api/users?age[]=[10,20]
 * Users::whereIn('age', [10, 20])
 *
 * @param {string} name
 * @param {array} array
 */
export const whereInFilter = (name, array) => {
  if (array && array.length) {
    return { [`${name}[]`]: JSON.stringify(array) }
  }
}

/**
 * Where Not Between
 * /api/users?age[notin]=[10,20]
 * Users::whereNotIn('age', [10, 20])
 *
 * @param {string} name
 * @param {array} array
 */
export const whereNotInFilter = (name, array) => {
  if (array && array.length) {
    return { [`${name}[notin]`]: JSON.stringify(array) }
  }
}

/**
 * Order by
 * /api/users?order_by=name,asc
 * Users::orderBy('name', 'asc')
 *
 * @param {object} item
 */
export const orderBy = (item) => {
  if (item) {
    return { order_by: `${item.name},${item.value}` }
  }
}

/**
 * Lowercase letters before filtering
 *
 * @param {string} input
 * @param {string} option
 */
export function filterString(input, option) {
  return option ? getSearchString(option).includes(getSearchString(input)) : false
}

const getSearchString = (str) => {
  return str
    ? str
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
    : str
}

/**
 * Capitalize first letter of every word
 *
 * @param {string} input
 */
export const toCapitalizedCase = function (string) {
  return string
    ? string
        .split(' ')
        .map((element) => element.charAt(0).toUpperCase() + element.slice(1).toLowerCase())
        .join(' ')
    : ''
}

/* js-file-download */
export function fileDownload(data, filename, mime, bom) {
  var blobData = typeof bom !== 'undefined' ? [bom, data] : [data]
  var blob = new Blob(blobData, { type: mime || 'application/octet-stream' })
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, filename)
  } else {
    var blobURL =
      window.URL && window.URL.createObjectURL
        ? window.URL.createObjectURL(blob)
        : window.webkitURL.createObjectURL(blob)
    var tempLink = document.createElement('a')
    tempLink.style.display = 'none'
    tempLink.href = blobURL
    tempLink.setAttribute('download', filename)

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank')
    }

    document.body.appendChild(tempLink)
    tempLink.click()

    // Fixes "webkit blob resource error 1"
    setTimeout(function () {
      document.body.removeChild(tempLink)
      window.URL.revokeObjectURL(blobURL)
    }, 200)
  }
}
