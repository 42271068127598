import React from 'react'
import PropTypes from 'prop-types'
import { Drawer, Grid } from 'antd'
import { toggleMenu } from 'store/actions/common.action'
import { connect } from 'react-redux'
import { useLayoutHelper } from 'hooks'

function SideDrawer({ component: Component, isMenuOpen, toggleMenuAction }) {
  const { isToggleMenu } = useLayoutHelper()
  const onToggleMobileMenu = () => {
    toggleMenuAction(false)
  }

  return (
    <>
      {!isToggleMenu ? (
        <Component toggleMenuAction={toggleMenuAction} />
      ) : (
        <>
          <Drawer
            width="220"
            placement="left"
            closable
            onClose={onToggleMobileMenu}
            visible={isMenuOpen}
            className="menu-drawer"
          >
            <Component isMobile={isToggleMenu} toggleMenuAction={toggleMenuAction} />
          </Drawer>
        </>
      )}
    </>
  )
}

export default connect(
  ({ common }) => ({
    isMenuOpen: common.isMenuOpen,
  }),
  {
    toggleMenuAction: toggleMenu,
  },
)(SideDrawer)

SideDrawer.propTypes = {
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]).isRequired,
}
