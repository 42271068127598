import React, { useEffect, lazy, Suspense, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Switch, Route, useLocation, useHistory, Redirect, matchPath } from 'react-router-dom'
import { Modal } from 'antd'
import {
  UserOutlined,
  SettingOutlined,
  InfoCircleOutlined,
  HddOutlined,
  LayoutOutlined,
  CalendarOutlined,
} from 'assets/icon'
import { PageLoader, BasePage, ProjectPage, AdminPage, BlankPage } from 'components'
import { getCookie, getProfile, getProjectRole, logout, setRouteProjectId } from 'store/actions/auth.action'
import { routes } from './routes'

/* Used to render a lazy component with react-router */
const waitFor = (Tag) => (props) => <Tag {...props} />

// no redirect whitelist
const whiteList = ['/login', '/auth-redirect', '/forgot-password', '/reset-password']

const Login = lazy(() => import('pages/Login'))
const ResetPasword = lazy(() => import('pages/Login/ResetPassword'))
const ForgotPassword = lazy(() => import('pages/Login/ForgotPassword'))
const Profile = lazy(() => import('pages/Profile/Profile'))
const Overview = lazy(() => import('pages/Overview/Overview'))
const Projects = lazy(() => import('pages/Projects/Projects'))
const AssignedToMe = lazy(() => import('pages/AssignedToMe'))
const ProjectDetails = lazy(() => import('pages/ProjectDetails'))
// const ProjectSettings = lazy(() => import('pages/ProjectSettings'))
const FileStorage = lazy(() => import('pages/FileStorage/FileStorage'))
const ProjectFileStorage = lazy(() => import('pages/FileStorage/ProjectFileStorage'))
const FileLog = lazy(() => import('pages/FileStorage/FileLog'))
const ProjectUsers = lazy(() => import('pages/ProjectUsers/ProjectUsers'))
const ProjectCalendar = lazy(() => import('pages/Calendar/ProjectCalendar'))
const Users = lazy(() => import('pages/Admin/Users/Users'))
const Departments = lazy(() => import('pages/Admin/Departments/Departments'))
const Roles = lazy(() => import('pages/Admin/Roles/Roles'))
const Calendar = lazy(() => import('pages/Admin/Calendar/Calendar'))

/**
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * name:'router-name'
 * translate: 'title'             the name show in sidebar and breadcrumb (recommend set)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    icon: 'svg-name'             the icon show in the sidebar
  }
*/

export const routePage = {
  base: 'base',
  project: 'project',
  admin: 'admin',
}

export const routeList = [
  {
    name: 'Home',
    path: '/projects',
    component: Projects,
    translate: 'sidebar.nav.overview',
    routePage: routePage.base,
    meta: {
      // roles: ['project'],
    },
  },
  {
    name: 'Overview',
    path: '/projects/:id/overview',
    nestedRoute: '/overview',
    component: Overview,
    translate: 'sidebar_overview',
    routePage: routePage.project,
    meta: {
      icon: <InfoCircleOutlined />,
      roles: ['project_overview'],
    },
  },
  {
    name: 'Work Package',
    translate: 'sidebar_workpackage',
    routePage: routePage.project,
    path: '/work_package',
    nestedRoute: '/work_package',
    meta: {
      icon: <HddOutlined />,
      roles: ['work_package', 'my_work_package'],
    },
    submenu: [
      {
        name: 'Project Plan',
        path: '/projects/:id/gantt',
        nestedRoute: '/gantt',
        component: ProjectDetails,
        translate: 'sidebar_projectPlan',
        routePage: routePage.project,
        meta: {
          roles: ['work_package'],
        },
      },
      {
        name: 'My tasks',
        path: '/projects/:id/my-tasks',
        nestedRoute: '/my-tasks',
        component: AssignedToMe,
        translate: 'sidebar_assignToMe',
        routePage: routePage.project,
        meta: {
          roles: ['my_work_package'],
        },
      },
      // {
      //   name: 'Tasks',
      //   path: '/projects/:id/tasks',
      //   nestedRoute: '/tasks',
      //   component: null,
      //   translate: 'sidebar_task',
      //   routePage: routePage.project,
      //   meta: {
      //     roles: ['work_package'],
      //   },
      // },
    ],
  },
  {
    name: 'File Storage',
    path: '/projects/:id/file-storage',
    nestedRoute: '/file-storage',
    translate: 'sidebar_fileStorage',
    routePage: routePage.project,
    meta: {
      icon: <LayoutOutlined />,
      roles: ['project_file_storage'],
    },
    submenu: [
      {
        name: 'File Storage',
        path: '/projects/:id/file-storage',
        nestedRoute: '/file-storage',
        component: ProjectFileStorage,
        translate: 'sidebar_fileStorage',
        routePage: routePage.project,
        meta: {
          roles: ['project_file_storage'],
        },
      },
      {
        name: 'Logs',
        path: '/projects/:id/file-logs',
        nestedRoute: '/file-logs',
        component: FileLog,
        translate: 'sidebar_fileLog',
        routePage: routePage.project,
        meta: {
          roles: ['project_file_storage'],
        },
      },
    ],
  },
  {
    name: 'Members',
    path: '/projects/:id/members',
    nestedRoute: '/members',
    component: ProjectUsers,
    translate: 'sidebar_members',
    routePage: routePage.project,
    meta: {
      icon: <UserOutlined />,
      roles: ['project_user'],
    },
  },
  {
    name: 'Calendars',
    path: '/projects/:id/calendars',
    nestedRoute: '/calendars',
    component: ProjectCalendar,
    translate: 'siderbar_projectCalendar',
    routePage: routePage.project,
    meta: {
      icon: <CalendarOutlined />,
      roles: ['department_calendar'],
    },
  },
  // {
  //   name: 'Project Settings',
  //   path: '/projects/:id/settings',
  //   nestedRoute: '/settings',
  //   component: null,
  //   translate: 'siderbar_projectSetting',
  //   routePage: routePage.project,
  //   meta: {
  //     icon: <SettingOutlined />,
  //     roles: ['project_setting'],
  //   },
  // },
  {
    name: 'Admin',
    translate: 'sidebar_users_permissions',
    path: '/admin',
    routePage: routePage.admin,
    meta: {
      icon: <UserOutlined />,
      roles: ['user', 'department', 'role'],
    },
    submenu: [
      {
        name: 'Users',
        path: '/admin/users',
        component: Users,
        translate: 'sidebar_users',
        routePage: routePage.admin,
        meta: {
          roles: ['user'],
        },
      },
      {
        name: 'Departements',
        path: '/admin/departments',
        component: Departments,
        translate: 'sidebar_department',
        routePage: routePage.admin,
        meta: {
          roles: ['department'],
        },
      },
      {
        name: 'Roles',
        path: '/admin/roles',
        component: Roles,
        translate: 'sidebar_roles_permissions',
        routePage: routePage.admin,
        meta: {
          roles: ['role'],
        },
      },
    ],
  },
  {
    name: 'System Calendar',
    path: '/calendars',
    component: Calendar,
    translate: 'sidebar_system_calendar',
    routePage: routePage.base,
    meta: {
      icon: <SettingOutlined />,
    },
  },
  {
    name: 'Profile',
    path: '/profile',
    component: Profile,
    routePage: routePage.base,
    hidden: true,
  },
  {
    name: 'Lưu trữ',
    path: '/file-storage',
    component: FileStorage,
    routePage: routePage.base,
    hidden: true,
  },
]

function AppRoutes() {
  let token = getCookie('token')
  const [redirectToLogin, setRedirectToLogin] = useState(false)
  // const [isAuthenticatedProject, setIsAuthenticatedProject] = useState(0)
  const { token: apiToken, isAuthenticated, accessedRoutes, routeProjectId } = useSelector(
    (state) => state.authState,
  )

  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()

  const projectMatch = matchPath(location.pathname, {
    path: '/projects/:id',
    strict: false,
  })

  const getAuthenticatedUser = () => dispatch(getProfile())
  const getAuthenticatedProjectRole = (projectId) => dispatch(getProjectRole(projectId))
  const setIsAuthenticatedProject = (projectId) => dispatch(setRouteProjectId(projectId))
  const signOut = () => dispatch(logout())

  useEffect(() => {
    token = getCookie('token')
    if (!token && !isAuthenticated && !apiToken) {
      setRedirectToLogin(true)
      if (![...whiteList].concat('/projects').includes(location.pathname)) {
        history.push({
          search: `?redirect=${location.pathname}`,
        })
      }
    } else {
      requireAuth()
    }
  }, [apiToken])

  useEffect(() => {
    /* if the user access to the last visited project, don't need to check role */
    if (projectMatch?.params?.id && isAuthenticated && routeProjectId !== projectMatch?.params?.id) {
      projectRole()
    }
  }, [projectMatch?.params?.id, isAuthenticated])

  const requireAuth = async () => {
    // NProgress.start()
    try {
      await getAuthenticatedUser()

      if (whiteList.indexOf(location.pathname) !== -1) {
        history.push('/')
      }
    } catch (error) {
      signOut()
      // this.setState({ redirectLogin: true })
    }
    // NProgress.done()
  }

  const projectRole = async () => {
    // NProgress.start()
    try {
      await getAuthenticatedProjectRole(projectMatch?.params?.id)

      setIsAuthenticatedProject(projectMatch?.params?.id)
    } catch (error) {
      Modal.error({
        title: 'Unauthorized',
        content: error.message,
      })
      history.push('/')
    }
    // NProgress.done()
  }

  const renderRedirect = () => {
    if (redirectToLogin && whiteList.indexOf(location.pathname) === -1) {
      return <Redirect to="/login" />
    }
  }

  const generateRoutes = (routes, routePage) => {
    let accessedRoutes = []
    routes.forEach((route) => {
      if (route.component && route.routePage === routePage) {
        accessedRoutes.push(
          <Route
            key={route.path}
            exact={route.exact}
            path={route.path}
            component={waitFor(route.component)}
          />,
        )
      }
      if (route.submenu) {
        accessedRoutes = [...accessedRoutes, ...generateRoutes(route.submenu, routePage)]
      }
    })

    return accessedRoutes
  }

  const getRedirectPage = (routes, page) => {
    if (!routes) return
    let redirectPage
    for (let i = 0; i < routes.length; i++) {
      const route = routes[i]
      if (route.path && !route.hidden && !route.submenu) {
        if (!page) {
          redirectPage = route.path
          break
        } else if (route.routePage === page) {
          redirectPage = route.path.replace(':id', projectMatch?.params?.id)
          break
        }
      }
      if (route.submenu) {
        redirectPage = getRedirectPage(route.submenu, page)
        if (redirectPage) {
          break
        }
      }
    }

    return redirectPage
  }

  const redirectPage = getRedirectPage(accessedRoutes)
  const redirectProjectPage = getRedirectPage(accessedRoutes, routePage.project)
  // console.log(redirectProjectPage)

  const baseRoutes = (
    <BlankPage>
      <Suspense fallback={<PageLoader />}>
        <Switch>
          <Route exact path={routes.login} component={Login} />
          <Route exact path={routes.forgotPassword} component={ForgotPassword} />
          <Route exact path={routes.resetPassword} component={ResetPasword} />
          <Redirect to="/login"></Redirect>
        </Switch>
      </Suspense>
    </BlankPage>
  )

  if (!token && !isAuthenticated) {
    return baseRoutes
  }

  const adminMatch = matchPath(location.pathname, {
    path: '/admin',
    strict: false,
  })

  return (
    <Route
      path="/"
      render={() => {
        renderRedirect()
        if (!isAuthenticated) {
          return null
        }

        if (projectMatch) {
          if (routeProjectId !== projectMatch?.params?.id) return null
          return (
            <ProjectPage>
              <Suspense fallback={<PageLoader />}>
                <Switch>
                  {generateRoutes(accessedRoutes, routePage.project)}
                  <Redirect to={redirectProjectPage} />
                </Switch>
              </Suspense>
            </ProjectPage>
          )
        }
        if (adminMatch) {
          return (
            <AdminPage>
              <Suspense fallback={<PageLoader />}>
                <Switch>{generateRoutes(accessedRoutes, routePage.admin)}</Switch>
              </Suspense>
            </AdminPage>
          )
        }

        return (
          <BasePage>
            <Suspense fallback={<PageLoader />}>
              <Switch>
                {generateRoutes(accessedRoutes, routePage.base)}
                <Redirect to={redirectPage} />
              </Switch>
            </Suspense>
          </BasePage>
        )
      }}
    />
  )
}

export { routes, AppRoutes }
