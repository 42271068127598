import React, { useEffect, useState } from 'react'
import { departmentBackgroundColors } from 'enums'
import { Pie } from 'react-chartjs-2'
import 'chartjs-plugin-datalabels'

const ProjectProgressChart = ({ data, options }) => {
  const [chartData, setChartData] = useState({})

  useEffect(() => {
    const datasets = getDatasets(data)
    setChartData(datasets)
  }, [data])

  function getDatasets(departments) {
    const labels = []
    const backgroundColors = []
    const borderColors = []
    let progressDone = 0
    let progressPercentDone = 0
    const projectWorkload = departments[0]?.projectWorkload

    const data = departments?.map((department) => {
      labels.push(department.name)
      const bgColor = departmentBackgroundColors[department.id] || '#ffffff'
      backgroundColors.push(bgColor)
      borderColors.push(bgColor)

      progressDone += department.departmentWorkload?.duration
      const value = Math.floor((department.departmentWorkload?.duration * 100) / projectWorkload)
      progressPercentDone += value
      return value
      //return (department.departmentWorkload?.duration * 100) / projectWorkload
    })

    if (progressDone !== projectWorkload) {
      labels.push('Còn lại')
      data.push(100 - progressPercentDone)
      backgroundColors.push('#F5F5F5')
      borderColors.push('#F5F5F5')
    }

    return {
      labels,
      datasets: [
        {
          data,
          backgroundColor: backgroundColors,
        },
      ],
    }
  }

  return (
    <Pie
      data={chartData}
      height={90}
      options={{
        responsive: true,
        plugins: {
          datalabels: {
            color: 'black',
            labels: {
              title: {
                font: {
                  weight: 'bold',
                },
              },
            },
            formatter: function (value, context) {
              return `${value}%`
            },
          },
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              var index = tooltipItem.index
              var currentValue = data.datasets[tooltipItem.datasetIndex].data[index]
              return `${data.labels[index]}: ${currentValue}%`
            },
          },
        },
      }}
    />
  )
}

export default ProjectProgressChart
