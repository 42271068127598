import * as actionTypes from 'store/types/role.action.types'

const initialState = {
  roles: [],
  total: 0,
  permissionTree: [],
}

const reducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case actionTypes.FETCH_ROLE:
      return { ...state, roles: payload.data, total: payload.total }
    case actionTypes.FETCH_PERMISSION_TREE:
      return { ...state, permissionTree: payload }
    default:
      return state
  }
}

export default reducer
