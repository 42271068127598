import React, { useEffect } from 'react'
import { MiniProgress } from 'components'
import moment from 'moment'

const ProjectDurationChart = ({
  data,
  showRemaining = false,
  remain,
  strokeWidth = 10,
  departmentView = null,
  projectDurationOffset,
  setProjectDurationOffset,
}) => {
  useEffect(() => {
    /**
     * Set offset
     */
    const offsetMargin =
      Math.abs(leftMartgin) >= Math.abs(rightMartgin) ? Math.abs(leftMartgin) : Math.abs(rightMartgin)
    if (offsetMargin > projectDurationOffset) {
      setProjectDurationOffset(offsetMargin)
    }
  })

  let duration = moment(data.end_date).diff(moment(data.start_date), 'days') + 1

  const realDurationPercent = Math.floor(
    ((moment().diff(moment(data.start_date), 'days') + 1) * 100) / duration,
  )

  let leftMartgin,
    rightMartgin,
    totalDuration = 0

  if (departmentView) {
    totalDuration =
      moment(departmentView.total.end_date).diff(moment(departmentView.total.start_date), 'days') + 1

    /*
     * Left margin
     */
    const leftDuration = moment(data.start_date).diff(moment(departmentView.total.start_date), 'days') + 1
    leftMartgin = Math.floor(leftDuration === 1 ? 0 : (leftDuration * 100) / totalDuration)

    /*
     * Right margin
     */
    const rightDuration = moment(departmentView.total.end_date).diff(moment(data.end_date), 'days') + 1
    rightMartgin = Math.floor(rightDuration === 1 ? 0 : (rightDuration * 100) / totalDuration)
  }

  /*
   * current date is before start_date, duration percent = 0
   * current date is after end_date, duration percent = 100
   * otherwise, duration percent = duration / total duration
   */
  const durationPercent = Math.floor(
    moment().isAfter(moment(data.end_date))
      ? 100
      : moment().isBefore(moment(data.start_date))
      ? 0
      : ((moment().diff(moment(data.start_date), 'days') + 1) * 100) / duration,
  )

  const remainText = durationPercent > 80 ? remain : `còn ${remain > 0 ? remain : 0} ngày`
  const renderTooltip = () => {
    return (
      <>
        <p>Bắt đầu: {moment(data.start_date).format('DD-MM-YYYY')}</p>
        <p>Kết thúc: {moment(data.end_date).format('DD-MM-YYYY')}</p>
        <p>Hôm nay: {moment().format('DD-MM-YYYY')}</p>
      </>
    )
  }

  return (
    <div
      style={{
        marginLeft: leftMartgin < 0 ? `${leftMartgin}%` : `${leftMartgin}%`,
        marginRight: rightMartgin > 100 ? `${rightMartgin}%` : `${rightMartgin}%`,
      }}
    >
      <MiniProgress
        percent={durationPercent}
        strokeWidth={strokeWidth}
        showTarget
        color="#b7ea94"
        remaining={remainText}
        showRemaining={showRemaining}
        targets={[
          {
            id: 1,
            title: moment(data.start_date).format('DD-MM-YYYY'),
            position: 'bottom',
            value: 0,
            left: -15,
            popup: renderTooltip,
          },
          ...(realDurationPercent >= 0 && realDurationPercent <= 100
            ? [
                // { id: 2, title: 'Hôm nay', value: durationPercent, left: -16 },
                {
                  id: 4,
                  title: moment().format('DD-MM-YYYY'),
                  value: durationPercent,
                  left: -25,
                  position: 'top',
                  popup: renderTooltip,
                },
              ]
            : []),
          {
            id: 3,
            title: moment(data.end_date).format('DD-MM-YYYY'),
            position: 'bottom',
            value: 100,
            left: -40,
            popup: renderTooltip,
          },
        ]}
      />
    </div>
  )
}

export default ProjectDurationChart
