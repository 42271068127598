import React from 'react'
import { Layout } from 'antd'
import { NavBar, AdminSideBar, SideDrawer } from 'components'

const AdminPage = (props) => {
  return (
    <Layout className="layout-view">
      <SideDrawer component={AdminSideBar} />
      <Layout>
        <NavBar className="navbar-admin" />
        <Layout className="layout-content">{props.children}</Layout>
      </Layout>
    </Layout>
  )
}

export default AdminPage
