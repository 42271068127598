import store from 'store'

function getRoleFromState() {
  const xstore = store.getState()

  return {
    role_permissions: xstore.authState.permissions,
    role: xstore.authState.role,
  }
}

function getProjectRoleFromState(projectId) {
  const xstore = store.getState()

  let result
  if (xstore.authState.role.slug === process.env.REACT_APP_IS_ADMIN) {
    result = {
      role_permissions: xstore.authState.permissions,
      role: xstore.authState.role,
    }
  } else {
    const projectRole = xstore.authState.project_users.find((item) => item.project_id === projectId)
    result = {
      role: projectRole?.role,
      role_permissions: projectRole?.role_permissions,
    }
  }

  return result
}

export function permission(roleActions, storeData) {
  let checkRole = false
  const { role_permissions, role } = storeData

  if (role && role.slug === process.env.REACT_APP_IS_ADMIN) {
    return true
  }
  try {
    if (!Array.isArray(roleActions)) {
      roleActions = [roleActions]
    }
    for (const roleAction of roleActions) {
      const roleName = roleAction.split(':')[0]
      const actions = roleAction.split(':')[1].split(',')

      const selectedRole = role_permissions.find((item) => item.slug === roleName)

      if (selectedRole) {
        for (let action of actions) {
          action = action.trim()
          switch (action) {
            case 'view':
            case 'read':
              checkRole = selectedRole.permission & 1
              break
            case 'create':
            case 'add':
              checkRole = selectedRole.permission & 2
              break
            case 'update':
            case 'edit':
              checkRole = selectedRole.permission & 4
              break
            case 'delete':
            case 'remove':
              checkRole = selectedRole.permission & 8
              break
            case 'deadline':
              checkRole = selectedRole.permission & 16
              break
            default:
              checkRole = false
          }
          if (checkRole) {
            break
          }
        }
      }
      if (checkRole) {
        break
      }
    }
  } catch (error) {
    checkRole = false
  }

  return checkRole ? true : false
}

export function projectPermission(roleActions, projectId) {
  const storeData = getProjectRoleFromState(projectId)

  return permission(roleActions, storeData)
}

export function isAdmin() {
  const xstore = store.getState()

  return xstore.authState?.role?.slug  === process.env.REACT_APP_IS_ADMIN
}

export default function (roleActions) {
  const storeData = getRoleFromState()

  return permission(roleActions, storeData)
}
