import React, { PureComponent } from 'react'
import gantt from 'dhtmlx-gantt/codebase/dhtmlxgantt'
import 'gantt-export/api'
import moment from 'moment'
import uniqBy from 'lodash/uniqBy'
import { Button, Modal, Tooltip } from 'antd'
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  DatabaseFilled,
  SignalFilled,
  MergeCellsOutlined,
} from '@ant-design/icons'
import GanttToolbar from './GanttToolbar'
import {
  ganttConfig,
  fitTaskText,
  addMarker,
  zoomToFit,
  zoomLevel,
  configScaleTimeline,
  getGanttLayout,
  getStringWidth,
  getColumnPadding,
  getTaskWidth,
  addBaseLine,
  ganttCalendar,
  ganttExportHeader,
  ganttExportFooter,
} from './Gantt.config'
import { projectPermission } from 'utils/permission'

import 'dhtmlx-gantt/codebase/dhtmlxgantt.css'

let selectedDepartment = null
let selectedUser = null
let onlyCritical = null
let scrollVerCellOffset = 0
let isShowGrid = false
let isExpandTaskColumn = false
let prevWidth = 0
let closedTasks = []
let selectedColumns = [
  'status',
  'progress',
  'text',
  'target_start_date',
  'target_end_date',
  'calendar_target_duration',
  'calendar_duration',
  'assignment_department',
  'assigned_department',
  'assignment_person',
  'assigned_person',
  'relation_target',
]
let projectStart = null
let projectEnd = null

export default class Gantt extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      currentZoomLevel: 'weeks',
      fullscreenMode: false,
      selectedDepartment: this.props.filters?.department_id,
      selectedLayout: 'default',
    }
    selectedDepartment = this.props.filters.department_id
    onlyCritical = this.props.filters.only_critical
    this.resizerControls = React.createRef()
    this.initZoom()
  }

  componentDidMount() {
    const { projectId, roleName, departmentId } = this.props
    ganttConfig(projectId, roleName)
    const _this = this
    let updatedTasks = []
    let updatedParentTasks = []
    /* check task is updated by drag/drop or not */
    let manual_drag = false
    gantt.config.autosize_min_width = 476

    if (this.props.isTouchMode) {
      gantt.config.autosize = 'xy'
      gantt.config.touch = false
      gantt.config.touch_tooltip = true
      gantt.config.prevent_default_scroll = true
      gantt.config.preserve_scroll = false
      gantt.config.grid_resize = false
      gantt.config.autoscroll = false
      gantt.config.drag_move = false
      gantt.config.drag_progress = false
      gantt.config.drag_resize = false
      gantt.config.drag_links = false
      gantt.config.touch_drag = false
      // console.log('config', gantt.config)
    }

    gantt.init(this.ganttContainer)

    this.onBeforeParse = gantt.attachEvent('onBeforeParse', function () {
      closedTasks = []
      gantt.eachTask(function (task) {
        if (!task.$open) closedTasks.push(task.id)
      })
    })

    this.onParse = gantt.attachEvent('onParse', function () {
      if (closedTasks.length) {
        for (var i = 0; i < closedTasks.length; i++) {
          gantt.close(closedTasks[i])
        }
      }
    })

    gantt.parse(this.props.tasks)
    this.setZoom('weeks')

    function taskBelongsToUser(task, userId) {
      return (
        task.assigned_to_id === userId ||
        task.assignment_id === userId ||
        task.supervisor_id === userId ||
        task.responsible_ids?.includes(userId)
      )
    }

    function taskBelongsToDepartment(task, departmentId) {
      return (
        task.assigned_department_id === departmentId ||
        task.assignment_department_id === departmentId ||
        task.supervisor_department_id === departmentId ||
        task.responsible_department_ids?.includes(departmentId)
      )
    }

    /* get related parent tasks */
    let parent_parameters = []
    function get_parent_parameters(task) {
      if (task.parent) {
        const parent = gantt.getTask(task.parent)
        parent_parameters.push({
          id: parent.id,
          calendar_id: parent.calendar_id,
          start_date: parent.start_date,
          end_date: parent.end_date,
        })
        if (parent.parent) get_parent_parameters(parent)
      }
    }

    function match_parents(id) {
      for (let i = 0; i < parent_parameters.length; i++) {
        if (id === parent_parameters[i].id) return i
      }
      return null
    }

    function compare_parent_parameters(task) {
      if (task.parent) {
        const parent = gantt.getTask(task.parent)
        const parent_from_array = parent_parameters[match_parents(parent.id)]
        if (
          !parent_from_array ||
          (parent_from_array &&
            (+parent.start_date !== +parent_from_array.start_date ||
              +parent.end_date !== +parent_from_array.end_date))
        ) {
          updatedParentTasks.unshift(parent)
          updatedParentTasks = uniqBy(updatedParentTasks, 'id')
        }
        if (parent.parent) compare_parent_parameters(parent)
      }
    }

    this.onBeforeLightbox = gantt.attachEvent('onBeforeLightbox', (id) => {
      const task = gantt.getTask(id)
      if (!this.props.userCalendar && task.$new) {
        Modal.error({
          title: 'Không thể tạo công việc',
          content: 'Vui lòng tạo lịch làm việc cho phòng ban của bạn',
        })
        gantt.deleteTask(id)
        return false
      }

      const parent = gantt.getParent(task)
      task._parent = parent ? gantt.getTask(parent) : null

      if (task.$new) {
        if (task._parent.is_root) {
          task.calendar_id = this.props.userCalendar.id
        } else {
          task.calendar_id = task._parent.calendar_id
        }
        gantt.updateTask(id)
      }

      this.props.showModal(task)
      return false
    })

    this.onLinkDblClick = gantt.attachEvent('onLinkDblClick', (id) => {
      const link = gantt.getLink(id)
      this.props.showModal(link, true)
      return false
    })

    this.onAfterAutoSchedule = gantt.attachEvent('onAfterAutoSchedule', function (taskId, relatedTasks) {
      updatedTasks = uniqBy([...relatedTasks.map((id) => ({ ...gantt.getTask(id) })), ...updatedTasks], 'id')
      if (taskId) {
        const task = gantt.getTask(taskId)
        // console.log(task)
        const updatedTask = { ...task }
        updatedTasks.unshift(updatedTask)
        compare_parent_parameters(gantt.getTask(taskId))
        /* if the task was updated on the edit form */
        if (gantt.isManualUpdate) {
          // compare_parent_parameters(gantt.getTask(taskId))
          const updatedData = {
            ...updatedTask,
            related_work_packages: [...updatedParentTasks, ...updatedTasks].filter(
              (item) => item.id !== task.id,
            ),
          }
          _this.props.handleUpdateWorkPackage(updatedData, () => {
            updatedParentTasks = []
            updatedTasks = []
            parent_parameters = []
          })
        }
        gantt.isManualUpdate = false
      }
    })

    let resizerControls = this.resizerControls?.current

    const zoombar = document.querySelector('.zoom-bar')
    const sider = document.querySelector('.ant-layout-sider')

    this.onGanttRender = gantt.attachEvent('onGanttRender', function () {
      if (_this.state.selectedLayout == 'default' && !_this.props.isTouchMode) {
        const resizer = document.querySelector('.gantt_resizer')
        let siderWidth = sider ? sider.getBoundingClientRect().width : 0
        /** To adjust the initial potision of the resizer controls */
        if (resizer && resizerControls) {
          resizerControls.style.left = `calc(${resizer.getBoundingClientRect().left}px - ${siderWidth}px)`
        }
      }
      resizerControls.style.top = zoombar.getBoundingClientRect().height - 15 + 'px'
    })

    if (!_this.props.isTouchMode) {
      this.onGridResize = gantt.attachEvent('onGridResize', function (old_width, new_width) {
        resizerControls.style.left = new_width + 1 + 'px'
      })
      let isBrowserResize = false,
        timerBrowserResize
      this.onBrowserResize = window.addEventListener('resize', function () {
        if (!isBrowserResize) {
          isBrowserResize = true
          return false
        }
        clearTimeout(timerBrowserResize)
        timerBrowserResize = setTimeout(function () {
          let availableHeight =
            window.innerHeight -
            (zoombar.getBoundingClientRect().height + zoombar.getBoundingClientRect().top)

          if (_this.ganttContainer) {
            _this.ganttContainer.style.height = availableHeight + 'px'
            _this.ganttContainer.classList.add('--resized')
          }

          resizerControls.style.top = zoombar.getBoundingClientRect().height - 15 + 'px'

          gantt.setSizes()
        }, 200)
      })
    }

    this.onAfterLinkAdd = gantt.attachEvent('onAfterLinkAdd', function (id, link) {
      _this.props.handleAddLink(link, [...updatedParentTasks, ...updatedTasks])
    })

    this.onAfterLinkUpdate = gantt.attachEvent('onAfterLinkUpdate', function (id, link) {
      _this.props.handleUpdateLink(link, [...updatedParentTasks, ...updatedTasks])
    })

    /* get updated parent tasks */
    this.onBeforeTaskUpdate = gantt.attachEvent('onBeforeTaskUpdate', function (id) {
      if (manual_drag === false) {
        parent_parameters = []
        get_parent_parameters(gantt.getTask(id))
      }
      return true
    })

    this.onBeforeTaskDrag = gantt.attachEvent('onBeforeTaskDrag', function (id) {
      const task = gantt.getTask(id)
      if (
        (roleName === 'work_package' &&
          (projectPermission('work_package:update', _this.props.projectId) ||
            (taskBelongsToUser(task, _this.props.userId) &&
              projectPermission('my_work_package:update', _this.props.projectId)))) ||
        (roleName === 'my_work_package' && projectPermission('my_work_package:update', _this.props.projectId))
      ) {
        updatedParentTasks = []
        updatedTasks = []
        manual_drag = true
        parent_parameters = []
        get_parent_parameters(gantt.getTask(id))
        return true
      }
      return false
    })

    this.onBeforeTaskDisplay = gantt.attachEvent('onBeforeTaskDisplay', function (id, task) {
      if (task.is_root) return true
      if (_this.props.assignedToMe && !taskBelongsToUser(task, _this.props.userId)) {
        return false
      }
      if (onlyCritical && !gantt.isCriticalTask(task)) return false

      if (selectedUser && !taskBelongsToUser(task, selectedUser)) {
        return false
      }
      if (selectedDepartment && !taskBelongsToDepartment(task, selectedDepartment)) {
        return false
      }
      return true
    })

    this.onAfterTaskUpdate = gantt.attachEvent('onAfterTaskUpdate', function (id) {
      /* if the user update on the edit form, only apply auto schedule after updated selected task */
      if (!gantt.isManualUpdate) {
        gantt.autoSchedule(id)
      }
      manual_drag = false
      compare_parent_parameters(gantt.getTask(id))
    })

    this.onAfterTaskDrag = gantt.attachEvent('onAfterTaskDrag', function (id) {
      const task = gantt.getTask(id)
      const updatedData = {
        ...task,
        related_work_packages: [...updatedParentTasks, ...updatedTasks].filter((item) => item.id !== task.id),
      }
      console.log(updatedData.related_work_packages)
      _this.props.handleUpdateWorkPackage(updatedData, () => {
        updatedParentTasks = []
        updatedTasks = []
        parent_parameters = []
      })
    })

    this.onTaskLoading = gantt.attachEvent('onTaskLoading', function (task) {
      task.start_date = gantt.date.parseDate(task.start_date, 'xml_date')
      task.end_date = gantt.date.parseDate(moment(task.end_date).format('YYYY-MM-DD 23:59:59'), 'xml_date')
      task.target_start_date = gantt.date.parseDate(task.target_start_date, 'xml_date')
      task.target_end_date = gantt.date.parseDate(
        moment(task.target_end_date).format('YYYY-MM-DD 23:59:59'),
        'xml_date',
      )
      return true
    })

    this.onGridHeaderClick = gantt.attachEvent('onGridHeaderClick', function (name, e) {
      if (name === 'text') {
        // if (!prevWidth) {
        //   prevWidth = getTaskWidth(isShowGrid)
        // }
        let maxWidth = getTaskWidth(isShowGrid)
        const column = gantt.getGridColumn(name)
        gantt.eachTask(function (task) {
          const width = getColumnPadding(task, column) + getStringWidth(task.text) / 2
          if (width > maxWidth) {
            maxWidth = width
          }
        })
        gantt.getGridColumns().forEach(function (column) {
          if (column.name === 'text') {
            if (!isExpandTaskColumn) {
              prevWidth = column.width
              column.width = maxWidth
            } else {
              column.width = prevWidth
            }
          }
          // gridWidth += column.width
        })
        isExpandTaskColumn = !isExpandTaskColumn
        // gantt.config.grid_width = gridWidth
        gantt.render()
      }
      return true
    })

    this.onGanttReady = gantt.attachEvent('onGanttReady', function () {
      console.log('onGanttReady')
      console.log('this.props.tasks.data', _this.props.tasks)
      if (gantt.config.show_chart) addBaseLine()
    })

    gantt.templates.progress_text = (start, end, task) => {
      return `<span style='text-align:left;padding-left: 10px;'>${Math.round(task.progress * 100)}% </span>`
    }

    gantt.templates.timeline_cell_class = function (task, date) {
      // console.log(gantt.date.date_to_str("%d-%m")(date))
      // if(gantt.date.date_to_str("%d-%m")(date) == '18-11') {
      //   console.log(task)
      //   console.log(gantt.isWorkTime({ date: date, task: task }))
      // }
      if (!gantt.isWorkTime({ date: date, task: task })) return 'week_end'
      return ''
    }

    gantt.resetUpdatedArrray = () => {
      updatedParentTasks = []
      updatedTasks = []
      parent_parameters = []
      manual_drag = false
    }

    addMarker(this.props.project)

    /* fit task text */
    fitTaskText(projectId, roleName, departmentId)
  }

  componentDidUpdate(prevProps) {
    if (this.props.isReloaded !== prevProps.isReloaded) {
      const { tasks, calendars } = this.props
      ganttCalendar(calendars)
      const rootTask = tasks.data.find((item) => item.is_root)
      gantt.parse(tasks)
      const highlight_task = this.props.filters?.highlight_task
      if (prevProps.firstLoading && highlight_task) {
        const task = gantt.getTask(highlight_task)
        if (task) {
          gantt.selectTask(highlight_task)
          const taskPosition = gantt.getTaskPosition(task)
          gantt.scrollTo(taskPosition.left, taskPosition.top)
          // this.props.showModal(task)
        }
      }
      if (rootTask) {
        const { target_end_date, target_start_date, start_date, end_date } = rootTask
        projectEnd = end_date
        if (end_date < target_end_date) {
          projectEnd = target_end_date
        }
        projectStart = start_date
        if (target_start_date < start_date) {
          projectStart = target_start_date
        }
        configScaleTimeline(projectStart, projectEnd)
      }
    }
  }

  componentWillUnmount() {
    gantt.ext.tooltips.hideTooltip()
    gantt.detachEvent(this.onBeforeLightbox)
    gantt.detachEvent(this.onBeforeLinkAdd)
    gantt.detachEvent(this.onLinkDblClick)
    gantt.detachEvent(this.onAfterAutoSchedule)
    gantt.detachEvent(this.onAfterLinkAdd)
    gantt.detachEvent(this.onAfterLinkUpdate)
    gantt.detachEvent(this.onBeforeTaskUpdate)
    gantt.detachEvent(this.onBeforeTaskDrag)
    gantt.detachEvent(this.onAfterTaskUpdate)
    gantt.detachEvent(this.onAfterTaskDrag)
    gantt.detachEvent(this.onParse)
    gantt.detachEvent(this.onTaskDrag)
    gantt.detachEvent(this.onTaskLoading)
    gantt.detachEvent(this.onBeforeParse)
    gantt.detachEvent(this.onBeforeTaskDisplay)
    gantt.detachEvent(this.onGanttRender)
    gantt.detachEvent(this.onGridResize)
    gantt.detachEvent(this.onGridHeaderClick)
    gantt.detachEvent(this.onGanttReady)
    gantt.displayBaseline = false
    selectedDepartment = null
    selectedUser = null
    onlyCritical = null
    gantt.config.highlight_critical_path = false
    gantt.config.show_grid = true
    gantt.config.show_chart = true
    scrollVerCellOffset = 0
    window.removeEventListener('resize', () => {})
    gantt.clearAll()
  }

  initZoom() {
    gantt.ext.zoom.init(zoomLevel)
  }

  setZoom = (value) => {
    console.log(value)
    if (projectStart) {
      gantt.config.start_date = moment(projectStart).add(-1, value)
      gantt.config.end_date = moment(projectEnd).add(1, value)
    }
    gantt.ext.zoom.setLevel(value)
    gantt.render()
  }

  // zoomOut = () => {
  //   gantt.ext.zoom.zoomOut()
  //   gantt.render()
  //   this.setState({ currentZoomLevel: gantt.ext.zoom.getCurrentLevel() })
  // }

  // zoomIn = () => {
  //   gantt.ext.zoom.zoomIn()
  //   gantt.render()
  //   this.setState({ currentZoomLevel: gantt.ext.zoom.getCurrentLevel() })
  // }

  fullscreen = () => {
    if (!gantt.getState().fullscreen) {
      gantt.expand()
      this.setState({ fullscreenMode: true })
    } else {
      gantt.collapse()
      this.setState({ fullscreenMode: false })
    }
  }

  onDisplayTargetTask = () => {
    gantt.displayBaseline = !gantt.displayBaseline
    gantt.render()
  }

  onDisplayCriticalPath = () => {
    gantt.config.highlight_critical_path = !gantt.config.highlight_critical_path
    gantt.render()
  }

  onToggleGrid = (showGrid = false) => {
    isExpandTaskColumn = false
    const { projectId, roleName } = this.props
    if (Array.isArray(showGrid)) {
      if (!this.resizerControls.current.classList.contains('--isActive')) {
        this.resizerControls.current.classList.add('--isActive')
      }
      gantt.config.autofit = true
      gantt.config.show_grid = true
      gantt.config.show_chart = false
      gantt.config.layout = getGanttLayout(projectId, roleName, [])
      isShowGrid = []
    } else {
      if (showGrid) {
        this.resizerControls.current.classList.remove('--isActive')
      } else {
        if (!this.resizerControls.current.classList.contains('--isActive')) {
          this.resizerControls.current.classList.add('--isActive')
        }
      }
      gantt.config.autosize = false
      gantt.config.show_grid = showGrid
      gantt.config.show_chart = true
      gantt.config.layout = getGanttLayout(projectId, roleName, gantt.config.show_grid)
      isShowGrid = gantt.config.show_grid
    }
    gantt.config.layout = getGanttLayout(projectId, roleName, selectedColumns)
    gantt.init(this.ganttContainer)
    gantt.parse(this.props.tasks)
  }

  onToggleVisibility = (value) => {
    const { projectId, roleName } = this.props

    selectedColumns = value
    gantt.config.layout = getGanttLayout(projectId, roleName, value)

    gantt.init(this.ganttContainer)
    gantt.parse(this.props.tasks)
  }

  onToggleLayout = (value) => {
    switch (value) {
      case 'default':
        this.onToggleGrid(true)
        break
      case 'gantt':
        this.onToggleGrid(false)
        break
      case 'task':
        this.onToggleGrid([])
        break
    }
    this.setState({ selectedLayout: value })
  }

  onFilterByDepartment = (value) => {
    selectedDepartment = value
    this.setState({ selectedDepartment })
    selectedUser = null
    gantt.refreshData()
    gantt.setSizes()
  }

  onFilterByUser = (value) => {
    selectedUser = value
    gantt.refreshData()
    gantt.setSizes()
  }

  onFilterByCriticalPath = () => {
    onlyCritical = !onlyCritical
    console.log(onlyCritical)
    gantt.refreshData()
    gantt.setSizes()
  }

  exportFile = (file) => {
    const { project, projectId, roleName } = this.props

    if (file !== 'excel') {
      const exportColumns = selectedColumns.filter((item) =>
        [
          'progress',
          'text',
          'target_start_date',
          'target_end_date',
          // 'calendar_target_duration',
          'relation_target',
          // 'assigned_department',
          // 'assigned_person',
        ].includes(item),
      )
      gantt.config.layout = getGanttLayout(projectId, roleName, exportColumns, true, file === 'png')
      /* display by years */
      if (moment(gantt.config.end_date).diff(moment(gantt.config.start_date), 'months') > 36) {
        gantt.config.start_date = moment(projectStart).add(-8, 'months')
        gantt.config.end_date = moment(projectEnd).add(8, 'months')
        gantt.ext.zoom.setLevel('export_3')
      } else if (moment(gantt.config.end_date).diff(moment(gantt.config.start_date), 'months') > 24) {
        /* display by quarters */
        gantt.config.start_date = moment(projectStart).add(-2, 'months')
        gantt.config.end_date = moment(projectEnd).add(2, 'months')
        gantt.ext.zoom.setLevel('export_2')
      } else {
        /* display by month */
        gantt.config.start_date = moment(projectStart).add(-6, 'weeks')
        gantt.config.end_date = moment(projectEnd).add(6, 'weeks')
        gantt.ext.zoom.setLevel('export')
      }
      gantt.init(this.ganttContainer)
      gantt.parse(this.props.tasks)
    }
    // const server = 'http://localhost:3200/gantt'
    const server = 'https://export.dhtmlx.com/gantt'
    if (file === 'excel') {
      gantt.exportToExcel({
        name: `${project.name}.xlsx`,
        server: server,
        // data: gantt.serialize().data,
        // columns: [
        //   { id: 'text', header: 'Công việc', width: 100 },
        //   { id: 'progress', header: 'Tiến độ', width: 15 },
        //   { id: 'target_start_date', header: 'Bắt đầu dự kiến', width: 15, type: 'date' },
        //   { id: 'target_end_date', header: 'Kết thúc dự kiến', width: 15, type: 'date' },
        //   { id: 'target_duration', header: 'Thời gian dự kiến', width: 15 },
        //   { id: 'duration', header: 'Thời gian thực tế', width: 15 },
        //   { id: 'assigned?.department?.name', header: 'Nhân viên', width: 15 },
        // ],
        date_format: 'yyyy-mm-dd',
        // visual: true,
        // cellColors: true,
        // raw: true,
      })
    } else if (file === 'png') {
      gantt.exportToPNG({
        name: `${project.name}.png`,
        header: ganttExportHeader(project.name),
        server: server,
        raw: true,
        // additional_settings: {
        //   format: 'A4', //or `A4`, `A5`, `Legal`, `Letter`, `Tabloid`
        //   landscape: true, // works only when the `format` has the correct value
        //   width: 'content', // for multipage export
        //   // height: 'content', // for multipage export
        // },
      })
    } else {
      gantt.exportToPDF({
        header: ganttExportHeader(project.name),
        footer: ganttExportFooter(this.props.auth?.full_name),
        name: `${project.name}.pdf`,
        server: server,
        raw: true,
        additional_settings: {
          format: 'A4', //or `A4`, `A5`, `Legal`, `Letter`, `Tabloid`
          landscape: true, // works only when the `format` has the correct value
          // width: 'content', // for multipage export
          // height: 500, // for multipage export
        },
      })
    }
    if (file !== 'excel') {
      gantt.config.layout = getGanttLayout(projectId, roleName, selectedColumns)
      gantt.config.start_date = moment(projectStart).add(-1, 'weeks')
      gantt.config.end_date = moment(projectEnd).add(1, 'weeks')
      gantt.ext.zoom.setLevel(this.state.currentZoomLevel)
      gantt.init(this.ganttContainer)
      gantt.parse(this.props.tasks)
    }
  }

  render() {
    const { currentZoomLevel, fullscreenMode } = this.state
    const { tasks } = this.props
    const rootTask = tasks.data.find((item) => item.is_root)

    const menuLayoutControl = !this.props.isTouchMode ? (
      <>
        <li className="layout--reset">
          <Tooltip title="Reset">
            <Button
              type="link"
              onClick={(e) => {
                this.onToggleGrid(true)
                this.setState({ selectedLayout: 'default' })
                this.resizerControls.current.style.left = `calc(691px + ${scrollVerCellOffset}px)`
              }}
            >
              <MergeCellsOutlined style={{ fontSize: '15px', padding: 0, margin: 0 }} />
              <ArrowRightOutlined style={{ fontSize: '12px', padding: 0, margin: 0 }} />
            </Button>
          </Tooltip>
        </li>
        <li className="layout--task">
          <Tooltip title="Mở rộng Gantt">
            <Button
              type="link"
              onClick={(e) => {
                this.onToggleGrid(false)
                this.setState({ selectedLayout: 'gantt' })
                this.resizerControls.current.style.left = 22 + 'px'
              }}
              style={{ padding: 0, margin: 0 }}
            >
              <ArrowLeftOutlined style={{ fontSize: '12px', padding: 0 }} />
              <SignalFilled style={{ fontSize: '15px', padding: 0 }} />
            </Button>
          </Tooltip>
        </li>
        <li className="layout--gantt">
          <Tooltip title="Mở rộng Task">
            <Button
              type="link"
              onClick={(e) => {
                this.onToggleGrid([])
                this.setState({ selectedLayout: 'task' })
                this.resizerControls.current.style.left = 22 + 'px'
              }}
              style={{ padding: 0, margin: 0 }}
            >
              <DatabaseFilled style={{ fontSize: '15px', padding: 0, margin: 0 }} />
              <ArrowRightOutlined style={{ fontSize: '12px', padding: 0, margin: 0 }} />
            </Button>
          </Tooltip>
        </li>
      </>
    ) : (
      ''
    )
    // const menuLayoutControl = (
    //   <d>
    //     <li className="layout--reset">
    //       <Tooltip title="Reset">
    //         <Button
    //           type="link"
    //           onClick={(e) => {
    //             this.onToggleGrid(true)
    //             this.setState({ selectedLayout: 'default' })
    //             this.resizerControls.current.style.left = `calc(691px + ${scrollVerCellOffset}px)`
    //           }}
    //         >
    //           <MergeCellsOutlined style={{ fontSize: '15px', padding: 0, margin: 0 }} />
    //           <ArrowRightOutlined style={{ fontSize: '12px', padding: 0, margin: 0 }} />
    //         </Button>
    //       </Tooltip>
    //     </li>
    //     <li className="layout--task">
    //       <Tooltip title="Mở rộng Gantt">
    //         <Button
    //           type="link"
    //           onClick={(e) => {
    //             this.onToggleGrid(false)
    //             this.setState({ selectedLayout: 'gantt' })
    //             this.resizerControls.current.style.left = 22 + 'px'
    //           }}
    //           style={{ padding: 0, margin: 0 }}
    //         >
    //           <ArrowLeftOutlined style={{ fontSize: '12px', padding: 0 }} />
    //           <SignalFilled style={{ fontSize: '15px', padding: 0 }} />
    //         </Button>
    //       </Tooltip>
    //     </li>
    //     <li className="layout--gantt">
    //       <Tooltip title="Mở rộng Task">
    //         <Button
    //           type="link"
    //           onClick={(e) => {
    //             this.onToggleGrid([])
    //             this.setState({ selectedLayout: 'task' })
    //             this.resizerControls.current.style.left = 22 + 'px'
    //           }}
    //           style={{ padding: 0, margin: 0 }}
    //         >
    //           <DatabaseFilled style={{ fontSize: '15px', padding: 0, margin: 0 }} />
    //           <ArrowRightOutlined style={{ fontSize: '12px', padding: 0, margin: 0 }} />
    //         </Button>
    //       </Tooltip>
    //     </li>
    //   </div>
    // )

    return (
      <>
        {/* <div style="display: flex; align-items: center;"><img src="https://myproject.tienphatcorp.com/tp-logo-tiny.jpg" style="width: 120px; height: 120px; margin-right: 20px; display: inline-block" />
      <h1 style="line-height: 120px; display: inline-block">fewfe</h1></div> */}
        <div className="zoom-bar">
          <GanttToolbar
            zoom={currentZoomLevel}
            exportFile={this.exportFile}
            fullscreenMode={fullscreenMode}
            onDisplayTargetTask={this.onDisplayTargetTask}
            onDisplayCriticalPath={this.onDisplayCriticalPath}
            zoomLevel={zoomLevel}
            isDisplayFilter={!this.props.assignedToMe}
            departments={this.props.projectUsers.departments}
            users={this.props.projectUsers.users}
            onFilterByDepartment={this.onFilterByDepartment}
            onFilterByUser={this.onFilterByUser}
            selectedDepartment={selectedDepartment}
            departmentState={this.state.selectedDepartment}
            selectedUser={selectedUser}
            onFilterByCriticalPath={this.onFilterByCriticalPath}
            onlyCritical={onlyCritical}
            onGroupBy={this.groupBy}
            onSelectZoom={this.setZoom}
            zoomToFit={() => zoomToFit(rootTask)}
            fullscreen={this.fullscreen}
            onZoomChange={this.handleZoomChange}
            onToggleGrid={this.onToggleGrid}
            onToggleVisibility={this.onToggleVisibility}
            selectedLayout={this.state.selectedLayout}
            onToggleLayout={this.onToggleLayout}
            isTouchMode={this.props.isTouchMode}
          />
        </div>
        <ul className="gantt-layout-controls" ref={this.resizerControls}>
          {menuLayoutControl}
        </ul>
        <div
          ref={(input) => {
            this.ganttContainer = input
          }}
          className="gantt"
        />
      </>
    )
  }
}
