import React, { useEffect } from 'react'
import { Table, Tag, Tooltip, Button, Space } from 'antd'
import permission from 'utils/permission'
import { LineOutlined } from 'assets/icon'

const weekdayColor = {
  monday: {
    color: '#2274A5',
  },
  tuesday: {
    color: '#BA3B46',
  },
  wednesday: {
    color: '#4C243B',
  },
  thursday: {
    color: '#0B7189',
  },
  friday: {
    color: '#157145',
  },
  saturday: {
    color: '#B47EB3',
  },
  sunday: {
    color: '#228CDB',
  },
}

function ProjectCalendarList(props) {
  const tablePermission = props.permission ?? permission
  const { data, handleActionButton, isLoading } = props
  const columns = [
    {
      title: 'Phòng ban',
      dataIndex: ['department'],
      width: 120,
      render: (department) => {
        return department?.name || <LineOutlined />
      },
    },
    {
      title: 'Ngày làm việc',
      dataIndex: ['department_weekdays'],
      width: 520,
      render: (department_weekdays) => {
        return (
          department_weekdays?.map((day, index) => (
            <Tag key={index} color={weekdayColor[day]?.color}>
              {day}
            </Tag>
          )) || <LineOutlined />
        )
      },
    },
    {
      title: 'Ngày nghỉ',
      dataIndex: ['department_holidays'],
      render: (department_holidays) => {
        return (
          department_holidays?.map((day, index) => (
            <Tooltip key={index} title={`${day.start_date} - ${day.end_date}`}>
              <Tag key={index}>{day.name}</Tag>
            </Tooltip>
          )) || <LineOutlined />
        )
      },
    },
    ...(tablePermission(`${props.roleName}:edit,${props.roleName}:delete`) || props.isSystemCalendar
      ? [
          {
            title: '',
            width: 180,
            align: 'center',
            fixed: 'right',
            render: (row) => (
              <>
                <Space size="small">
                  {tablePermission(`${props.roleName}:create`) && !props.isSystemCalendar && (
                    <Button onClick={handleActionButton.bind(null, 1, row)} size="small">
                      Sao chép
                    </Button>
                  )}

                  {(tablePermission(`${props.roleName}:update`) || props.isSystemCalendar) && (
                    <Button type="primary" onClick={handleActionButton.bind(null, 0, row)} size="small">
                      Sửa
                    </Button>
                  )}
                  {(tablePermission(`${props.roleName}:delete`) || props.isSystemCalendar) && (
                    <Button
                      onClick={handleActionButton.bind(null, 2, row)}
                      type="primary"
                      danger
                      size="small"
                    >
                      Xoá
                    </Button>
                  )}
                </Space>
              </>
            ),
          },
        ]
      : []),
  ]

  return (
    <>
      <Table
        loading={isLoading}
        pagination={false}
        columns={columns}
        dataSource={data}
        rowKey="id"
        bordered
      />
    </>
  )
}

export default ProjectCalendarList
