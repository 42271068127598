export const config = {
  date: {
    month_full: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    month_short: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    day_full: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    day_short: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  },
  labels: {
    new_task: 'New task',
    icon_save: 'Save',
    icon_cancel: 'Cancel',
    icon_details: 'Details',
    icon_edit: 'Edit',
    icon_delete: 'Delete',
    gantt_save_btn: 'New Label',
    gantt_cancel_btn: 'New Label',
    gantt_delete_btn: 'New Label',
    confirm_closing: '', // Your changes will be lost, are you sure?
    confirm_deleting: 'Task will be deleted permanently, are you sure?',
    section_description: 'Description',
    section_time: 'Time period',
    section_type: 'Type',
    section_file: 'File',
    section_priority: 'Mức độ ưu tiên',
    section_owner: 'Người phụ trách',
    section_category: 'Danh mục',
    section_accountable: 'Người kiểm soát',
    section_title: 'Tiêu đề',
    section_status: 'Trạng thái',
    assignment_department: 'Phòng ban giao việc',
    assigned_department: 'Phòng ban nhận việc',
    assignment_person: 'Người giao việc',
    assigned_person: 'Người nhận việc',

    /* grid columns */
    column_wbs: 'WBS',
    column_id: 'ID',
    column_text: 'Công việc',
    column_status: 'Trạng thái',
    column_assigned: 'Người phụ trách',
    column_target_start_date: 'Bắt đầu dự kiến',
    column_target_end_date: 'Kết thúc dự kiến',
    column_duration: 'Thời gian thực tế',
    column_target_duration: 'Thời gian dự kiến',
    column_priority: 'Mức độ ưu tiên',
    column_progress: 'Tiến độ',
    column_add: '',

    /* link confirmation */
    link: 'Link',
    confirm_link_deleting: 'will be deleted',
    link_start: ' (start)',
    link_end: ' (end)',

    type_task: 'Task',
    type_project: 'Project',
    type_milestone: 'Milestone',

    minutes: 'Minutes',
    hours: 'Hours',
    days: 'Days',
    weeks: 'Week',
    months: 'Months',
    years: 'Years',

    /* message popup */
    message_ok: 'OK',
    message_cancel: 'Cancel',

    /* constraints */
    section_constraint: 'Constraint',
    constraint_type: 'Constraint type',
    constraint_date: 'Constraint date',
    asap: 'As Soon As Possible',
    alap: 'As Late As Possible',
    snet: 'Start No Earlier Than',
    snlt: 'Start No Later Than',
    fnet: 'Finish No Earlier Than',
    fnlt: 'Finish No Later Than',
    mso: 'Must Start On',
    mfo: 'Must Finish On',

    /* resource control */
    resources_filter_placeholder: 'type to filter',
    resources_filter_label: 'hide empty',
  },
}
