import React, { useState, useEffect } from 'react'
import {
  Modal,
  Button,
  Typography,
  Row,
  Select,
  Col,
  Tooltip,
  Tabs,
  List,
  Form,
  InputNumber,
  message,
} from 'antd'
import { PlusOutlined, ExclamationCircleOutlined } from 'assets/icon'
import moment from 'moment'
import isEmpty from 'lodash/isEmpty'
import { chain } from 'lodash'
import { useTranslation } from 'react-i18next'
import { filterString } from 'utils/utility'
import { projectPermission } from 'utils/permission'
import GeneralInfo from './GeneralInfo'
import ActivityLogTab from './ActivityLogTab'
import { linkTypeMapping } from 'components'
import './style.scss'
import gantt from 'dhtmlx-gantt/codebase/dhtmlxgantt'
import { fileBlackList } from 'utils'

const { Text } = Typography
const { Option, OptGroup } = Select
const { TabPane } = Tabs

const formTailLayout = {
  wrapperCol: { span: 24 },
  labelCol: { span: 4 },
}

const AddTaskModal = (props) => {
  const {
    visible,
    taskData,
    onCancel,
    workPackagesList,
    relationList,
    onDelete,
    handleAddLink,
    handleDeleteRelation,
    masterData,
    onFinish,
    relations,
    projectId,
    onEdit,
    roleName,
    userId,
    loading,
    authenticatedUser,
    getWorkPackageLogs,
  } = props
  const { t } = useTranslation()
  const [listSourceTask, setListSourceTask] = useState([])
  const [listAddedSource, setAddedSource] = useState([])
  const [isAddLink, setIsAddLink] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [selectedLinkData, setSelectedLinkData] = useState({})
  const [optionGroups, setOptionGroups] = useState({})
  const [activityLogs, setActivityLogs] = useState([])

  const [form] = Form.useForm()
  const [addLinkForm] = Form.useForm()
  const [editLinkForm] = Form.useForm()

  useEffect(() => {
    const groupedData = chain(masterData.project_users)
      .groupBy((x) => x.department?.name)
      .value()
    setOptionGroups(groupedData)
  }, [masterData.project_users])

  useEffect(() => {
    if (!isEmpty(taskData) && !taskData.$new) {
      const links = relationList.filter(({ target }) => taskData.id === target) || {}
      const tasks = workPackagesList
        .map((item) => {
          const link = links.filter((i) => i.source === item.id)
          if (isEmpty(link)) return item
          else return { ...item, relation: link[0], taskIndex: gantt.getGlobalTaskIndex(item.id) + 1 }
        })
        .filter((item) => item.relation)
      setListSourceTask(tasks)
    }
  }, [taskData, relationList])
  const targetTask =
    relationList.filter(({ source }) => source === taskData.id).map(({ target }) => target) || {}

  // let selectRelationOptsa = workPackagesList.filter((item) => {
  //   return (
  //     item.id !== taskData.id &&
  //     !targetTask.includes(item.id) &&
  //     !listSourceTask.map(({ id }) => id).includes(item.id) &&
  //     !listAddedSource.includes(item.id) &&
  //     !item.is_root &&
  //     item.parent_id !== taskData.id &&
  //     item.id !== taskData.parent_id
  //   )
  // })
  // console.log(selectRelationOptsa)
  let selectRelationOpts = gantt
    .serialize()
    .data.map((item, index) => ({ taskIndex: index, ...item }))
    .filter((item) => {
      return (
        item.id !== taskData.id &&
        !targetTask.includes(item.id) &&
        !listSourceTask.map(({ id }) => id).includes(item.id) &&
        !listAddedSource.includes(item.id) &&
        !item.is_root &&
        item.parent_id !== taskData.id &&
        item.id !== taskData.parent_id
      )
    })
  // console.log(selectRelationOpts)

  const permission = (actions) => {
    return projectPermission(actions, projectId)
  }

  const generalPermission = (action) => {
    return (
      permission(`work_package:${action}`) ||
      (permission(`my_work_package:${action}`) &&
        (taskData.assigned_to_id === userId ||
          taskData.assignment_id === userId ||
          taskData.supervisor_id === userId ||
          taskData.responsible_ids?.includes(userId)))
    )
  }

  const onClickDeleteRelation = (item) => {
    Modal.confirm({
      title: 'Xoá công việc liên quan',
      icon: <ExclamationCircleOutlined />,
      okText: 'Xoá',
      okType: 'danger',
      cancelText: 'Đóng',
      maskClosable: true,
      centered: true,
      onOk: () => {
        return new Promise((resolve, reject) => {
          if (taskData.$new) {
            setListSourceTask(listSourceTask.filter((i) => i.id !== item.id))
            setAddedSource(listAddedSource.filter((i) => i !== item.id))
            resolve()
          } else {
            handleDeleteRelation(item?.relation?.id, resolve, reject)
            if (isEditing) {
              setIsEditing(false)
              setSelectedLinkData({})
            }
          }
        })
      },
    })
  }

  const onClickEditRelation = (item) => {
    if (item) {
      const source = (({ relation, ...rest }) => rest)(item)
      setSelectedLinkData({
        data: item.relation,
        selectRelationOpts: [source],
      })
      setIsAddLink(false)
      setIsEditing(!isEditing)
    }
  }

  useEffect(() => {
    if (!isEditing || isAddLink) {
      addLinkForm.resetFields()
      addLinkForm.setFieldsValue({
        lag: 0,
      })
    }

    if (isEditing && selectedLinkData.data) {
      editLinkForm.resetFields()
      editLinkForm.setFieldsValue({
        source: selectedLinkData.data.source,
        type: selectedLinkData.data.type,
        lag: selectedLinkData.data.lag || 0,
      })
    }
  }, [isEditing, selectedLinkData, isAddLink])

  const renderListLinks = (item) => {
    console.log(item)
    return (
      <List.Item
        actions={[
          ...(permission('relation:update')
            ? [
                <Tooltip title="Sửa" key="list-loadmore-edit">
                  <Text
                    {...(isAddLink && { disabled: true })}
                    type="primary"
                    underline
                    className="pointer"
                    style={{ margin: '0 8px' }}
                    onClick={() => {
                      isAddLink ? setIsAddLink(false) : onClickEditRelation(item)
                    }}
                  >
                    Sửa
                  </Text>
                </Tooltip>,
              ]
            : []),
          ...(permission('relation:delete')
            ? [
                <Tooltip title="Xoá" key="list-loadmore-edit">
                  <Text
                    type="danger"
                    underline
                    className="pointer"
                    style={{ margin: '0 8px' }}
                    onClick={() => {
                      onClickDeleteRelation(item)
                    }}
                  >
                    Xoá
                  </Text>
                </Tooltip>,
              ]
            : []),
        ]}
      >
        <List.Item.Meta
          title={`${item.taskIndex} - ${item.text}`}
          description={`${moment(item.start_date).format('DD/MM/YYYY')} - ${moment(item.end_date).format(
            'DD/MM/YYYY',
          )}`}
        />
        <div style={{ marginRight: 15, marginLeft: 15 }}>{item.assigned?.department?.name}</div>
        <div>{linkTypeMapping.find(({ key }) => key === item?.relation?.type)?.short_label || ''}</div>
      </List.Item>
    )
  }

  const handleDelete = () => {
    onDelete(setIsDeleting)
  }

  const clearFormAndResetState = () => {
    setIsAddLink(false)
    setIsEditing(false)
    addLinkForm.resetFields()
    form.resetFields()
    editLinkForm.resetFields()
    setListSourceTask([])
    setAddedSource([])
  }

  const handleCancel = () => {
    onCancel()
    clearFormAndResetState()
  }

  const handleSubmitAddLink = (value) => {
    if (taskData.$new) {
      const tasks = workPackagesList
        .map((item) => {
          if (item.id === value.source) return { ...item, relation: value }
          else return item
        })
        .filter((item) => item.relation)
      setListSourceTask(listSourceTask.concat(tasks))
      setAddedSource(listAddedSource.concat(value.source))
      addLinkForm.resetFields()
      setIsAddLink(false)
    } else {
      handleAddLink({ ...value, target: taskData.id }, () => {
        addLinkForm.resetFields()
        setIsAddLink(false)
      })
    }
  }

  const handleSubmitEditLink = async () => {
    try {
      const values = await editLinkForm.validateFields()
      onEdit({ ...selectedLinkData.data, ...values }, () => clearFormAndResetState())
    } catch (error) {
      Modal.error({
        title: 'Lỗi',
        content: error.message,
      })
    }
  }

  const handleSubmitForm = (value) => {
    value.work_package_files = value.files?.filter((file) => file.id)

    //value.files = value.files?.filter((file) => !file.id).map((file) => file.originFileObj)
    if (value.files) {
      if (value.directory) {
        const files = []
        const filePaths = []
        value.files.forEach(function (file) {
          const fileTmp = file.originFileObj
          let fileExtension = file.name.split('.')
          fileExtension = fileExtension[fileExtension.length - 1]
          if (!fileBlackList.includes(fileExtension)) {
            if (!file.id) {
              files.push(fileTmp)
              filePaths.push(fileTmp.webkitRelativePath)
            }
          }
        })
        value.files = files
        value.filePaths = filePaths
      } else {
        value.files = value.files.filter((file) => !file.id).map((file) => file.originFileObj)
      }
    }

    if (isEditing) {
      message.warning('Bạn chưa hoàn tất chỉnh sửa phụ thuộc')
    } else {
      const target_end_date = moment(value.target_end_date).add(1, 'days')
      const end_date = moment(value.end_date).add(1, 'days')

      onFinish(
        {
          ...value,
          relations,
          end_date,
          target_end_date,
          ...(value.hasOwnProperty('supervisor_id') ? { supervisor_id: value.supervisor_id ?? null } : null),
          ...(value.hasOwnProperty('assigned_to_id')
            ? { assigned_to_id: value.assigned_to_id ?? null }
            : null),
        },
        () => clearFormAndResetState(),
      )
    }
  }

  const changeTab = async (activeTab) => {
    if (activeTab === 'activity-log' && !activityLogs.length) {
      const { data } = await getWorkPackageLogs(projectId, taskData.id)
      setActivityLogs(data)
    }
  }

  const renderRelationForm = () => {
    return (
      <Form
        wrapperCol={{ span: 24 }}
        form={isEditing ? editLinkForm : addLinkForm}
        onFinish={isEditing ? handleSubmitEditLink : handleSubmitAddLink}
        className="modal-relation"
      >
        <Row gutter={[12, 12]}>
          <Col md={8} xs={24}>
            <Form.Item name="source" rules={[{ required: true, message: 'Vui lòng chọn công việc' }]}>
              <Select
                {...(isEditing && { disabled: true })}
                style={{ width: '100%' }}
                placeholder="Chọn công việc"
                allowClear
                showSearch
                optionFilterProp="text"
                filterOption={(input, option) =>
                  filterString(input, option.children) || filterString(input, option.label)
                }
              >
                {[...(isEditing ? selectedLinkData.selectRelationOpts : selectRelationOpts)].map(
                  ({ id, text, taskIndex }) => (
                    <Option key={id} value={id}>
                      {`${taskIndex + 1} - ${text}`}
                    </Option>
                  ),
                )}
              </Select>
            </Form.Item>
          </Col>

          <Col md={8} xs={24}>
            <Form.Item name="type" rules={[{ required: true, message: 'Vui lòng chọn liên kết' }]}>
              <Select
                {...(isEditing && { disabled: true })}
                style={{ width: '100%' }}
                placeholder="Chọn loại liên kết"
              >
                {linkTypeMapping.map(({ key, label }) => (
                  <Option key={key} value={key}>
                    {label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col md={4} xs={24}>
            <Form.Item name="lag">
              <InputNumber
                style={{ width: '100%' }}
                placeholder="Sớm/Trễ"
                formatter={(value) => `${value} Ngày`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              />
            </Form.Item>
          </Col>

          <Col flex="1 1 auto" md={{ span: 4, push: 0 }} xs={{ span: 12, push: 12 }}>
            <Button
              key="submit"
              type="primary"
              onClick={() => {
                isEditing ? editLinkForm.submit() : addLinkForm.submit()
              }}
            >
              {isEditing ? 'Cập nhật' : 'Thêm'}
            </Button>

            <Button
              key="back"
              type="default"
              onClick={() => {
                isEditing ? setIsEditing(false) : setIsAddLink(false)
              }}
            >
              Huỷ
            </Button>
          </Col>
        </Row>
      </Form>
    )
  }

  const renderRelationTab = () => {
    return (
      <div className="pad-bot-md">
        {!isAddLink && !isEditing ? (
          <Col span={24} className="pad-vertical-sm">
            {permission('relation:create') && (
              <Button type="dashed" onClick={() => setIsAddLink(true)} style={{ width: '100%' }}>
                <PlusOutlined /> Thêm công việc liên quan
              </Button>
            )}
          </Col>
        ) : (
          renderRelationForm()
        )}
        <List
          className="demo-loadmore-list"
          itemLayout="horizontal"
          dataSource={listSourceTask}
          renderItem={renderListLinks}
          bordered
        />
      </div>
    )
  }

  const renderTaskInvolve = () => {
    return (
      <Form {...formTailLayout} className="pad-vertical-sm" form={form} onFinish={handleSubmitForm}>
        <Row gutter={[0, 0]}>
          <Col md={12} xs={24}>
            <Form.Item labelCol={{ span: 8 }} label="Người giao việc">
              {taskData.$new ? authenticatedUser?.full_name : taskData.assignment?.full_name}
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              labelCol={{
                span: 8,
              }}
              name="supervisor_id"
              label="Người kiểm soát"
            >
              <Select
                placeholder="Chọn nhân viên"
                allowClear
                showSearch
                optionFilterProp="full_name"
                filterOption={(input, option) =>
                  filterString(input, option.children) || filterString(input, option.label)
                }
              >
                {Object.keys(optionGroups).map((groupName) => (
                  <OptGroup key={groupName} label={groupName}>
                    {optionGroups[groupName].map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.full_name}
                      </Option>
                    ))}
                  </OptGroup>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 0]}>
          <Col span={24}>
            <Form.Item name="responsible_ids" label="Người phụ trách">
              <Select
                showSearch
                placeholder="Chọn nhân viên"
                mode="multiple"
                allowClear
                optionFilterProp="full_name"
                filterOption={(input, option) => filterString(input, option.children)}
              >
                {Object.keys(optionGroups).map((groupName) => (
                  <OptGroup
                    key={groupName}
                    label={
                      <div
                        className="select-option-group-item"
                        onClick={() => {
                          const selected = form.getFieldValue('responsible_ids')
                          form.setFieldsValue({
                            responsible_ids: selected.concat(
                              optionGroups[groupName]
                                .filter((grpOpt) => !selected.includes(grpOpt.id))
                                .map((item) => item.id),
                            ),
                          })
                        }}
                      >
                        {groupName}
                      </div>
                    }
                  >
                    {optionGroups[groupName].map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.full_name}
                      </Option>
                    ))}
                  </OptGroup>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 0]}>
          <Col span={24}>
            <Form.Item name="assigned_to_id" label="Người nhận việc" className="mb-0">
              <Select
                placeholder="Chọn nhân viên"
                allowClear
                showSearch
                optionFilterProp="full_name"
                filterOption={(input, option) =>
                  filterString(input, option.children) || filterString(input, option.label)
                }
              >
                {Object.keys(optionGroups).map((groupName) => (
                  <OptGroup key={groupName} label={groupName}>
                    {optionGroups[groupName].map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.full_name}
                      </Option>
                    ))}
                  </OptGroup>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {/* <Col span={24} className="text-right">
            <Button
              type={'link'}
              className="p-0"
              onClick={() => form.setFieldsValue({ assigned_to_id: userId })}
            >
              Giao cho tôi
            </Button>
          </Col> */}
        </Row>
      </Form>
    )
  }

  return (
    <Modal
      title={taskData.$new ? 'Tạo công việc mới' : 'Chỉnh sửa công việc'}
      width={850}
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      className="task-modal"
      destroyOnClose
      maskClosable={false}
    >
      <Tabs defaultActiveKey="1" onChange={changeTab}>
        <TabPane tab="Thông tin chung" key="1">
          <GeneralInfo
            {...props}
            form={form}
            permission={permission}
            generalPermission={generalPermission}
            roleName={roleName}
            relations={listSourceTask.map((item) => item.relation)}
            handleSubmitForm={handleSubmitForm}
          />
        </TabPane>
        {!taskData.is_root && (
          <TabPane tab="Nhân Viên" key="2">
            {renderTaskInvolve()}
          </TabPane>
        )}
        {!taskData.$new && !taskData.is_root ? (
          <React.Fragment>
            <TabPane tab="Phụ thuộc" key="3">
              {renderRelationTab()}
            </TabPane>
            <TabPane tab="Lịch sử" key="activity-log">
              <ActivityLogTab activityLogs={activityLogs} statuses={masterData.statuses} />
            </TabPane>
          </React.Fragment>
        ) : null}
      </Tabs>
      <Row className="ant-modal-footer">
        <Col span={6}>
          <Row justify="start">
            {!taskData.$new && !taskData.is_root && generalPermission('delete') && (
              <Button
                // {...(isEditing && { disabled: true })}
                key="submit"
                type="danger"
                onClick={handleDelete}
                loading={isDeleting}
              >
                Xoá
              </Button>
            )}
          </Row>
        </Col>
        <Col span={18}>
          <Row justify="end">
            <Button key="back" onClick={handleCancel}>
              Đóng
            </Button>
            {(taskData.$new || (!taskData.$new && generalPermission('update'))) && (
              <Button
                // {...(isEditing && { disabled: true })}
                loading={loading}
                key="submit"
                type="primary"
                onClick={() => form.submit()}
              >
                {taskData.$new ? 'Tạo công việc' : 'Lưu'}
              </Button>
            )}
          </Row>
        </Col>
      </Row>
    </Modal>
  )
}

export default AddTaskModal
