import React, { useState, useMemo, useCallback, forwardRef, useEffect } from 'react'
import moment from 'moment'
import { Modal, Form, Input, Table, Select, Button, Tag, Row, Col } from 'antd'

import { chain } from 'lodash'
import { InboxOutlined } from 'assets/icon'
import { dateFormat } from 'utils'
import * as fileActions from 'store/actions/filestorage.action'
import { FileBreadcrumb } from 'components'
import { DeleteOutlined } from 'assets/icon'
import { filterString } from 'utils/utility'

const { Option, OptGroup } = Select

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
}

function FileLogModal({
  isSelectedProject,
  isSelectedDepartment,
  visible,
  onCancel,
  breadscrumb,
  onSubmit,
  onDelete,
  onSuccess,
  rowData,
  selectedProject,
  fileLogs,
  selectedUsers,
  isMobile,
}) {
  const fileLogsList = useMemo(() => fileLogs, [fileLogs])
  const [selectedFileLogList, setSelectedFileLogList] = useState([])

  useEffect(() => {
    if (visible) {
      //setSelectedFileLogList([])
    }
  }, [visible])

  const columns = [
    {
      title: 'Lịch sử',
      dataIndex: 'content',
      key: 'content',
      render: (content, record) => {
        return (
          <>
            <span
              className="file-log__message"
              dangerouslySetInnerHTML={{
                __html: `${content || ''}`,
              }}
            ></span>
          </>
        )
      },
    },
  ]

  return (
    <Modal
      width={800}
      title={
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          Lịch sử
        </div>
      }
      maskClosable={false}
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancel
        </Button>,
      ]}
      forceRender
    >
      <Table
        className="file-log__list"
        pagination={false}
        rowKey="id"
        columns={columns}
        dataSource={fileLogsList}
        scroll={{ x: 'max-content', y: 200 }}
        tableLayout={'auto'}
        showHeader={false}
      />
    </Modal>
  )
}

export default FileLogModal
