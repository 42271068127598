import React, { useEffect } from 'react'
import { Form, Input, Button } from 'antd'

const formItemLayout = {
  labelCol: {
    xs: { span: 20 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 18 },
    sm: { span: 14 },
  },
}

const tailLayout = {
  wrapperCol: { offset: 5, span: 16 },
}

const UserInfoForm = ({ currentUser, onSubmit }) => {
  const [form] = Form.useForm(null)

  useEffect(() => {
    form.setFieldsValue({
      first_name: currentUser.first_name,
      last_name: currentUser.last_name,
      username: currentUser.username,
      email: currentUser.email,
      phone: currentUser.phone,
    })
  }, [])

  return (
    <Form
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      layout="horizontal"
      id="user-info-form"
      form={form}
      name="dynamic_rule"
      onFinish={onSubmit}
    >
      <Form.Item
        {...formItemLayout}
        name="username"
        label="Tên tài khoản"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập tên tài khoản',
          },
        ]}
      >
        <Input disabled />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="first_name"
        label="Tên"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập tên',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="last_name"
        label="Họ"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập họ',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="email"
        label="Email"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập email',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item {...formItemLayout} name="phone" label="Điện thoại">
        <Input />
      </Form.Item>
      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit">
          Lưu
        </Button>
      </Form.Item>
    </Form>
  )
}
export default UserInfoForm
