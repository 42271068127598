import React from 'react'
import { Bar } from 'react-chartjs-2'

const ProjectBudgetChart = ({ height = 150, project }) => {
  const salesData = {
    labels: ['Kế hoạch', 'Thực tế'],
    datasets: [
      {
        label: 'Ngân sách',
        backgroundColor: ['#51a5ff', '#ffce56'],
        borderColor: ['#51a5ff', '#ffce56'],
        borderWidth: 1,
        hoverBackgroundColor: ['#51a5ff', '#ffce56'],
        hoverBorderColor: ['#51a5ff', '#ffce56'],
        maxBarThickness: 40,
        data: [project.target_budget, project.actual_budget],
      },
    ],
  }

  const stepSize = +(1 + '0'.repeat(`${project.target_budget}`.length - 1))

  return (
    <Bar
      data={salesData}
      height={height}
      options={{
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              var label = data.datasets[tooltipItem.datasetIndex].label || ''

              if (label) {
                label += ': '
              }
              let yLabel = tooltipItem.yLabel
              const stringLg = yLabel.toString().length
              if (stringLg > 9) label += yLabel / 1000000000 + ' tỷ'
               else if (stringLg > 6) label += yLabel / 1000000 + ' triệu'
              return label
            },
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                min: 0,
                stepSize,
                callback: function (value, index, values) {
                  const stringLg = value.toString().length
                  if (stringLg > 9) return value / 1000000000 + ' tỷ'
                  if (stringLg > 6) return value / 1000000 + ' triệu'
                },
              },
            },
          ],
        },
      }}
    />
  )
}

export default ProjectBudgetChart
