const files = [
  {
    fileID: '1',
    key: 1,
    fileName: 'lari_credit_card_account.twd',
    fileDescription: 'motivating.mp4',
    fileSize: 91,
    fileType: 'fileType 1',
    isFolder: false,
    uploadedAt: '2019-11-07T02:53:33.622Z',
    owner: 25960,
    parentID: 17513,
    fileExtension: 'tpl',
    fileURL: 'http://wendy.info',
    sourceTaskID: '04487-0375',
    project_id: 8,
    department_id: 'department_id 1',
    permissions: {
      read: [],
      write: [],
      isOwner: false,
      isReadable: false,
      isWritable: false,
    },
    logs: {
      actionLog: 'Uploaded',
      updatedAt: '2019-11-07T02:53:33.622Z',
      owner: 'admin',
    },
  },
  {
    fileID: '2',
    key: 2,
    fileName: 'consultant_sexy.azf',
    fileDescription: 'input_avon.pdf',
    fileSize: 35,
    fileType: 'fileType 2',
    isFolder: false,
    uploadedAt: '2019-12-22T16:25:44.165Z',
    owner: 24824,
    parentID: 80367,
    fileExtension: 'jpg',
    fileURL: 'https://noble.org',
    sourceTaskID: '37679',
    project_id: 8,
    department_id: 'department_id 2',
    permissions: {
      read: [],
      write: [],
      isOwner: false,
      isReadable: false,
      isWritable: false,
    },
    logs: {
      actionLog: 'Edited',
      updatedAt: '2019-11-07T02:53:33.622Z',
      owner: 'admin',
    },
  },
  {
    fileID: '3',
    key: 3,
    fileName: 'borders_technician_override.ini',
    fileDescription: 'dot_com_generate_analyst.mp4',
    fileSize: 33,
    fileType: 'fileType 3',
    isFolder: false,
    uploadedAt: '2020-09-24T06:56:36.488Z',
    owner: 75655,
    parentID: 9190,
    fileExtension: 'torrent',
    fileURL: 'http://gonzalo.info',
    sourceTaskID: '49800-8881',
    department_id: 'department_id 3',
    permissions: {
      read: [],
      write: [],
      isOwner: false,
      isReadable: false,
      isWritable: false,
    },
    logs: {
      actionLog: 'Uploaded',
      updatedAt: '2019-11-07T02:53:33.622Z',
      owner: 'admin',
    },
  },
  {
    fileID: '4',
    key: 4,
    fileName: 'kids_tan.fnc',
    fileDescription: 'fantastic_wooden_chips_white.mp2',
    fileSize: 17,
    fileType: 'fileType 4',
    isFolder: false,
    uploadedAt: '2020-08-22T09:54:27.562Z',
    owner: 97921,
    parentID: 31320,
    fileExtension: 'sm',
    fileURL: 'https://morgan.name',
    sourceTaskID: '18227-4646',
    project_id: 8,
    department_id: 'department_id 4',
    permissions: {
      read: [],
      write: [],
      isOwner: false,
      isReadable: false,
      isWritable: false,
    },
    logs: {
      actionLog: 'Uploaded',
      updatedAt: '2019-11-07T02:53:33.622Z',
      owner: 'admin',
    },
  },
  {
    fileID: '5',
    key: 5,
    fileName: 'extranet_pants_face_to_face.snf',
    fileDescription: '3rd_generation_field_client_server.pdf',
    fileSize: 35,
    fileType: 'fileType 5',
    isFolder: true,
    uploadedAt: '2020-02-03T07:33:54.679Z',
    owner: 25893,
    parentID: 22292,
    fileExtension: 'mpg',
    fileURL: 'http://cortney.com',
    sourceTaskID: '04079-8051',
    department_id: 'department_id 5',
    permissions: {
      read: [],
      write: [],
      isOwner: false,
      isReadable: false,
      isWritable: false,
    },
    logs: {
      actionLog: 'Uploaded',
      updatedAt: '2019-11-07T02:53:33.622Z',
      owner: 'admin',
    },
  },
  {
    fileID: '6',
    key: 6,
    fileName: 'real_time_jbod.asc',
    fileDescription: 'generating.wav',
    fileSize: 94,
    fileType: 'fileType 6',
    isFolder: false,
    uploadedAt: '2020-09-11T23:12:41.339Z',
    owner: 73060,
    parentID: 18619,
    fileExtension: 'mb',
    fileURL: 'http://nova.biz',
    sourceTaskID: '63575-1821',
    project_id: 8,
    department_id: 'department_id 6',
    logs: {
      actionLog: 'Edited',
      updatedAt: '2019-11-07T02:53:33.622Z',
      owner: 'admin',
    },
  },
  {
    fileID: '7',
    key: 7,
    fileName: 'compress_model.msp',
    fileDescription: 'virtual_steel_artificial_intelligence.gif',
    fileSize: 71,
    fileType: 'fileType 7',
    isFolder: false,
    uploadedAt: '2020-01-13T20:43:29.776Z',
    owner: 95617,
    parentID: 59274,
    fileExtension: 'tcl',
    fileURL: 'http://sadye.biz',
    sourceTaskID: '76080',
    project_id: 8,
    department_id: 'department_id 7',
    permissions: {
      read: [],
      write: [],
      isOwner: false,
      isReadable: false,
      isWritable: false,
    },
    logs: {
      actionLog: 'Uploaded',
      updatedAt: '2019-11-07T02:53:33.622Z',
      owner: 'admin',
    },
  },
  {
    fileID: '8',
    key: 8,
    fileName: 'revolutionize_unbranded_programming.fdf',
    fileDescription: 'calculating_experiences.mpe',
    fileSize: 89,
    fileType: 'fileType 8',
    isFolder: true,
    uploadedAt: '2020-08-26T16:44:41.504Z',
    owner: 32025,
    parentID: 11753,
    fileExtension: 'pgn',
    fileURL: 'https://stanley.com',
    sourceTaskID: '87692-2093',
    project_id: 8,
    department_id: 'department_id 8',
    permissions: {
      read: [],
      write: [],
      isOwner: false,
      isReadable: false,
      isWritable: false,
    },
    logs: {
      actionLog: 'Deleted',
      updatedAt: '2019-11-07T02:53:33.622Z',
      owner: 'admin',
    },
  },
  {
    fileID: '9',
    key: 9,
    fileName: 'success.cil',
    fileDescription: 'kentucky_seamless_virtual.png',
    fileSize: 91,
    fileType: 'fileType 9',
    isFolder: false,
    uploadedAt: '2019-11-28T16:43:08.016Z',
    owner: 80150,
    parentID: 49332,
    fileExtension: 'hpid',
    fileURL: 'https://judson.info',
    sourceTaskID: '09406-7382',
    project_id: 8,
    department_id: 'department_id 9',
    permissions: {
      read: [],
      write: [],
      isOwner: false,
      isReadable: false,
      isWritable: false,
    },
    logs: {
      fileName: 'lari_credit_card_account.twd',
      actionLog: 'Uploaded',
      updatedAt: '2019-11-07T02:53:33.622Z',
      owner: 'admin',
    },
  },
  {
    fileID: '10',
    key: 10,
    fileName: 'south_georgia_and_the_south_sandwich_islands_holistic.spc',
    fileDescription: 'assistant_strategist_markets.jpg',
    fileSize: 14,
    fileType: 'folder',
    isFolder: true,
    uploadedAt: '2020-03-14T13:07:23.018Z',
    owner: 33926,
    parentID: 56949,
    fileExtension: 'tk',
    fileURL: 'https://daryl.org',
    sourceTaskID: '00298',
    project_id: 8,
    department_id: 'department_id 10',
    permissions: {
      read: [],
      write: [],
      isOwner: false,
      isReadable: false,
      isWritable: false,
    },
    logs: {
      actionLog: 'Deleted',
      updatedAt: '2019-11-07T02:53:33.622Z',
      owner: 'admin',
    },
  },
]

export default files
