import React from 'react'
import { TreeSelect, Select, Checkbox, Form, Dropdown, Menu, Button } from 'antd'
import {
  ColumnWidthOutlined,
  CaretDownOutlined,
  InsertRowLeftOutlined,
  ExportOutlined,
} from '@ant-design/icons'
import { filterString } from 'utils/utility'

const { Option } = Select

const treeData = [
  {
    title: 'Trạng thái',
    value: 'status',
    key: 'status',
  },
  {
    title: 'Tiến độ',
    value: 'progress',
    key: 'progress',
  },
  {
    title: 'Công việc',
    value: 'text',
    key: 'text',
    disableCheckbox: true,
  },
  {
    title: 'Bắt đầu dự kiến',
    value: 'target_start_date',
    key: 'target_start_date',
  },
  {
    title: 'Kết thúc thực tế',
    value: 'target_end_date',
    key: 'target_end_date',
  },
  {
    title: 'Thời gian dự kiến',
    value: 'calendar_target_duration',
    key: 'calendar_target_duration',
  },
  {
    title: 'Thời gian thực tế',
    value: 'calendar_duration',
    key: 'calendar_duration',
  },
  {
    title: 'Phòng ban giao việc',
    value: 'assignment_department',
    key: 'assignment_department',
  },
  {
    title: 'Phòng ban nhận việc',
    value: 'assigned_department',
    key: 'assigned_department',
  },
  {
    title: 'Người giao việc',
    value: 'assignment_person',
    key: 'assignment_person',
  },
  {
    title: 'Người nhận việc',
    value: 'assigned_person',
    key: 'assigned_person',
  },
  {
    title: 'Quan hệ công việc',
    value: 'relation_target',
    key: 'relation_target',
  },
]

const ganttToolbar = ({
  zoomLevel,
  zoomToFit,
  onSelectZoom,
  departments,
  users,
  isDisplayFilter,
  fullscreen,
  fullscreenMode,
  selectedDepartment,
  departmentState,
  selectedUser,
  onDisplayTargetTask,
  onDisplayCriticalPath,
  onFilterByUser,
  onFilterByDepartment,
  onFilterByCriticalPath,
  onlyCritical,
  onToggleVisibility,
  selectedLayout,
  exportFile,
  onToggleLayout,
  isTouchMode,
}) => {
  const [form] = Form.useForm()

  const changeDepartment = (value) => {
    form.setFieldsValue({ userId: null })
    onFilterByDepartment(value)
  }

  const handleToggleColumn = (value) => {
    onToggleVisibility(value)
  }

  const exportMenu = (
    <Menu>
      <Menu.Item>
        <a className="pointer" onClick={() => exportFile('pdf')}>
          PDF
        </a>
      </Menu.Item>
      <Menu.Item>
        <a className="pointer" onClick={() => exportFile('png')}>
          PNG
        </a>
      </Menu.Item>
      <Menu.Item>
        <a className="pointer" onClick={() => exportFile('excel')}>
          Excel
        </a>
      </Menu.Item>
    </Menu>
  )

  return (
    <ul className="gantt-controls">
      <li className="gantt-menu-item gantt-menu-item-right gantt-menu-item-last">
        <a onClick={selectedLayout !== 'task' ? zoomToFit : undefined}>
          <ColumnWidthOutlined />
          Phóng để vừa
        </a>
      </li>
      <li className="gantt-menu-item gantt-menu-item-right">
        <Checkbox onChange={onDisplayCriticalPath}>Critical path</Checkbox>
      </li>
      <li className="gantt-menu-item gantt-menu-item-right">
        <Checkbox {...(selectedLayout === 'task' && { disabled: true })} onChange={onDisplayTargetTask}>
          Kế hoạch
        </Checkbox>
      </li>
      <li className="gantt-menu-item zoom gantt-menu-item-right">
        <label>Zoom :</label>
        <Select
          size="large"
          defaultValue="weeks"
          onChange={onSelectZoom}
          className="zoom-option capitalize"
          bordered={false}
        >
          {zoomLevel.levels
            .filter((item) => !item.hidden)
            .map((item) => (
              <Option key={item.name} value={item.name} className="capitalize">
                {item.name}
              </Option>
            ))}
        </Select>
      </li>
      {isDisplayFilter && (
        <>
          <li className="gantt-menu-item gantt-menu-item-right">
            <Form form={form} initialValues={{ userId: selectedUser }}>
              <Form.Item name="userId">
                <Select
                  size="large"
                  onChange={onFilterByUser}
                  dropdownClassName="select-long-text"
                  className="zoom-option"
                  style={{ minWidth: 170 }}
                  bordered={false}
                  showSearch
                  optionFilterProp="full_name"
                  filterOption={(input, option) => filterString(input, option.children)}
                >
                  <Option value={null}>Tất cả nhân viên</Option>
                  {users
                    .filter((user) => !departmentState || user.department.id === departmentState)
                    .map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.full_name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Form>
          </li>
          <li className="gantt-menu-item gantt-menu-item-right">
            <Select
              size="large"
              defaultValue={selectedDepartment}
              style={{ minWidth: 170 }}
              dropdownClassName="select-long-text"
              onChange={(value) => changeDepartment(value)}
              className="zoom-option"
              bordered={false}
              showSearch
              optionFilterProp="name"
              filterOption={(input, option) => filterString(input, option.children)}
            >
              <Option value={0}>Tất cả phòng ban</Option>
              {departments.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </li>
        </>
      )}
      <li className="gantt-menu-item gantt-menu-item-right">
        <Checkbox defaultChecked={onlyCritical} onChange={onFilterByCriticalPath}>
          Lọc theo Critical Path
        </Checkbox>
      </li>
      {isTouchMode && (
        <li className="gantt-menu-item">
          <Select
            size="large"
            defaultValue={'default'}
            style={{ minWidth: 170 }}
            dropdownClassName="select-long-text"
            onChange={(value) => onToggleLayout(value)}
            className="zoom-option"
            bordered={false}
          >
            <Option value="default" key="default">
              Mặc định
            </Option>
            <Option value="gantt" key="gantt">
              Mở rộng Gantt
            </Option>
            <Option value="task" key="task">
              Mở rộng Task
            </Option>
          </Select>
        </li>
      )}

      <li className="gantt-menu-item gantt-menu-item-right gantt-column-control">
        <TreeSelect
          {...(selectedLayout === 'gantt' && { disabled: true })}
          onChange={handleToggleColumn}
          treeData={treeData}
          bordered={false}
          maxTagPlaceholder={(data) => (
            <div className="pointer">
              <CaretDownOutlined style={{ fontSize: '12px', padding: 0, margin: 0 }} />{' '}
              <InsertRowLeftOutlined />
            </div>
          )}
          defaultValue={[
            'status',
            'progress',
            'text',
            'target_start_date',
            'target_end_date',
            'calendar_target_duration',
            'calendar_duration',
            'assignment_department',
            'assigned_department',
            'assignment_person',
            'assigned_person',
            'relation_target',
          ]}
          maxTagCount={0}
          treeCheckable
          dropdownClassName="select-long-text"
          style={
            {
              // width: '50px',
            }
          }
        />
      </li>
      <li className="gantt-menu-item gantt-menu-item-right gantt-column-control">
        <Dropdown overlay={exportMenu} placement="bottomCenter">
          <Button icon={<ExportOutlined />} type="text">
            Export
          </Button>
        </Dropdown>
      </li>
    </ul>
  )
}

export default ganttToolbar
