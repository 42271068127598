const Endpoints = {
  fileUrl: process.env.REACT_APP_STORAGE_URL,
  login: '/auth/login',
  profile: '/auth/user',
  projectRole: '/auth/project',
  logout: '/auth/logout',
  projects: '/projects',
  projectUsers: '/project_users',
  workPackages: '/work_packages',
  workPackageLogs: '/work_package_logs',
  getUser: '/users',
  roles: '/roles',
  departments: '/departments',
  filestorages: '/file_storages',
  filefolders: '/file_folders',
  users: '/users',
  types: '/types',
  statuses: '/statuses',
  remoteUrl: '/remote_search',
  notification: '/notifications',
  calendar: '/department_calendars',
  system_calendar: '/system_calendars',
}

export default Endpoints
