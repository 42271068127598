import React from 'react'
import { List } from 'antd'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { priorityOpts } from 'configs/constants'

const trackingFields = [
  'subject',
  'description',
  'start_date',
  'target_start_date',
  'end_date',
  'target_end_date',
  'status_id',
  'priority_id',
  'supervisor_id',
  'assigned_to_id',
  'progress',
  'overview_tracking'
]

const ActivityLogTab = (props) => {
  const { activityLogs, statuses } = props
  const { t } = useTranslation()

  const renderLogDescription = (log, logIndex) => {
    const prevLog = logIndex === activityLogs.length - 1 ? null : activityLogs[logIndex + 1]

    if (log.activity_type === 'work_package_create') {
      return `Tạo công việc <span class="field-name">${log.subject}</span>`
    }
    if (log.activity_type === 'work_package_update' && prevLog) {
      let results = []
      if (log.related_work_package) {
        results.push(
          `<span class="ant-typography ant-typography-danger">Cập nhật liên quan đến công việc <span class="field-name">${log.related_work_package.subject}</span></span>`,
        )
      }
      for (const [key, value] of Object.entries(log)) {
        if (trackingFields.includes(key)) {
          if (value != prevLog[key]) {
            switch (key) {
              case 'progress':
                results.push(
                  `Cập nhật <span class="field-name">${t(
                    `work_package.${key}`,
                  )}</span> từ <span class="old-value">${
                    prevLog[key] * 100
                  }%</span> sang <span class="new-value">${value * 100}%</span>`,
                )
                break
              case 'overview_tracking':
                results.push(
                  `Cập nhật <span class="field-name">${t(
                    `work_package.${key}`,
                  )}</span> từ <span class="old-value">${
                    prevLog[key] ? 'có' : 'không'
                  }</span> sang <span class="new-value">${value ? 'có' : 'không'}</span>`,
                )
                break
              case 'supervisor_id':
                if (log.supervisor) {
                  results.push(
                    `${log.user.full_name} đã thêm <span class="field-name">${t(
                      `work_package.${key}`,
                    )}</span> <span class="new-value">${
                      log.supervisor.full_name
                    }</span> vào công việc <span class="field-name">${log.subject}</span>`,
                  )
                } else {
                  results.push(
                    `${log.user.full_name} xóa <span class="field-name">${t(
                      `work_package.${key}`,
                    )}</span> ra khỏi công việc <span class="field-name">${log.subject}</span>`,
                  )
                }
                break
              case 'assigned_to_id':
                if (log.assigned) {
                  results.push(
                    `${log.user.full_name} đã thêm <span class="field-name">${t(
                      `work_package.${key}`,
                    )}</span> <span class="new-value">${
                      log.assigned.full_name
                    }</span> vào công việc <span class="field-name">${log.subject}</span>`,
                  )
                } else {
                  results.push(
                    `${log.user.full_name} xóa <span class="field-name">${t(
                      `work_package.${key}`,
                    )}</span> ra khỏi công việc <span class="field-name">${log.subject}</span>`,
                  )
                }
                break
              case 'priority_id':
                results.push(
                  `Cập nhật <span class="field-name">${t(
                    `work_package.${key}`,
                  )}</span> từ <span class="old-value">${
                    priorityOpts[prevLog[key] - 1]?.label
                  }</span> sang <span class="new-value">${priorityOpts[log[key] - 1]?.label}</span>`,
                )
                break
              case 'status_id':
                results.push(
                  `Cập nhật <span class="field-name">${t(
                    `work_package.${key}`,
                  )}</span> từ <span class="old-value">${
                    statuses?.find((item) => item.id === prevLog.status_id)?.name
                  }</span> sang <span class="new-value">${
                    statuses?.find((item) => item.id === log.status_id)?.name
                  }</span>`,
                )
                break
              default:
                results.push(
                  `Cập nhật <span class="field-name">${t(
                    `work_package.${key}`,
                  )}</span> từ <span class="old-value">${
                    prevLog[key]
                  }</span> sang <span class="new-value">${value}</span>`,
                )
                break
            }
          }
        }
      }
      return results.join('\n')
    }
  }

  return (
    <div className="pad-bot-md">
      <List
        itemLayout="horizontal"
        dataSource={activityLogs}
        renderItem={(log, index) => (
          <List.Item>
            <List.Item.Meta
              title={
                <div>
                  {log.user.full_name}
                  <div className="pull-right ant-list-item-meta-description">
                    {moment(log.created_at).format('HH:mm DD/MM/YYYY')}
                  </div>
                </div>
              }
              description={
                <span
                  className="pre-line"
                  dangerouslySetInnerHTML={{ __html: renderLogDescription(log, index) }}
                ></span>
              }
            />
          </List.Item>
        )}
      />
    </div>
  )
}

export default ActivityLogTab
