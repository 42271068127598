import React from 'react'
import { Layout, Grid } from 'antd'
import { NavBar, SideBar, SideDrawer } from 'components'

const ProjectPage = (props) => {
  return (
    <Layout className="layout-view">
      <SideDrawer component={SideBar} />
      <Layout>
        <NavBar isDisplayProjectName className="navbar-dashboard" />
        <Layout className="layout-main">{props.children}</Layout>
      </Layout>
    </Layout>
  )
}

export default ProjectPage
