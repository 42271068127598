import React, { useEffect } from 'react'
import { Modal, Button, Typography, Descriptions, Row, Select, Col, Form, InputNumber } from 'antd'
import { projectPermission } from 'utils/permission'
import { linkTypeMapping } from '../Tasks'

const { Title } = Typography
const { Option } = Select

const formTailLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
}

const RelationModal = (props) => {
  const { data, onDelete, onCancel, visible, onEdit, projectId } = props
  const [editLinkForm] = Form.useForm()

  const permission = (actions) => {
    return projectPermission(actions, projectId)
  }

  const clearFormAndResetState = () => {
    onCancel()
    editLinkForm.resetFields()
  }

  const handleSubmitEditLink = async () => {
    try {
      const values = await editLinkForm.validateFields()
      onEdit({ ...data.data, ...values }, () => clearFormAndResetState())
    } catch (error) {
      Modal.error({
        title: 'Lỗi',
        content: error.message,
      })
    }
  }

  useEffect(() => {
    if (data.data) {
      editLinkForm.setFieldsValue({
        source: data.data.source,
        type: data.data.type,
        lag: data.data.lag || 0,
      })
    }
  }, [visible])

  return (
    <Modal
      title="Công việc phụ thuộc"
      className="modal-relation"
      visible={visible}
      okText="Delete"
      onOk={onDelete}
      onCancel={onCancel}
      maskClosable={false}
      footer={[
        ...(permission('relation:delete')
          ? [
              <Button key="delete" danger type="danger" onClick={onDelete} className="modal-button--delete">
                Xoá
              </Button>,
            ]
          : []),
        <Button key="cancel" onClick={onCancel} className="modal-button--cancel">
          Huỷ
        </Button>,
        ,
        ...(permission('relation:update')
          ? [
              <Button
                key="submit"
                type="primary"
                onClick={() => editLinkForm.submit()}
                className="modal-button--update"
              >
                Cập nhật
              </Button>,
            ]
          : []),
      ]}
    >
      <Descriptions.Item label="Công việc">
        <Title level={4}>{data.targetTask.text}</Title>
      </Descriptions.Item>

      <Form
        wrapperCol={{ span: 24 }}
        form={editLinkForm}
        onFinish={handleSubmitEditLink}
        className="pad-top-sm"
      >
        <Row gutter={[12, 12]}>
          <Col md={24}>
            <Form.Item
              {...formTailLayout}
              name="source"
              rules={[{ required: true, message: 'Vui lòng chọn công việc' }]}
              label="Công việc gốc"
            >
              <Select disabled style={{ width: '100%' }} placeholder="Chọn công việc">
                <Option
                  key={data.data.source}
                  value={data.data.source}
                  {...(data.data.source && { disabled: true })}
                >
                  {data.sourceTask.subject}
                </Option>
              </Select>
            </Form.Item>
          </Col>

          <Col md={24}>
            <Form.Item
              {...formTailLayout}
              name="type"
              rules={[{ required: true, message: 'Vui lòng chọn liên kết' }]}
              label="Loại liên kết"
            >
              <Select
                disabled
                style={{ width: '100%' }}
                placeholder="Chọn loại liên kết"
                rules={[{ required: true, message: 'Vui lòng chọn liên kết' }]}
              >
                {linkTypeMapping?.map(({ key, label }) => (
                  <Option key={key} value={key}>
                    {label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col md={24}>
            <Form.Item {...formTailLayout} name="lag" label="Trước(-) / Sau(+)">
              <InputNumber formatter={(value) => `${value} Ngày`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default RelationModal
