/**
 * Format file size by unit
 *
 * @param {number} bytes
 */
export function formatFileSize(bytes) {
  if (bytes < 1024) return bytes + ' Bytes'
  else if (bytes < 1048576) return (bytes / 1024).toFixed(3) + ' KB'
  else if (bytes < 1073741824) return (bytes / 1048576).toFixed(2) + ' MB'
  else return (bytes / 1073741824).toFixed(3) + ' GB'
}

/**
 * File system list
 *
 */

export const fileBlackList = [
  'db',
  'ini',
  'DS_Store',
  'desktop',
  'fseventsd',
  'bin',
  'Trashes',
  'sys',
  'hidden',
  'backupdb',
  'Spotlight-V100',
  'DocumentRevisions-V100',
  'MobileBackups',
  'bak',
  'AppleDouble',
  'LSOverride',
  'Rhistory',
  'cdv',
  'build',
  'git',
  'svn',
  'tox',
  'in',
  'el',
]
