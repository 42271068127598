import React, { useEffect, useState } from 'react'
import { Modal, Form, Input, InputNumber, DatePicker, Upload, Button } from 'antd'
import moment from 'moment'

const { TextArea } = Input

const formItemLayout = {
  labelCol: {
    xs: { span: 22 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 22 },
    sm: { span: 14 },
  },
}

const ProjectForm = (props) => {
  const { project } = props
  const [form] = Form.useForm(null)
  const [image, setImage] = useState([])

  useEffect(() => {
    form.resetFields()
    if (props.visible && project) {
      form.setFieldsValue({
        name: project.name,
        description: project.description,
        start_date: moment(project.start_date),
        end_date: moment(project.end_date),
        is_public: project.is_public,
        target_budget: project.target_budget,
        actual_budget: project.actual_budget,
      })
    }
    setImage([])
  }, [props.visible])

  const uploadProps = {
    multiple: false,
    accept: 'image/*',
    onRemove: () => {
      setImage([])
    },
    beforeUpload: (file) => {
      setImage([file])
      return false
    },
    fileList: image,
  }

  const onFinish = (value) => {
    value.id = project?.id
    value.image = image.length > 0 ? image[0] : null
    value.is_public = value.is_public ? 1 : 0
    value.start_date = value.start_date ? moment(value.start_date).format('YYYY-MM-DD') : null
    value.end_date = value.end_date ? moment(value.end_date).format('YYYY-MM-DD') : null
    props.onSubmit(value)
  }

  return (
    <Modal
      title={project?.id ? 'Cập nhật dự án' : 'Thêm mới dự án'}
      okButtonProps={{
        form: 'project-form',
        key: 'submit',
        htmlType: 'submit',
        loading: props.saving,
      }}
      maskClosable={false}
      visible={props.visible}
      onCancel={props.onCancel}
      width={600}
      okText="Lưu"
      cancelText="Hủy bỏ"
    >
      <Form id="project-form" form={form} name="dynamic_rule" onFinish={onFinish}>
        <Form.Item
          {...formItemLayout}
          name="name"
          label="Tên dự án"
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập tên dự án',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item {...formItemLayout} name="description" label="Mô tả">
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          label="Ngày bắt đầu"
          name="start_date"
          rules={[{ required: true, message: 'Please select' }]}
        >
          <DatePicker placeholder="" />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          label="Ngày kết thúc"
          name="end_date"
          rules={[{ required: true, message: 'Please select' }]}
        >
          <DatePicker placeholder="" />
        </Form.Item>
        <Form.Item {...formItemLayout} label="Ngân sách kế hoạch" name="target_budget">
          <InputNumber
            style={{ width: 135 }}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          />
        </Form.Item>
        <Form.Item {...formItemLayout} label="Ngân sách thực tế" name="actual_budget">
          <InputNumber
            style={{ width: 135 }}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          />
        </Form.Item>
        {/* <Form.Item {...formTailLayout} name="is_public" valuePropName="checked">
          <Checkbox>Công khai</Checkbox>
        </Form.Item> */}
        {/* <Form.Item >
          <Upload {...uploadProps}>
            <Button>
              Chọn hình ảnh
            </Button>
          </Upload>
        </Form.Item> */}
      </Form>
    </Modal>
  )
}

export default ProjectForm
