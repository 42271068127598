import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { Input, Radio, Form, Select, DatePicker, Row, Col, InputNumber, Upload, Checkbox } from 'antd'
import { InboxOutlined } from 'assets/icon'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import gantt from 'dhtmlx-gantt/codebase/dhtmlxgantt'

import { STATUS_NEW, priorityOpts, STATUS_DONE, STATUS_CLOSE } from 'configs/constants'
import endPoints from 'configs/endPoints'
import './style.scss'

const { TextArea } = Input
const { Option } = Select

const formItemLayout = {
  wrapperCol: { span: 24 },
}

export default function GeneralTab(props) {
  const calendar = gantt.getCalendar(props.taskData.calendar_id)
  const { masterData, taskData, form, handleSubmitForm, generalPermission, authenticatedUser } = props
  const [status, setStatus] = useState(taskData.status_id || STATUS_NEW)
  const [isUploading, setIsUploading] = useState(0)

  const parent = { ...taskData._parent }
  useEffect(() => {
    if (!isEmpty(taskData)) {
      /* when create new task, if the parent's start date is before current date, set start date = current date
       * otherwise, set start date = parent's start date
       */
      let startDateNewTask = moment(
        moment(taskData.start_date).isBefore(moment())
          ? moment(getClosestWorkTime(moment().toDate(), 'past').format('YYYY-MM-DD 00:00:00'))
          : taskData.start_date,
      )
      /* if the date is not between parent's deadline, set start_date parent's start_date as default */
      if (!moment(startDateNewTask).isBetween(parent.target_start_date, parent.target_end_date)) {
        startDateNewTask = getClosestWorkTime(parent.target_start_date, 'past')
      }
      const filteredType = masterData.types?.filter(({ name }) => name.toLowerCase() === taskData.type) || []
      form.setFieldsValue({
        text: taskData.text,
        type_id: filteredType[0]?.id || 1,
        start_date: taskData.$new ? startDateNewTask : moment(taskData?.start_date),
        target_start_date: taskData.$new ? startDateNewTask : moment(taskData?.target_start_date),
        end_date: taskData.$new ? startDateNewTask : moment(taskData?.end_date).add(-1, 'days'),
        target_end_date: taskData.$new ? startDateNewTask : moment(taskData?.target_end_date).add(-1, 'days'),
        calendar_duration: taskData.calendar_duration ?? 1,
        calendar_target_duration: taskData.calendar_target_duration ?? 1,
        ...(taskData.is_root
          ? { duration: taskData.duration, target_duration: taskData.target_duration }
          : null),
        description: taskData.description,
        status_id: taskData.status_id || STATUS_NEW,
        priority_id: taskData.priority_id || 2,
        progress: Math.round(taskData.progress * 100) || 0,
        supervisor_id: taskData.supervisor_id,
        assignment_id: taskData.$new ? authenticatedUser.id : taskData.assignment_id,
        assigned_to_id: taskData.assigned_to_id,
        responsible_ids: taskData.responsible_ids ?? [],
        overview_tracking: taskData.overview_tracking ?? false,
        files: taskData.work_package_files?.map((item) => ({
          ...item,
          uid: item.id,
          name: item.name,
          url: item.full_url,
        })),
      })
    } else form.resetFields()
  }, [taskData, taskData.start_date, taskData.end_date])

  const validationDoneStatus = (_, value) => {
    if (
      taskData.type === 'project' &&
      !taskData.allowDone &&
      (value === STATUS_DONE || value === STATUS_CLOSE)
    )
      return Promise.reject('Vui lòng hoàn thành hết công việc')
    else return Promise.resolve()
  }

  const onChangeTargetDuration = (value) => {
    const parentEndDate = moment(parent.target_end_date).add(-1, 'days')
    /* when create task, update the actual date equal to planned date */

    let targetEndDate = moment(
      calendar.calculateEndDate({
        start_date: form.getFieldValue('target_start_date').toDate(),
        duration: value,
      }),
    ).add(-1, 'days')
    if (targetEndDate.isAfter(parentEndDate, 'day')) {
      targetEndDate = parentEndDate

      const start = getClosestWorkTime(form.getFieldValue('target_start_date'), 'future')
      const end = getClosestWorkTime(targetEndDate.toDate(), 'past')

      const targetDuration =
        calendar.calculateDuration({
          start_date: start.toDate(),
          end_date: end.toDate(),
        }) + 1

      value = targetDuration
    }
    if (taskData.$new) {
      form.setFieldsValue({
        target_end_date: targetEndDate,
        end_date: targetEndDate,
        calendar_target_duration: value,
        calendar_duration: value,
      })
    } else {
      form.setFieldsValue({
        target_end_date: targetEndDate,
        calendar_target_duration: value,
      })
    }
  }

  const onChangeTargetStartDate = (value) => {
    let targetEndDate = value.isAfter(form.getFieldValue('target_end_date'))
      ? value
      : form.getFieldValue('target_end_date')

    /*
     * the gantt's duration configuration doesn't work as expected,
     * so we need to move the start date to working date before the calculation
     * */

    const start = getClosestWorkTime(value, 'future')
    const end = getClosestWorkTime(form.getFieldValue('target_end_date'), 'past')
    const targetDuration =
      calendar.calculateDuration({
        start_date: start.toDate(),
        end_date: end.toDate(),
      }) + 1

    /* if there is no working date between start date and end date,
     * move end date to the first working date automatically
     */
    if (targetDuration === 1) {
      const nextWorkingDate = getClosestWorkTime(start.toDate(), 'future')

      if (targetEndDate < nextWorkingDate) {
        targetEndDate = nextWorkingDate
      }
    }

    form.setFieldsValue({
      target_end_date: targetEndDate,
      calendar_target_duration: targetDuration,
      ...(taskData.$new
        ? {
            end_date: targetEndDate,
            start_date: form.getFieldValue('target_start_date'),
            calendar_duration: targetDuration,
          }
        : null),
    })
  }

  const onChangeTargetEndDate = (value) => {
    /*
     * the gantt's duration configuration doesn't work as expected,
     * so we need to move the start date to working date before the calculation
     * */
    const start = getClosestWorkTime(form.getFieldValue('target_start_date'), 'future')
    const end = getClosestWorkTime(value, 'past')
    const targetDuration =
      calendar.calculateDuration({
        start_date: start.toDate(),
        end_date: end.toDate(),
      }) + 1

    form.setFieldsValue({
      calendar_target_duration: targetDuration,
      ...(taskData.$new
        ? {
            end_date: value,
            start_date: form.getFieldValue('target_start_date'),
            calendar_duration: targetDuration,
          }
        : null),
    })
  }

  const onChangeDuration = (value) => {
    form.setFieldsValue({
      end_date: moment(
        calendar.calculateEndDate({
          start_date: form.getFieldValue('start_date').toDate(),
          duration: value,
        }),
      ).add(-1, 'days'),
    })
  }

  const onChangeStartDate = (value) => {
    let endDate = value.isAfter(form.getFieldValue('end_date')) ? value : form.getFieldValue('end_date')
    /*
     * the gantt's duration configuration doesn't work as expected,
     * so we need to move the start date to working date before the calculation
     * */
    const start = getClosestWorkTime(value, 'future')
    const end = getClosestWorkTime(form.getFieldValue('end_date'), 'past')

    const duration =
      calendar.calculateDuration({
        start_date: start.toDate(),
        end_date: end.toDate(),
      }) + 1

    /* if there is no working date between start date and end date,
     * move end date to the first working date automatically
     */
    if (duration === 1) {
      const nextWorkingDate = getClosestWorkTime(start.toDate(), 'future')

      if (endDate < nextWorkingDate) {
        endDate = nextWorkingDate
      }
    }

    form.setFieldsValue({
      end_date: endDate,
      calendar_duration: duration,
    })
  }

  const onChangeEndDate = (value) => {
    /*
     * the gantt's duration configuration doesn't work as expected,
     * so we need to move the start date to working date before the calculation
     * */
    const start = getClosestWorkTime(form.getFieldValue('start_date'), 'future')
    const end = getClosestWorkTime(value, 'past')

    const duration =
      calendar.calculateDuration({
        start_date: start.toDate(),
        end_date: end.toDate(),
      }) + 1

    form.setFieldsValue({ calendar_duration: duration })
  }

  const getClosestWorkTime = (date, dir) => {
    let result = moment(calendar.getClosestWorkTime({ date: moment(date).toDate(), dir: dir }))
    if (!calendar.isWorkTime(result.toDate())) {
      result = dir === 'past' ? result.add(-1, 'days') : result.add(1, 'days')
      return getClosestWorkTime(result, dir)
    }
    return result
  }

  const selectedStatus = masterData.statuses.find((item) => item.id === status)

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  const uploadProps = {
    multiple: true,
    // accept: 'image/*',
    onRemove: (file) => {
      if (file) setIsUploading((prevIsUploading) => (prevIsUploading - 1 >= 0 ? prevIsUploading - 1 : 0))
    },
    beforeUpload: (file, fileList) => {
      if (file) setIsUploading((prevIsUploading) => prevIsUploading + 1)
      return false
    },
  }

  return (
    <>
      <Form name="validate_other" {...formItemLayout} form={form} onFinish={handleSubmitForm}>
        <Row gutter={24}>
          <Col span={16}>
            <Form.Item
              label="Công việc"
              name="text"
              rules={[{ required: true, message: 'Vui lòng nhập tên công việc' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="status_id"
              label="Trạng thái"
              rules={[
                {
                  validator: validationDoneStatus,
                },
                { required: true, message: 'Please select' },
              ]}
            >
              <Select className={`select-status ${selectedStatus?.slug}`} onChange={setStatus}>
                {masterData.statuses?.map(({ id, name }) => (
                  <Option key={id} value={id}>
                    {name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name="description" label="Mô tả">
          <TextArea rows={4} />
        </Form.Item>
        <div className="divider">
          Kế hoạch
          <div className="divider-line" />
        </div>
        <Row gutter={12}>
          <Col span={7}>
            <Form.Item
              name="target_start_date"
              label="Bắt đầu"
              rules={[{ required: true, message: 'Vui lòng nhập ngày' }]}
            >
              <DatePicker
                format="DD-MM-YYYY"
                onChange={onChangeTargetStartDate}
                allowClear={false}
                inputReadOnly
                // disabled={taskData.type === 'project'}
                disabled={
                  taskData.is_root ||
                  (!taskData.$new && !generalPermission('deadline')) ||
                  (!taskData.$new && isUploading == 0)
                }
                style={{ width: '100%' }}
                placeholder="Bắt đầu"
                disabledDate={(date) => date >= parent.target_end_date}
              />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              name="target_end_date"
              label="Hạn chót"
              rules={[{ required: true, message: 'Vui lòng nhập ngày' }]}
            >
              <DatePicker
                format="DD-MM-YYYY"
                onChange={onChangeTargetEndDate}
                allowClear={false}
                inputReadOnly
                // disabled={taskData.type === 'project'}
                disabled={
                  taskData.is_root ||
                  (!taskData.$new && !generalPermission('deadline')) ||
                  (!taskData.$new && isUploading == 0)
                }
                style={{ width: '100%' }}
                placeholder="Hạn chót"
                disabledDate={(date) =>
                  date < form.getFieldValue('target_start_date') ||
                  date > parent.target_end_date ||
                  (!calendar.isWorkTime(form.getFieldValue('target_start_date').toDate()) &&
                    date < getClosestWorkTime(form.getFieldValue('target_start_date'), 'future'))
                }
              />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              name={taskData.is_root ? 'target_duration' : 'calendar_target_duration'}
              label="Thời lượng dự kiến (ngày)"
              className="pull-right"
            >
              <InputNumber
                // defaultValue={100}
                // disabled={taskData.type === 'project'}
                disabled={
                  taskData.is_root ||
                  (!taskData.$new && !generalPermission('deadline')) ||
                  (!taskData.$new && isUploading == 0)
                }
                min={1}
                step={1}
                // max={100}
                onChange={onChangeTargetDuration}
                // formatter={(value) => `${value}ngày`}
                // parser={(value) => value.replace('ngày', '')}
              />
            </Form.Item>
          </Col>
        </Row>
        <div className="divider">
          Thực tế
          <div className="divider-line" />
        </div>
        <Row gutter={12}>
          <Col span={7}>
            <Form.Item
              name="start_date"
              label="Bắt đầu"
              rules={[{ required: true, message: 'Vui lòng nhập ngày' }]}
            >
              <DatePicker
                format="DD-MM-YYYY"
                onChange={onChangeStartDate}
                allowClear={false}
                inputReadOnly
                disabled={taskData.type === 'project'}
                style={{ width: '100%' }}
                placeholder="Bắt đầu"
              />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              name="end_date"
              label="Kết thúc"
              rules={[{ required: true, message: 'Vui lòng nhập ngày' }]}
            >
              <DatePicker
                format="DD-MM-YYYY"
                onChange={onChangeEndDate}
                allowClear={false}
                inputReadOnly
                disabled={taskData.type === 'project'}
                style={{ width: '100%' }}
                placeholder="Kết thúc"
                disabledDate={(date) =>
                  date < form.getFieldValue('start_date') ||
                  (!calendar.isWorkTime(form.getFieldValue('start_date').toDate()) &&
                    date < getClosestWorkTime(form.getFieldValue('start_date'), 'future'))
                }
              />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              name={taskData.is_root ? 'duration' : 'calendar_duration'}
              label="Thời gian thực tế (ngày)"
              className="pull-right"
            >
              <InputNumber
                // defaultValue={100}
                disabled={taskData.type === 'project'}
                min={1}
                step={1}
                // max={100}
                onChange={onChangeDuration}
                // formatter={(value) => `${value}ngày`}
                // parser={(value) => value.replace('ngày', '')}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={17}>
            <Form.Item name="priority_id" label="Mức độ ưu tiên">
              <Radio.Group>
                {priorityOpts.map(({ label, key }) => (
                  <Radio key={key} value={key}>
                    {label}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item name="progress" label="Tiến độ" className="pull-right">
              <InputNumber
                // defaultValue={100}
                disabled
                min={0}
                step={10}
                max={100}
                formatter={(value) => `${value}%`}
                parser={(value) => value.replace('%', '')}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="overview_tracking" label="Theo dõi qua Overview" valuePropName="checked">
              <Checkbox />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span="24">
            <div className="pad-vertical-sm">
              <Form.Item>
                <Form.Item name="files" valuePropName="fileList" getValueFromEvent={normFile} noStyle>
                  <Upload.Dragger name="file" {...uploadProps}>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Bấm để chọn tập tin</p>
                  </Upload.Dragger>
                </Form.Item>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
    </>
  )
}

const DateTimeType = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string,
  PropTypes.instanceOf(moment),
  PropTypes.instanceOf(Date),
])

GeneralTab.propTypes = {
  taskData: PropTypes.shape({
    $new: PropTypes.bool,
    _parent: PropTypes.any,
    allowDone: PropTypes.bool,
    assigned_to_id: PropTypes.any,
    assignment_id: PropTypes.any,
    description: PropTypes.string,
    calendar_duration: PropTypes.number,
    end_date: DateTimeType,
    is_root: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    priority_id: PropTypes.number,
    progress: PropTypes.number,
    responsible_ids: PropTypes.array,
    start_date: DateTimeType,
    status_id: PropTypes.number,
    supervisor_id: PropTypes.any,
    calendar_target_duration: PropTypes.number,
    target_end_date: DateTimeType,
    target_start_date: DateTimeType,
    text: PropTypes.string,
    type: PropTypes.string,
  }),
}
