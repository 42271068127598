import React from 'react'
import { Upload } from 'antd'
import { MailOutlined, UserOutlined, PhoneOutlined, SolutionOutlined } from '@ant-design/icons'
import { getCookie } from 'store/actions/auth.action'
import endPoints from 'configs/endPoints'

const UserInfo = ({ currentUser, uploadAvatar }) => (
  <div>
    <div className={'avatar-holder'}>
      {/* <div className={'avatar-icon'}> */}
      <Upload
        name="image"
        listType="picture-card"
        className="avatar-upload"
        showUploadList={false}
        action={`${process.env.REACT_APP_BASE_API}/users/upload_avatar`}
        headers={{ Authorization: `Bearer ${getCookie('token')}` }}
        onChange={uploadAvatar}
      >
        {currentUser.image ? (
          <img src={`${endPoints.fileUrl}/${currentUser.image}`} alt="avatar" />
        ) : (
          <UserOutlined style={{ fontSize: 80 }} />
        )}
      </Upload>
      {/* </div> */}
      <div className={'name'}>{currentUser.full_name}</div>
      <div>{currentUser.username}</div>
    </div>
    <div className={'detail'}>
      <p>
        <MailOutlined
          style={{
            marginRight: 8,
            marginBottom: 10,
          }}
        />
        {currentUser.email}
      </p>
      <p>
        <PhoneOutlined
          style={{
            marginRight: 8,
            marginBottom: 10,
          }}
        />
        {currentUser.phone}
      </p>
      <p>
        <SolutionOutlined
          style={{
            marginRight: 8,
            marginBottom: 10,
          }}
        />
        {currentUser.department?.name}
      </p>
    </div>
  </div>
)

export default UserInfo
