import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
  windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
  iosPlatforms = ['iPhone', 'iPad', 'iPod']

function useWindowSize() {
  const location = useLocation()

  const [windowSize, setWindowSize] = useState({
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
  })

  let timerBrowserResize

  function updateWindowSize() {
    clearTimeout(timerBrowserResize)
    timerBrowserResize = setTimeout(function () {
      setWindowSize({ windowWidth: window.innerWidth, windowHeight: window.innerHeight })
    }, 200)
  }

  const os = getOS()

  function getOS() {
    let userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      deviceOS = ''

    if (macosPlatforms.indexOf(platform) !== -1) {
      deviceOS = 'Mac OS'
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      deviceOS = 'iOS'
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      deviceOS = 'Windows'
    } else if (/Android/.test(userAgent)) {
      deviceOS = 'Android'
    } else if (!os && /Linux/.test(platform)) {
      deviceOS = 'Linux'
    }

    return deviceOS.replace(/\s+/g, '-').toLowerCase()
  }

  useEffect(() => {
    window.addEventListener('resize', updateWindowSize)
    let pathname = location.pathname.split('/')[1]
    if (pathname == 'projects') {
      pathname += '-' + location.pathname.split('/')[3]
    }
    if (os) document.body.className = `${os} ${pathname}`
    // if (os) document.body.className = `${os}`
    return () => {
      window.removeEventListener('resize', updateWindowSize)
      document.body.classList.remove(pathname)
    }
  }, [])

  return { ...windowSize, os }
}

export default useWindowSize
