import React from 'react'
import { Button } from 'antd'

import permission from 'utils/permission'

const tableAction = (props) => {
  const tablePermission = props.permission??permission
  return (
    <span>
      {tablePermission(`${props.roleName}:update`) && (
        <Button type="primary" size="small" onClick={() => props.onEdit(props.item)}>
          Sửa
        </Button>
      )}

      {tablePermission(`${props.roleName}:delete`) && (
        <Button type="primary" style={{ marginLeft: 15 }} size="small" danger onClick={() => props.onDelete(props.item.id)}>
          Xoá
        </Button>
      )}
    </span>
  )
}

export default tableAction
