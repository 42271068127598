export const STATUS_DONE = 4
export const STATUS_NEW = 1
export const STATUS_CLOSE = 5
export const ADMIN_ROLE_ID = 1
export const USER_ROLE_ID = 2

export const priorityOpts = [
  { key: 1, label: 'Cao' },
  { key: 2, label: 'Bình Thường' },
  { key: 3, label: 'Thấp' },
]
