import React, { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import { useSelector, useDispatch } from 'react-redux'

import * as notificationActions from 'store/actions/notification.action'
import { AppRoutes } from 'routes'
import messages from './locales/messages'
import './App.less'

const language = 'en'

function App() {
  const { isAuthenticated } = useSelector((state) => state.authState)
  const dispatch = useDispatch()
  const getUnreadCounts = () => dispatch(notificationActions.getUnreadCounts())

  useEffect(() => {
    if (isAuthenticated) {
      getUnreadCounts()
    }
  }, [isAuthenticated])

  return (
    <IntlProvider locale={language} messages={messages[language]}>
      <BrowserRouter basename="/">
        <AppRoutes />
      </BrowserRouter>
    </IntlProvider>
  )
}

export default App
