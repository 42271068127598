import * as actionTypes from 'store/types/notification.action.types'

const initialState = {
  notifications: [],
  unreadCount: 0,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_NOTIFICATION:
      return { ...state, notifications: action.payload }
    case actionTypes.FETCH_UNREADCOUNT:
      return { ...state, unreadCount: action.payload }
    default:
      return state
  }
}

export default reducer
