import * as actionTypes from 'store/types/project_user.action.types'

const initialState = {
  projectUsers: [],
  total: 0,
}

const reducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case actionTypes.FETCH_PROJECT_USER:
      return { ...state, projectUsers: payload.data, total: payload.total }

    default:
      return state
  }
}

export default reducer
