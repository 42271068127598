import React, { useEffect, useState } from 'react'
import { Form, Input, Tree } from 'antd'

import { Modal } from 'components/Common'

const formItemLayout = {
  labelCol: {
    xs: { span: 22 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 22 },
    sm: { span: 16 },
  },
}

const RoleForm = (props) => {
  const [form] = Form.useForm()
  const { data, onSubmit, treeData } = props
  const [checkedKeys, setCheckedKeys] = useState([])

  useEffect(() => {
    if (props.visible) {
      form.resetFields()
      getSelectedKeys()
      if (data) {
        form.setFieldsValue({
          name: data.name,
          description: data.description,
          permissions: data.permissions ?? [],
        })
      }
    }
  }, [props.visible])

  const onFinish = (formData) => {
    const role_permissions = []
    checkedKeys.forEach((roleKey) => {
      const role = getNode(treeData, roleKey)
      if (role && role.parent) {
        const roleIndex = role_permissions.findIndex((item) => item.slug === role.parent)
        if (roleIndex !== -1) {
          role_permissions[roleIndex].permission += role.permission
        } else {
          role_permissions.push({ ...role, slug: role.parent })
        }
      }
    })
    onSubmit({
      ...data,
      ...formData,
      role_permissions,
    })
  }

  const getNode = (tree, name) => {
    for (let i = 0; i < tree.length; i++) {
      const item = tree[i]
      if (item.key === name && item.parent) {
        return item
      }
      if (item.children) {
        const res = getNode(item.children, name)
        if (res) {
          return res
        }
      }
    }
    return
  }

  const getSelectedKeys = () => {
    const checkedKeys = []
    if (data?.role_permissions) {
      const permissionValues = [1, 2, 4, 8, 16, 32, 64]
      data.role_permissions.forEach((role) => {
        permissionValues.forEach((value) => {
          if (role.permission & value) {
            checkedKeys.push(`${role.slug}-${value}`)
          }
        })
      })
    }
    setCheckedKeys(checkedKeys)
  }

  return (
    <Modal
      title={data?.id ? data.name : 'Thêm vai trò'}
      formName="role-form"
      loading={props.loading}
      visible={props.visible}
      onCancel={props.onCancel}
    >
      <Form id="role-form" form={form} name="dynamic_rule" onFinish={onFinish}>
        <Form.Item
          {...formItemLayout}
          name="name"
          label="Tên"
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập tên',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item {...formItemLayout} name="description" label="Mô tả">
          <Input.TextArea rows={3} />
        </Form.Item>
        <Form.Item {...formItemLayout} name="permissions" label="Phân quyền">
          <Tree checkable onCheck={(value) => setCheckedKeys(value)} checkedKeys={checkedKeys} treeData={treeData} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default RoleForm
