import React, { useEffect } from 'react'
import { Form, Input } from 'antd'

import {Modal} from 'components/Common'

const formItemLayout = {
  labelCol: {
    xs: { span: 22 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 22 },
    sm: { span: 16 },
  },
}

const DepartmentForm = (props) => {
  const [form] = Form.useForm()
  const { data, onSubmit } = props

  useEffect(() => {
    if (props.visible) {
      form.resetFields()
      if (data) {
        form.setFieldsValue({
          name: data.name,
        })
      }
    }
  }, [props.visible])

  const onFinish = (formData) => {
    onSubmit({
      ...data,
      ...formData
    })
  }
  return (
    <Modal
      title={data?.id ? data.name : 'Thêm phòng ban'}
      formName='department-form'
      loading={props.loading}
      visible={props.visible}
      onCancel={props.onCancel}
    >
      <Form id="department-form" form={form} name="dynamic_rule" onFinish={onFinish}>
        <Form.Item
          {...formItemLayout}
          name="name"
          label="Tên phòng ban"
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập tên',
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default DepartmentForm
