import React from 'react'
import { Form, Input, Button } from 'antd'

const formItemLayout = {
  labelCol: {
    xs: { span: 20 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 18 },
    sm: { span: 14 },
  },
}

const tailLayout = {
  wrapperCol: { offset: 5, span: 16 },
}

const ChangePasswordForm = ({ onSubmit }) => {
  const [form] = Form.useForm(null)

  const onFinish = (formData) => {
    onSubmit(formData, () => {
      form.resetFields()
    })
  }

  return (
    <Form
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      layout="horizontal"
      id="password-form"
      form={form}
      name="dynamic_rule"
      onFinish={onFinish}
    >
      <Form.Item
        {...formItemLayout}
        name="old_password"
        label="Mật khẩu hiện tại"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập mật khẩu hiện tại',
          },
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="password"
        label="Mật khẩu mới"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập mật khẩu mới',
          },
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="password_confirmation"
        label="Mật khẩu xác nhận"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập mật khẩu xác nhận',
          },
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit">
          Lưu
        </Button>
      </Form.Item>
    </Form>
  )
}
export default ChangePasswordForm
