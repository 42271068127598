import React from 'react'
import { Card, Row, Col, Button, Space, Menu, Dropdown } from 'antd'
import { FileOutlined, PlusOutlined, FolderAddOutlined, FolderOpenFilled } from 'assets/icon'

function FileActionPanel({ onAdd, onCreateFolder, roleName, permission }) {
  const isShowPanel = onAdd

  return isShowPanel ? (
    <Card className="action-panel">
      <Row gutter={[20, 20]} style={{ margin: 0 }} align="middle">
        <Col flex="1 1 auto" xs={24} style={{ padding: 0 }}>
          <Space size="middle">
            <Dropdown
              disabled={!permission(`${roleName}:create`)}
              overlay={
                <Menu>
                  <Menu.Item key="0" onClick={() => onAdd(false)}>
                    <FileOutlined style={{ fontSize: 18 }} /> Upload tập tin
                  </Menu.Item>
                  <Menu.Divider />
                  <Menu.Item key="1" onClick={() => onAdd(true)}>
                    <FolderOpenFilled style={{ fontSize: 18 }} /> Upload thư mục
                  </Menu.Item>
                </Menu>
              }
              trigger={['click']}
            >
              <Button type="primary" icon={<PlusOutlined />}>
                Upload
              </Button>
            </Dropdown>
          </Space>
        </Col>
        <Col flex=" 0 0 auto">
          {onCreateFolder && (
            <Button disabled={!permission(`${roleName}:create`)} type="link" onClick={onCreateFolder}>
              <FolderAddOutlined style={{ fontSize: '2rem', color: '#545456' }} />
            </Button>
          )}
        </Col>
      </Row>
    </Card>
  ) : null
}

export default FileActionPanel
