import React from 'react'
import { Layout } from 'antd'
import { NavBar } from 'components'

import './BasePage.scss'

const { Content, Footer } = Layout

const BasePage = (props) => {
  const PageLayout = ({ content }) => {
    return (
      <Layout>
        <NavBar showLogo logoClassName="bg-white" />
        <Content className="layout-content">{content}</Content>
        <Footer style={{ textAlign: 'center' }}>TIEN PHAT CORP ©2020</Footer>
      </Layout>
    )
  }

  return (
    <Layout className="wrapper">
      {props.routePath === '/login' ? (
        <Layout>{props.children}</Layout>
      ) : (
        <PageLayout content={props.children} />
      )}
    </Layout>
  )
}

export default BasePage
