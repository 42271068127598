import * as actionTypes from 'store/types/filestorage.action.types'

const initialState = {
  filestorages: [],
  userpermissions: null,
  filefolders: [],
  uploadprogress: 0,
  total: 0,
}

const reducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case actionTypes.FETCH_FILESTORAGE:
      return { ...state, filestorages: payload.data, total: payload.total }
    case actionTypes.FETCH_FILESTORAGEFOLDER:
      return { ...state, filefolders: payload.data }
    case actionTypes.FETCH_USERPERMISSION:
      return { ...state, userpermissions: payload?.data }
    case actionTypes.FETCH_UPLOADPROGRESS:
      return { ...state, uploadprogress: payload }

    default:
      return state
  }
}

export default reducer
