import React from 'react'
import moment from 'moment'
import { formatFileSize } from 'utils'
import { Button, Space } from 'antd'
import {
  FolderFilled,
  FileOutlined,
  EditOutlined,
  DeleteOutlined,
  LineOutlined,
  UserAddOutlined,
  DownloadOutlined,
  HistoryOutlined,
} from 'assets/icon'
import TableFile from './TableFile'
import { isAdmin } from 'utils/permission'

function FileList(props) {
  const {
    isSelectedProject,
    isSelectedDepartment,
    fileList,
    isLoading,
    onAdd,
    onCreateFolder,
    selectedProject,
    handleFileButton,
    handleFolderButton,
    onShowFileLog,
    setSelectedFolderData,
    breadscrumb,
    currentUser,
    roleName,
    permission,
    isEditable,
  } = props

  const columns = [
    {
      title: '',
      dataIndex: 'extension',
      width: 40,
      render: (extension, row) => {
        return !row.is_folder ? (
          <FileOutlined style={{ fontSize: 28 }} twoToneColor="#52c41a" />
        ) : (
          <FolderFilled style={{ fontSize: 28 }} twoToneColor="#52c41a" />
        )
      },
      align: 'left',
    },
    {
      title: 'Tên',
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => {
        return <div className="--truncated">{name || <LineOutlined />}</div>
      },
    },
    {
      title: 'Owner',
      dataIndex: 'owner',
      key: 'owner',
      width: 120,
      align: 'center',
      fixed: 'center',
      render: (owner) => {
        return owner?.full_name
      },
    },
    {
      title: 'Dung lượng',
      dataIndex: 'size',
      key: 'size',
      width: 120,
      align: 'center',
      fixed: 'center',
      render: (size, row) => {
        if (!row.is_folder) {
          return formatFileSize(size)
        }
        return <LineOutlined />
      },
    },
    {
      title: 'Upload',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 120,
      sorter: true,
      render: (created_at) => {
        return moment(created_at, 'YYYY-MM-DD').format('YYYY-MM-DD') || <LineOutlined />
      },
    },
    {
      title: '',
      width: 220,
      align: 'right',
      render: (row) => {
        const folderPermission = row.file_permissions?.find((item) => item.user_id === currentUser.id)
        const fileButtons = row.is_folder ? (
          <>
            <Button
              // disabled={!permission(`${roleName}:view`)}
              onClick={handleFolderButton?.bind(null, 3, row)}
              type="link"
              icon={<DownloadOutlined />}
            ></Button>
            <Button
              disabled={
                !isAdmin() &&
                !(currentUser.is_manager && currentUser.department_id === row.department_id) &&
                !permission(`${roleName}:edit`, folderPermission)
              }
              onClick={handleFolderButton?.bind(null, 0, row)}
              type="link"
              icon={<EditOutlined />}
            ></Button>
            <Button
              disabled={
                !isAdmin() &&
                !(currentUser.is_manager && currentUser.department_id === row.department_id) &&
                !permission(`${roleName}:delete`, folderPermission)
              }
              // {...(!permission(`${roleName}:edit`) && { disabled: true })}
              onClick={handleFolderButton?.bind(null, 1, row)}
              type="link"
              danger
              icon={<DeleteOutlined />}
            ></Button>
            <Button
              disabled={
                !isAdmin() &&
                !(currentUser.is_manager && currentUser.department_id === row.department_id) &&
                !permission(`${roleName}:edit`, folderPermission)
              }
              onClick={handleFolderButton?.bind(null, 2, row)}
              type="link"
              icon={<UserAddOutlined />}
            ></Button>
          </>
        ) : (
          <Button
            disabled={!permission(`${roleName}:delete`)}
            onClick={handleFileButton?.bind(null, 1, row)}
            type="link"
            danger
            icon={<DeleteOutlined />}
          ></Button>
        )

        return (
          <Space align="center">
            {fileButtons}
            <Button
              disabled={!permission(`${roleName}:view`)}
              onClick={onShowFileLog.bind(null, row)}
              type="link"
              icon={<HistoryOutlined />}
            ></Button>
          </Space>
        )
      },
    },
  ]

  return (
    <TableFile
      isSelectedProject={isSelectedProject}
      isSelectedDepartment={isSelectedDepartment}
      dataSource={fileList}
      columns={columns}
      isLoading={isLoading}
      onAdd={onAdd}
      onCreateFolder={onCreateFolder}
      selectedProject={selectedProject}
      isLoading={isLoading}
      setSelectedFolderData={setSelectedFolderData}
      breadscrumb={breadscrumb}
      roleName={roleName}
      permission={permission}
      // isBrowsing={isBrowsing}
      // onDelete={onDelete}
      // onAdd={onAdd}

      // setSelectedFolderData={setSelectedFolderData}
    />
  )
}

export default FileList
