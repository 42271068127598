import * as actionTypes from 'store/types/user.action.types'

const initialState = {
  users: [],
  totalUser: 0,
  roles: [],
  totalRole: 0,
}

const reducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case actionTypes.FETCH_USER:
      return { ...state, users: payload.data, totalUser: payload.total }
    case actionTypes.FETCH_ROLES:
      return { ...state, roles: payload.data, totalRole: payload.total }

    default:
      return state
  }
}

export default reducer
