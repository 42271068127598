import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_PROFILE,
  SET_ROUTES,
  SET_PROJECT,
  UPDATE_PROFILE,
  UPDATE_AVARTAR,
} from 'store/actions/auth.action'

const initialState = {
  isAuthenticated: false,
  token: null,
  profile: null,
  role: null,
  permissions: [],
  project_users: [],
  accessedRoutes: [],
  routeProjectId: 0,
}

export default function auth(state = initialState, { type, payload }) {
  switch (type) {
    case AUTH_LOGIN:
      return { ...state, token: payload }
    case AUTH_LOGOUT:
      return { ...state, token: null, profile: null, isAuthenticated: false, role: null, permissions: [] }
    case AUTH_PROFILE:
      return {
        ...state,
        profile: payload,
        permissions: payload.role_permissions,
        role: payload.role,
        project_users: payload.project_users,
        isAuthenticated: true,
      }
    case UPDATE_PROFILE:
      return {
        ...state,
        profile: {
          ...state.profile,
          ...payload,
        },
      }
    case UPDATE_AVARTAR:
      return {
        ...state,
        profile: {
          ...state.profile,
          image: payload,
        },
      }
    case SET_ROUTES:
      return { ...state, accessedRoutes: payload }
    case SET_PROJECT:
      return { ...state, routeProjectId: payload }
    default:
      return state
  }
}
