import React from 'react'
import { Table } from 'antd'
import { TableAction } from 'components/Common'
import permission from 'utils/permission'

const ProjectUserList = (props) => {
  const tablePermission = props.permission ?? permission

  const columns = [
    {
      title: 'Tài khoản',
      dataIndex: ['username'],
    },
    {
      title: 'Tên',
      render: (item) => {
        return `${item.last_name} ${item.first_name}`
      },
    },
    {
      title: 'Email',
      dataIndex: ['email'],
    },
    {
      title: 'Phòng ban',
      dataIndex: ['user', 'department', 'name'],
    },
    {
      title: 'Vai trò',
      dataIndex: ['role', 'name'],
    },
    ...(tablePermission(`${props.roleName}:edit,${props.roleName}:delete`)
      ? [
          {
            title: '',
            width: '20%',
            align: 'center',
            render: (item) => (
              <TableAction
                roleName={props.roleName}
                permission={tablePermission}
                item={item}
                onEdit={props.onEdit}
                onDelete={props.onDelete}
              />
            ),
          },
        ]
      : []),
  ]

  const { data } = props

  return (
    <Table
      pagination={false}
      columns={columns}
      dataSource={data}
      rowKey="id"
      bordered
      // scroll={{ x: '767px', y: 414 }}
    />
  )
}

export default ProjectUserList
