import React, { useState, useEffect } from 'react'
import { Form, Input, Select, Switch, Checkbox, Row, Col } from 'antd'

import { Modal } from 'components/Common'
import { ADMIN_ROLE_ID, USER_ROLE_ID } from 'configs/constants'

const { Option } = Select

const formItemLayout = {
  labelCol: {
    xs: { span: 22 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 22 },
    sm: { span: 16 },
  },
}

const UserForm = (props) => {
  const [form] = Form.useForm(null)
  const { data, onSubmit, departments } = props
  const [isRegistration, setIsRegistration] = useState(false)
  const [isChangePassword, setIsChangePassword] = useState(false)

  useEffect(() => {
    if (props.visible) {
      form.resetFields()
      setIsChangePassword(false)
      if (data) {
        setIsRegistration(false)
        form.setFieldsValue({
          first_name: data.first_name,
          last_name: data.last_name,
          username: data.username,
          email: data.email,
          phone: data.phone,
          department_id: data.department_id,
          is_manager: data.is_manager ? true : false,
          can_update_system_calendar: data.can_update_system_calendar ? true : false,
          role_id: data.role_id === ADMIN_ROLE_ID ? true : false,
        })
      } else {
        setIsRegistration(true)
      }
    }
  }, [props.visible])

  const onFinish = (formData) => {
    onSubmit({
      ...data,
      ...formData,
      is_manager: formData.is_manager ? 1 : 0,
      can_update_system_calendar: formData.can_update_system_calendar ? 1 : 0,
      role_id: formData.role_id ? ADMIN_ROLE_ID : USER_ROLE_ID,
    })
  }

  return (
    <Modal
      title={data?.id ? data.full_name : 'Thêm mới người dùng'}
      formName="user-form"
      loading={props.loading}
      visible={props.visible}
      onCancel={props.onCancel}
    >
      <Form
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        id="user-form"
        form={form}
        name="dynamic_rule"
        onFinish={onFinish}
      >
        <Form.Item
          {...formItemLayout}
          name="first_name"
          label="Tên"
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập tên',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="last_name"
          label="Họ"
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập họ',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="username"
          label="Tên tài khoản"
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập tên tài khoản',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Row>
          <Col span={8}>
            <Form.Item name="role_id" valuePropName="checked">
              <Checkbox>Administrator</Checkbox>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="is_manager" valuePropName="checked">
              <Checkbox>Trưởng phòng</Checkbox>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="can_update_system_calendar" valuePropName="checked">
              <Checkbox>Lịch hệ thống</Checkbox>
            </Form.Item>
          </Col>
        </Row>

        {/* <Form.Item {...formItemLayout} name="role_id" label="Vai trò">
          <Checkbox>Quản trị</Checkbox>
        </Form.Item> */}
        <Form.Item
          {...formItemLayout}
          name="department_id"
          label="Phòng ban"
          rules={[
            {
              required: true,
              message: 'Vui lòng chọn phòng ban',
            },
          ]}
        >
          <Select>
            {departments.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="email"
          label="Email"
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập email',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item {...formItemLayout} name="phone" label="Điện thoại">
          <Input />
        </Form.Item>
        {!isRegistration && (
          <Form.Item label="Change Password">
            <Switch onChange={() => setIsChangePassword(!isChangePassword)} checked={isChangePassword} />
          </Form.Item>
        )}
        {isRegistration || isChangePassword ? (
          <>
            <Form.Item
              {...formItemLayout}
              name="password"
              label="Mật khẩu"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập mật khẩu',
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="password_confirmation"
              label="Mật khẩu xác nhận"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập mật khẩu xác nhận',
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
          </>
        ) : (
          ''
        )}
      </Form>
    </Modal>
  )
}

export default UserForm
