import React, { useEffect } from 'react'
import { Table, Button, Row, notification, Space } from 'antd'
import { useState } from 'react'
import { dateFormat } from 'utils'
import { useWindowSize, useLayoutHelper } from 'hooks'
import {
  FolderFilled,
  FileFilled,
  UsergroupAddOutlined,
  FileOutlined,
  EditOutlined,
  DeleteOutlined,
} from 'assets/icon'
import { FileActionPanel, FileBreadcrumb, FileDescription } from 'components'

const initialState = {
  tableHeight: 0,
  tableDataSource: [],
  isLoading: false,
  columns: [],
  isFileVisible: false,
  selectedFileData: {},
}

function TableFile({
  isSelectedProject,
  isSelectedDepartment,
  dataSource,
  isLoading,
  onAdd,
  onCreateFolder,
  selectedProject,
  columns,
  breadscrumb,
  setSelectedFolderData,
  roleName,
  permission,
  ...props
}) {
  const [state, setState] = useState(initialState)
  const { isToggleMenu: isMobile } = useLayoutHelper()

  const handleSelectedFolder = (value) => {
    let type = 0
    //Check if already selected a project
    if (!isSelectedProject) {
      //Check if is selecting a project
      type = 1
      // if (value.project_id) {
      //   type = 1
      //   console.log('Select project at root', value)
      // } else if (value.department_id) {
      //   type = 1
      //   console.log('Select general departments', value)
      // }
    } else {
      type = 1
    }

    setSelectedFolderData(type, value)
  }

  const handleDoubleClickAction = (actionType, record) => {
    switch (actionType) {
      // Show File Description
      case 0:
        setState({ ...state, isFileVisible: !state.isFileVisible, selectedFileData: record })
        break
      // Browsing
      case 1:
        handleSelectedFolder(record)
        break

      default:
    }
  }
  return (
    <>
      <FileActionPanel
        onAdd={onAdd}
        onCreateFolder={onCreateFolder}
        roleName={roleName}
        permission={permission}
      />
      <Row align="middle" className="ant-breadcrumb-container" style={{ padding: '24px' }}>
        <FileBreadcrumb
          isSelectedProject={isSelectedProject}
          isSelectedDepartment={isSelectedDepartment}
          setSelectedFolderData={setSelectedFolderData}
          breadscrumb={breadscrumb}
        />
      </Row>
      <Table
        {...props}
        pagination={false}
        rowKey="id"
        columns={columns}
        dataSource={dataSource}
        onRow={(record, recordIndex) => ({
          onDoubleClick: (event) => {
            if (record.is_folder) {
              handleDoubleClickAction(1, record)
            } else {
              handleDoubleClickAction(0, record)
            }
          },
        })}
        loading={isLoading}
        scroll={isMobile ? { x: 'max-content', y: 250 } : { x: 'max-content' }}
        tableLayout={isMobile ? 'auto' : 'fixed'}
        //rowClassName={(record) => !isAllowed(record.id, 1) && record.is_folder && 'disabled-row'}
        // className={`table-file ${os}`}
      />
      <FileDescription
        isMobile={isMobile}
        visible={state.isFileVisible}
        onCancel={() => setState({ ...state, isFileVisible: false })}
        selectedFileData={state.selectedFileData}
      />
    </>
  )
}

export default TableFile
